article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
  border: 0;
}

#map_canvas img {
  max-width: none;
}

button, input, select, textarea {
  vertical-align: middle;
  margin: 0;
  font-size: 100%;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

iframe {
  border: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

a:hover, a:active, [tabindex="-1"] {
  outline: 0 !important;
}

.site:before, .site:after, .x-site:before, .x-site:after {
  content: " ";
  width: 0;
  display: table;
}

.site:after, .x-site:after {
  clear: both;
}

.x-boxed-layout-active .site, .x-boxed-layout-active .x-site {
  box-sizing: content-box;
  background-color: #fff;
  border: 0;
  border-left: 1px solid #00000046;
  border-right: 1px solid #00000046;
  margin: 0 auto;
  box-shadow: 0 0 5px #00000020;
}

body {
  line-height: 1.7;
}

.x-container.offset {
  margin: 40px auto;
}

.x-root {
  display: flex;
}

.x-root .site, .x-root .x-site {
  width: 100%;
  min-width: 1px;
  flex: auto;
  position: relative;
}

.x-masthead, .x-colophon {
  position: relative;
}

.x-acc-item {
  z-index: 1;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.x-acc-header {
  z-index: 2;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.x-acc-header span {
  pointer-events: none;
}

.x-acc-header-content {
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.x-acc-header-indicator {
  text-align: center;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  line-height: 1;
  display: flex;
}

.x-acc-header-text {
  flex: 1 0;
}

.x-acc-content {
  z-index: 1;
  position: relative;
}

.x-acc-content > p:last-child, .x-acc-content > ul:last-child, .x-acc-content > ol:last-child {
  margin-bottom: 0;
}

.x-crumbs-list {
  flex-flow: wrap;
  place-content: center flex-start;
  align-items: center;
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0 !important;
}

.x-crumbs-list-item {
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  align-items: inherit;
  align-content: inherit;
  flex-wrap: nowrap;
}

.x-crumbs-list-item:last-child:after {
  content: ".";
  visibility: hidden;
  width: 0;
  opacity: 0;
  pointer-events: none;
  speak: none;
  display: inline;
}

.x-crumbs-link {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.x-card {
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.x-card.is-flip-left .x-card-face.is-back, .x-card.is-flip-right .x-card-face.is-back {
  transform: rotate3d(0, 1, 0, 180deg);
}

.x-card.is-flip-up .x-card-face.is-back, .x-card.is-flip-down .x-card-face.is-back {
  transform: rotate3d(1, 0, 0, 180deg);
}

.x-card.is-active.is-flip-left .x-card-faces {
  transform: rotate3d(0, 1, 0, -180deg);
}

.x-card.is-active.is-flip-right .x-card-faces {
  transform: rotate3d(0, 1, 0, 180deg);
}

.x-card.is-active.is-flip-up .x-card-faces {
  transform: rotate3d(1, 0, 0, 180deg);
}

.x-card.is-active.is-flip-down .x-card-faces {
  transform: rotate3d(1, 0, 0, -180deg);
}

.x-card.cannot-preserve-3d .x-card-faces {
  transform-style: initial;
}

.x-card.cannot-preserve-3d .x-card-face {
  -webkit-backface-visibility: initial;
  backface-visibility: initial;
}

.x-card.cannot-preserve-3d .x-card-face.is-front {
  visibility: visible;
}

.x-card.cannot-preserve-3d .x-card-face.is-back {
  visibility: hidden;
}

.x-card.cannot-preserve-3d .x-text {
  max-width: 100%;
}

.x-card.cannot-preserve-3d[class*="is-flip"] .x-card-faces, .x-card.cannot-preserve-3d[class*="is-flip"] .x-card-face {
  transform: none;
}

.x-card.cannot-preserve-3d.is-active .x-card-face.is-front {
  visibility: hidden;
}

.x-card.cannot-preserve-3d.is-active .x-card-face.is-back {
  visibility: visible;
}

.x-card.has-not-flipped .x-card-face.is-back * {
  box-shadow: none !important;
  filter: none !important;
  transform: none !important;
}

.x-card-faces {
  display: -ms-grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr;
  grid-gap: 0px;
  transform-style: preserve-3d;
  will-change: transform;
  flex: auto;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-auto-rows: 0;
  grid-auto-columns: 0;
  place-items: stretch stretch;
  display: grid;
  position: relative;
}

.x-card-face {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  z-index: 1;
  min-width: 0;
  max-width: 100%;
  min-height: 0;
  user-select: none;
  backface-visibility: hidden;
  flex-flow: column;
  grid-area: 1 / 1 / -1 / -1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.x-card-face [class^="x-bg"][class*="lower"], .x-card-face [class^="x-bg"][class*="upper"] {
  transform: translate3d(0, 0, 0);
}

.x-countdown {
  display: block;
}

.x-countdown.has-compact-labels .x-countdown-unit-content {
  flex-flow: row;
  justify-content: center;
  align-items: baseline;
}

.x-countdown.has-top-labels .x-countdown-unit-content, .x-countdown.has-bottom-labels .x-countdown-unit-content {
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.x-countdown.has-top-labels .x-countdown-number {
  order: 2;
}

.x-countdown.has-top-labels .x-countdown-label, .x-countdown.has-bottom-labels .x-countdown-number {
  order: 1;
}

.x-countdown.has-bottom-labels .x-countdown-label {
  order: 2;
}

.x-countdown-units {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.x-countdown-unit {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.x-countdown-unit:not(:last-child):after {
  font-style: normal;
  line-height: 1;
  display: inline;
}

.x-countdown-unit-content {
  flex: none;
  display: flex;
}

.x-countdown-number {
  width: 100%;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.x-countdown-digit {
  display: inline;
}

.x-countdown-label {
  display: block;
}

.x-statbar, .x-statbar-bar, .x-statbar-label {
  display: flex;
  position: relative;
}

.x-statbar {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.x-statbar-bar {
  flex-direction: inherit;
  align-items: center;
}

.x-statbar-label {
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
}

.x-statbar-label.x-active {
  opacity: 1;
  pointer-events: auto;
}

.x-tabs-list {
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  flex-flow: row;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.x-tabs-list:before, .x-tabs-list:after {
  content: "";
  width: 0;
  height: 0;
  visibility: hidden;
  display: block;
}

.x-tabs-list ul {
  flex-flow: inherit;
  flex: 1 0;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.x-tabs-list li {
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.x-tabs-list a, .x-tabs-list button {
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  flex: 1 0;
  display: block;
}

.x-tabs-list li:first-child, .x-tabs-list li:first-child a, .x-tabs-list li:first-child button {
  margin-left: 0 !important;
}

.x-tabs-list li:last-child, .x-tabs-list li:last-child a, .x-tabs-list li:last-child button {
  margin-right: 0 !important;
}

.x-tabs-panels {
  z-index: 1;
  flex-flow: row;
  align-items: stretch;
  display: flex;
  position: relative;
}

.x-tabs-panel {
  flex: 1;
}

.x-tabs-panel:not(.x-active) {
  display: none;
}

.x-tabs-panel :first-child {
  margin-top: 0;
}

.x-tabs-panel :last-child {
  margin-bottom: 0;
}

.x-quote {
  display: flex;
}

.x-quote-content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.x-quote-text :first-child {
  margin-top: 0;
}

.x-quote-text :last-child {
  margin-bottom: 0;
}

.x-quote-cite {
  display: flex;
}

.x-global-block {
  position: relative;
}

[data-x-single-anim] {
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(.4, 0, .2, 1);
  animation-fill-mode: forwards;
}

[data-x-single-anim][class*="secondary"] {
  animation-duration: .45s;
}

@keyframes x-slide-top {
  33% {
    opacity: 0;
    transform: none;
  }

  34% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes x-slide-left {
  33% {
    opacity: 0;
    transform: none;
  }

  34% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes x-slide-right {
  33% {
    opacity: 0;
    transform: none;
  }

  34% {
    opacity: 0;
    transform: translate3d(50%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes x-slide-bottom {
  33% {
    opacity: 0;
    transform: none;
  }

  34% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes x-scale-up {
  33% {
    opacity: 0;
    transform: none;
  }

  34% {
    opacity: 0;
    transform: scale3d(0, 0, 1);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes x-scale-down {
  33% {
    opacity: 0;
    transform: none;
  }

  34% {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes x-flip-x {
  33% {
    opacity: 0;
    transform: none;
  }

  34% {
    opacity: 0;
    transform: perspective(125px)rotate3d(1, 0, 0, -180deg);
  }

  100% {
    opacity: 1;
    transform: perspective(125px)rotate3d(1, 0, 0, 0);
  }
}

@keyframes x-flip-y {
  33% {
    opacity: 0;
    transform: none;
  }

  34% {
    opacity: 0;
    transform: perspective(125px)rotate3d(0, 1, 0, -180deg);
  }

  100% {
    opacity: 1;
    transform: perspective(125px)rotate3d(0, 1, 0, 0);
  }
}

.x-testimonial {
  min-width: 1px;
  flex-wrap: nowrap;
  display: flex;
}

.x-testimonial-content {
  min-width: 1px;
  flex-flow: column;
  flex: auto;
  display: flex;
}

.x-testimonial-text :first-child {
  margin-top: 0;
}

.x-testimonial-text :last-child {
  margin-bottom: 0;
}

.x-testimonial-cite {
  min-width: 1px;
  flex-wrap: nowrap;
  display: flex;
}

.x-testimonial-cite-content {
  min-width: 1px;
  flex: auto;
}

.x-testimonial-cite-text {
  display: block;
}

.x-div {
  width: auto;
  min-width: 0;
  max-width: none;
  height: auto;
  min-height: 0;
  max-height: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.x-row {
  width: auto;
  min-width: 0;
  max-width: none;
  height: auto;
  min-height: 0;
  max-height: none;
  border: 0;
  border-radius: 0;
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.x-row-inner {
  min-width: 0;
  min-height: 0;
  flex-wrap: wrap;
  flex: auto;
  display: flex;
}

.x-col {
  width: auto;
  min-width: 0;
  max-width: 100%;
  height: auto;
  min-height: 0;
  max-height: none;
  border: 0;
  border-radius: 0;
  flex: 0 auto;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.no-preserve3d .x-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.x-grid {
  width: auto;
  min-width: 0;
  max-width: none;
  height: auto;
  min-height: 0;
  max-height: auto;
  border: 0;
  border-radius: 0;
  grid-auto-rows: auto;
  grid-auto-columns: auto;
  margin: 0;
  padding: 0;
  display: grid;
  position: relative;
}

.x-cell {
  width: auto;
  min-width: 0;
  max-width: 100%;
  height: auto;
  min-height: 0;
  max-height: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
}

.x-loader {
  width: 1em;
  height: 1em;
  opacity: 0;
  font-size: 1em;
  display: inline-block;
}

.x-loader svg {
  width: 1em;
  height: 1em;
  margin: auto;
  display: block;
}

.x-loader.is-active, form.processing .x-loader {
  opacity: 1;
}

.x-paginate {
  flex-flow: row;
  place-content: center flex-start;
  align-items: center;
  display: flex;
}

.x-paginate-inner {
  flex-flow: wrap;
  flex: 1;
  align-content: center;
  align-items: center;
  display: flex;
}

.x-paginate-inner > * {
  text-align: center;
  flex-flow: row;
  flex-shrink: 1;
  flex-basis: auto;
  justify-content: center;
  align-items: center;
  line-height: 1;
  display: flex;
}

.x-paginate-inner .dots, .x-paginate-inner .current {
  cursor: not-allowed;
}

.x-the-content.entry-content {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.x-the-content.entry-content > :first-child {
  margin-top: 0 !important;
}

.x-the-content.entry-content > :last-child {
  margin-bottom: 0 !important;
}

[class*="woocommerce"] .x-wc-products .cross-sells, [class*="woocommerce"] .x-wc-products .related, [class*="woocommerce"] .x-wc-products .upsells {
  float: none;
  clear: none;
  width: 100%;
}

[class*="woocommerce"] div.x-wc-product-gallery div.woocommerce-product-gallery {
  float: none;
  width: 100%;
}

[class*="woocommerce"] .pswp {
  z-index: 999999999;
}

[class*="woocommerce"] .x-wc-add-to-cart-form form {
  margin: 0;
}

.x-wc-shop-sort {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.x-wc-shop-sort .woocommerce-ordering, .x-wc-shop-sort .woocommerce-result-count {
  float: none;
  margin: 0;
}

.x-content-area .woocommerce-Reviews-title {
  margin-top: 0;
}

.x-icon {
  width: auto;
  height: auto;
  line-height: inherit;
  text-align: center;
  flex-flow: row !important;
  justify-content: center !important;
  align-items: center !important;
  display: inline-flex !important;
}

.x-icon:before {
  width: inherit !important;
  height: inherit !important;
  line-height: inherit !important;
  text-align: inherit !important;
  margin: 0 !important;
  display: block !important;
  position: static !important;
  inset: auto !important;
}

.x-anchor {
  min-width: 1px;
  cursor: pointer;
  flex-flow: column;
  justify-content: stretch;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.x-anchor-content {
  z-index: 2;
  border-radius: inherit;
  flex: 1 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.x-anchor-text {
  min-width: 1px;
  max-width: 100%;
  flex-shrink: 1;
}

.x-anchor-text-primary, .x-anchor-text-secondary {
  z-index: 3;
  display: block;
  position: relative;
}

div.x-anchor-text-primary *, div.x-anchor-text-secondary * {
  font-family: inherit !important;
  font-size: 1em !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
  line-height: inherit !important;
  text-align: inherit !important;
  text-shadow: none !important;
  text-transform: inherit !important;
  color: inherit !important;
  box-shadow: none !important;
  background-color: #0000 !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  text-decoration: none !important;
  display: block !important;
}

.x-anchor-sub-indicator {
  width: auto;
  height: auto;
  letter-spacing: 0;
  text-align: center;
  z-index: 2;
  line-height: 1;
  display: block;
  position: relative;
}

.x-anchor-sub-indicator:before {
  display: inherit;
  width: inherit;
  height: inherit;
  line-height: inherit;
  text-align: inherit;
}

li:not(.menu-item-has-children) > .x-anchor .x-anchor-sub-indicator {
  visibility: hidden;
  speak: none;
  display: none;
}

.x-anchor-content.is-int {
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.x-anchor.has-int-content .x-anchor-content[class*="in-scale-up"].is-int {
  opacity: 0;
  transform: scale(0);
}

.x-anchor.has-int-content:hover .x-anchor-content[class*="in-scale-up"].is-int, .x-anchor.has-int-content:focus .x-anchor-content[class*="in-scale-up"].is-int {
  opacity: 1;
  transform: scale(1);
}

.x-anchor.has-int-content:hover .x-anchor-content.x-anchor-content-out-slide-top-in-scale-up:not(.is-int), .x-anchor.has-int-content:focus .x-anchor-content.x-anchor-content-out-slide-top-in-scale-up:not(.is-int) {
  transform: translate3d(0, -100%, 0);
}

.x-anchor.has-int-content:hover .x-anchor-content.x-anchor-content-out-slide-left-in-scale-up:not(.is-int), .x-anchor.has-int-content:focus .x-anchor-content.x-anchor-content-out-slide-left-in-scale-up:not(.is-int) {
  transform: translate3d(-100%, 0, 0);
}

.x-anchor.has-int-content:hover .x-anchor-content.x-anchor-content-out-slide-right-in-scale-up:not(.is-int), .x-anchor.has-int-content:focus .x-anchor-content.x-anchor-content-out-slide-right-in-scale-up:not(.is-int) {
  transform: translate3d(100%, 0, 0);
}

.x-anchor.has-int-content:hover .x-anchor-content.x-anchor-content-out-slide-bottom-in-scale-up:not(.is-int), .x-anchor.has-int-content:focus .x-anchor-content.x-anchor-content-out-slide-bottom-in-scale-up:not(.is-int) {
  transform: translate3d(0, 100%, 0);
}

.x-bar {
  position: relative;
}

.x-bar, .x-bar-scroll, .x-bar-scroll-outer, .x-bar-scroll-inner, .x-bar-content {
  display: flex;
}

.x-bar, .x-bar-scroll, .x-bar-scroll-outer, .x-bar-scroll-inner {
  justify-content: space-between;
}

.x-bar-h, .x-bar-h .x-bar-scroll, .x-bar-h .x-bar-scroll-outer, .x-bar-h .x-bar-scroll-inner {
  flex-direction: row;
}

.x-bar-v, .x-bar-v .x-bar-scroll, .x-bar-v .x-bar-scroll-outer, .x-bar-v .x-bar-scroll-inner {
  flex-direction: column;
}

.x-bar-scroll, .x-bar-scroll-outer, .x-bar-scroll-inner, .x-bar-content {
  flex: 1 0 auto;
}

.x-bar-content {
  z-index: 5;
}

.x-bar, .x-bar-scroll, .x-bar-scroll-outer, .x-bar-scroll-inner, .x-bar-content, .x-bar-container {
  min-width: 1px;
}

.x-bar-space {
  flex-shrink: 0;
}

.x-bar-space-right {
  order: 9999;
}

.x-bar-outer-spacers:before, .x-bar-outer-spacers:after {
  content: "";
  pointer-events: none;
  visibility: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  display: block;
}

.x-bar-scroll {
  position: relative;
  width: 100% !important;
  height: 100% !important;
}

.x-bar-scroll .ps__rail-x, .x-bar-scroll .ps__rail-y {
  z-index: 10;
}

.x-bar-scroll-outer, .x-bar-scroll-inner {
  width: 100% !important;
  height: 100% !important;
}

.x-bar-scroll-outer {
  overflow: hidden !important;
}

.x-bar-scroll-inner {
  box-sizing: content-box !important;
  -webkit-overflow-scrolling: touch !important;
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
}

.x-bar-h .x-bar-scroll-inner {
  padding: 0 0 50px !important;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
}

.x-bar-v .x-bar-scroll-inner {
  padding: 0 50px 0 0 !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.x-bar-scroll-button {
  z-index: 9999;
  text-align: center;
  opacity: 1;
  box-shadow: none;
  pointer-events: auto;
  border: 0;
  border-radius: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  line-height: 1;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.x-bar-scroll-button .x-icon {
  width: auto;
  height: 1em;
  margin: auto;
  display: block;
  transform: translateZ(0);
}

.x-bar-scroll-button:not(.is-active) {
  pointer-events: none;
}

.x-bar-scroll-button.is-bck:not(.is-active) {
  opacity: 0;
  transform: translate3d(-100%, -50%, 0);
}

.x-bar-scroll-button.is-fwd:not(.is-active) {
  opacity: 0;
  transform: translate3d(100%, -50%, 0);
}

.x-bar-absolute {
  position: absolute;
}

.x-bar-fixed {
  position: fixed;
}

.x-bar-bottom {
  bottom: 0;
}

.x-bar-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.x-bar-right {
  top: 0;
  bottom: 0;
  right: 0;
}

@media screen and (min-width: 783px) {
  .admin-bar .x-bar-left, .admin-bar .x-bar-right {
    top: 32px;
  }
}

@media screen and (min-width: 601px) and (max-width: 782px) {
  .admin-bar .x-bar-left, .admin-bar .x-bar-right {
    top: 46px;
  }
}

.x-bar-h .x-bar-container, .x-bar-h .x-mod-container {
  height: inherit;
}

.x-bar-v .x-bar-container, .x-bar-v .x-mod-container {
  width: inherit;
}

.x-bar-container {
  display: flex;
  position: relative;
}

.x-mod-container {
  flex-direction: inherit;
  align-items: inherit;
  display: inline-flex;
  position: relative;
}

.x-bar-container .x-mod-container {
  display: flex;
}

.x-bar-is-initially-hidden {
  opacity: 0;
  pointer-events: none;
}

.x-bar-is-initially-hidden:not(.x-bar-is-visible) {
  visibility: hidden;
}

[class^="x-bg"] {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

[class^="x-bg"][data-x-params*="parallax"] {
  opacity: 0;
}

[class^="x-bg"] img {
  width: 100%;
  height: 100%;
  display: block;
}

.x-bg {
  z-index: -1;
}

[class*="x-bg-layer-lower"] {
  z-index: 1;
}

[class*="x-bg-layer-upper"] {
  z-index: 2;
}

.x-collapsed {
  display: none;
}

.x-collapsing {
  height: 0;
  overflow: hidden !important;
}

.x-mini-cart ul {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}

.x-mini-cart li:before, .x-mini-cart li:after {
  content: " ";
  width: 0;
  display: table;
}

.x-mini-cart li:after {
  clear: both;
}

.x-mini-cart a:not(.x-anchor) {
  display: block;
}

.x-mini-cart a:not(.x-anchor):focus {
  outline: 0;
}

.x-mini-cart img {
  float: left;
  margin: 0;
  display: block;
}

.x-mini-cart .remove {
  float: right;
  text-align: center !important;
}

.x-mini-cart .quantity {
  display: block;
  margin: 0 !important;
}

.x-mini-cart .total {
  margin: 0;
}

.x-mini-cart .total strong {
  font-weight: inherit;
}

.x-mini-cart .buttons {
  flex-flow: wrap;
  margin: 0;
  display: flex;
}

.x-mini-cart .widget_shopping_cart_content {
  flex-direction: column;
  display: flex;
}

.rtl .x-mini-cart img {
  float: right;
}

.rtl .x-mini-cart .remove {
  float: left;
}

.x-dropdown {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 9999;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translate3d(0, 5%, 0);
}

.x-dropdown[data-x-stem*="u"] {
  transform: translate3d(0, -5%, 0);
}

.x-dropdown.x-active {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transform: none;
}

ul.x-dropdown, ol.x-dropdown {
  list-style: none;
}

.menu-item-has-children {
  position: relative;
}

.x-frame {
  width: 100%;
}

.x-frame-inner {
  z-index: 1;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.x-frame-inner video, .x-frame-inner > div, .x-frame-inner > embed, .x-frame-inner > iframe, .x-frame-inner > img, .x-frame-inner > object, .x-frame-inner > .x-map iframe, .x-frame-inner > .x-video iframe, .x-frame-inner > .x-video .mejs-poster img {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.x-graphic {
  letter-spacing: 0;
  z-index: 2;
  flex-shrink: 0;
  line-height: 1;
  position: relative;
}

.x-graphic-child {
  position: relative;
}

.x-graphic-icon, .x-graphic-icon.x-icon, .x-graphic-image, .x-graphic-image.x-image {
  display: block;
}

.x-graphic-icon, .x-graphic-icon.x-icon {
  font-size: 1em;
}

.x-graphic-primary:not(:only-child), .x-graphic-secondary {
  backface-visibility: hidden;
}

.x-graphic-primary:not(:only-child) {
  opacity: 1;
  z-index: 2;
}

.x-graphic-secondary {
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.x-scale-up.x-graphic-primary:not(:only-child) {
  transform: scale(1);
}

.x-scale-up.x-graphic-secondary {
  transform: scale(.35);
}

.x-scale-down.x-graphic-primary:not(:only-child) {
  transform: scale(1);
}

.x-scale-down.x-graphic-secondary {
  transform: scale(1.5);
}

.x-flip-x.x-graphic-primary:not(:only-child) {
  transform: perspective(100px)rotateX(0);
}

.x-flip-x.x-graphic-secondary {
  transform: perspective(100px)rotateX(-180deg);
}

.x-flip-y.x-graphic-primary:not(:only-child) {
  transform: perspective(100px)rotateY(0);
}

.x-flip-y.x-graphic-secondary {
  transform: perspective(100px)rotateY(-180deg);
}

.has-graphic .x-graphic-primary:not(:only-child)[class*="active"], .has-graphic:hover .x-graphic-primary:not(:only-child), [data-x-effect-provider*="effects"]:hover .x-graphic-primary:not(:only-child) {
  opacity: 0;
}

.has-graphic .x-graphic-primary:not(:only-child)[class*="active"].x-scale-up, .has-graphic:hover .x-graphic-primary:not(:only-child).x-scale-up, [data-x-effect-provider*="effects"]:hover .x-graphic-primary:not(:only-child).x-scale-up {
  transform: scale(1.5);
}

.has-graphic .x-graphic-primary:not(:only-child)[class*="active"].x-scale-down, .has-graphic:hover .x-graphic-primary:not(:only-child).x-scale-down, [data-x-effect-provider*="effects"]:hover .x-graphic-primary:not(:only-child).x-scale-down {
  transform: scale(.35);
}

.has-graphic .x-graphic-primary:not(:only-child)[class*="active"].x-flip-x, .has-graphic:hover .x-graphic-primary:not(:only-child).x-flip-x, [data-x-effect-provider*="effects"]:hover .x-graphic-primary:not(:only-child).x-flip-x {
  transform: perspective(100px)rotateX(180deg);
}

.has-graphic .x-graphic-primary:not(:only-child)[class*="active"].x-flip-y, .has-graphic:hover .x-graphic-primary:not(:only-child).x-flip-y, [data-x-effect-provider*="effects"]:hover .x-graphic-primary:not(:only-child).x-flip-y {
  transform: perspective(100px)rotateY(180deg);
}

.has-graphic .x-graphic-secondary[class*="active"], .has-graphic:hover .x-graphic-secondary, [data-x-effect-provider*="effects"]:hover .x-graphic-secondary {
  opacity: 1;
}

.has-graphic .x-graphic-secondary[class*="active"].x-scale-up, .has-graphic:hover .x-graphic-secondary.x-scale-up, [data-x-effect-provider*="effects"]:hover .x-graphic-secondary.x-scale-up, .has-graphic .x-graphic-secondary[class*="active"].x-scale-down, .has-graphic:hover .x-graphic-secondary.x-scale-down, [data-x-effect-provider*="effects"]:hover .x-graphic-secondary.x-scale-down {
  transform: scale(1);
}

.has-graphic .x-graphic-secondary[class*="active"].x-flip-x, .has-graphic:hover .x-graphic-secondary.x-flip-x, [data-x-effect-provider*="effects"]:hover .x-graphic-secondary.x-flip-x {
  transform: perspective(100px)rotateX(0);
}

.has-graphic .x-graphic-secondary[class*="active"].x-flip-y, .has-graphic:hover .x-graphic-secondary.x-flip-y, [data-x-effect-provider*="effects"]:hover .x-graphic-secondary.x-flip-y {
  transform: perspective(100px)rotateY(0);
}

.x-image {
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .x-image {
    min-height: 0%;
  }
}

.x-image img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  -ms-interpolation-mode: bicubic;
  border: 0;
  display: block;
}

a.x-image {
  cursor: pointer;
}

a.x-image:focus {
  outline: 0;
}

.x-image-preserve-ratio img {
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.x-image-preserve-ratio img[src*=".svg"] {
  height: 100% !important;
}

.x-line {
  width: 0;
  height: 0;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
}

.x-menu, .x-menu .sub-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.x-menu, .x-menu li {
  min-width: 1px;
}

.x-has-sub {
  position: relative;
}

.x-menu-modal .x-anchor, .x-menu-layered .x-anchor, .x-menu-dropdown .x-anchor, .x-menu-collapsed .x-anchor, .x-menu-inline .x-dropdown .x-anchor {
  display: flex;
}

.x-menu-modal li:before, .x-menu-modal li:after, .x-menu-layered li:before, .x-menu-layered li:after, .x-menu-collapsed li:before, .x-menu-collapsed li:after {
  content: "";
  display: table;
}

.x-menu-modal li:after, .x-menu-layered li:after, .x-menu-collapsed li:after {
  clear: both;
}

.x-bar-v .x-menu-collapsed, .x-bar-v .x-menu-collapsed > li {
  width: inherit;
}

.x-menu-inline {
  display: flex;
}

.x-menu-inline > li {
  flex-direction: inherit;
  justify-content: inherit;
  align-items: inherit;
  display: flex;
}

.x-menu-layered {
  position: relative;
  overflow: hidden;
}

.x-menu-layered .menu-item-has-children {
  position: static;
}

.x-menu-layered ul {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.x-menu-layered li {
  pointer-events: none;
}

.x-menu-layered .x-anchor {
  opacity: 0;
  pointer-events: none;
  transform: translate(25px);
}

.x-prev-layer > li > .x-anchor {
  transform: translate(-25px);
}

.x-current-layer > li > .x-anchor {
  opacity: 1;
  pointer-events: auto;
  transform: translate(0);
}

.x-modal {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 99999999;
  position: fixed;
  inset: 0;
}

.x-modal.x-active {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.x-modal-bg {
  z-index: 1;
  display: block;
  position: fixed;
  inset: 0;
}

.x-modal-close {
  width: 1em;
  height: 1em;
  text-align: center;
  opacity: 0;
  z-index: 3;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 0;
  line-height: 1em;
  display: block;
  position: fixed;
  transform: scale(0);
}

.x-modal-close span {
  display: block;
}

.x-modal.x-active .x-modal-close {
  opacity: 1;
  transform: scale(1);
}

.x-modal-close-top {
  top: 0;
}

.x-modal-close-left {
  left: 0;
}

.x-modal-close-right {
  right: 0;
}

.x-modal-close-bottom {
  bottom: 0;
}

.admin-bar .x-modal-close-top {
  top: 32px;
}

@media screen and (max-width: 782px) {
  .admin-bar .x-modal-close-top {
    top: 46px;
  }
}

.x-modal-content-outer {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: table;
  position: relative;
}

.x-modal-content-inner {
  vertical-align: middle;
  display: table-cell;
}

.x-modal-content {
  width: 100%;
  margin: 0 auto;
  transform: translate3d(0, -15px, 0);
}

.x-modal.x-active .x-modal-content {
  transform: translate3d(0, 0, 0);
}

.admin-bar .x-modal, .admin-bar .x-modal-bg {
  top: 32px;
}

@media screen and (max-width: 782px) {
  .admin-bar .x-modal, .admin-bar .x-modal-bg {
    top: 46px;
  }
}

.x-off-canvas {
  visibility: hidden;
  pointer-events: none;
  z-index: 99999998;
  position: fixed;
  inset: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.x-off-canvas.x-active {
  visibility: visible;
  pointer-events: auto;
}

.x-off-canvas-bg {
  opacity: 0;
  z-index: 1;
  display: block;
  position: absolute;
  inset: 0;
  transform: translate3d(0, 0, 0);
}

.x-off-canvas.x-active .x-off-canvas-bg {
  opacity: 1;
}

.x-off-canvas-close {
  text-align: center;
  opacity: 0;
  z-index: 3;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 0;
  line-height: 1;
  display: block;
  position: absolute;
  top: 0;
  transform: scale(0);
}

.x-off-canvas-close span {
  display: block;
}

.x-off-canvas.x-active .x-off-canvas-close {
  opacity: 1;
  transform: scale(1);
}

.x-off-canvas-content {
  width: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
}

.x-off-canvas.x-active .x-off-canvas-content {
  transform: translate3d(0, 0, 0);
}

.x-off-canvas-close-left {
  left: 0;
}

.x-off-canvas-close-right {
  right: 0;
}

.x-off-canvas-content-left {
  left: 0;
  transform: translate3d(-115%, 0, 0);
}

.x-off-canvas-content-right {
  right: 0;
  transform: translate3d(115%, 0, 0);
}

.admin-bar .x-off-canvas {
  top: 32px;
}

@media screen and (max-width: 782px) {
  .admin-bar .x-off-canvas {
    top: 46px;
  }
}

[data-x-particle] {
  opacity: 0;
  pointer-events: none;
  speak: none;
  z-index: 0;
  display: block;
  position: absolute;
}

[data-x-particle] span {
  border-radius: inherit;
  background-color: currentColor;
  display: block;
  position: absolute;
  inset: 0;
}

[data-x-particle*="inside"][data-x-particle*="t_"] {
  top: 0;
}

[data-x-particle*="inside"][data-x-particle*="_l"] {
  left: 0;
}

[data-x-particle*="inside"][data-x-particle*="_r"] {
  right: 0;
}

[data-x-particle*="inside"][data-x-particle*="b_"] {
  bottom: 0;
}

[data-x-particle*="inside"][data-x-particle*="c_"]:not([data-x-particle*="c_c"]) {
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}

[data-x-particle*="inside"][data-x-particle*="_c"]:not([data-x-particle*="c_c"]) {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

[data-x-particle*="inside"][data-x-particle*="c_c"] {
  top: 50%;
  left: 50%;
}

[data-x-particle*="overlap"][data-x-particle*="t_"] {
  top: 0%;
}

[data-x-particle*="overlap"][data-x-particle*="c_"] {
  top: 50%;
}

[data-x-particle*="overlap"][data-x-particle*="b_"] {
  top: 100%;
}

[data-x-particle*="overlap"][data-x-particle*="_l"] {
  left: 0%;
}

[data-x-particle*="overlap"][data-x-particle*="_c"] {
  left: 50%;
}

[data-x-particle*="overlap"][data-x-particle*="_r"] {
  left: 100%;
}

[data-x-particle~="scale-x_y"] {
  transform: scale3d(0, 0, 1);
}

[data-x-particle~="scale-x"] {
  transform: scale3d(0, 1, 1);
}

[data-x-particle~="scale-y"] {
  transform: scale3d(1, 0, 1);
}

[data-x-particle*="overlap"], [data-x-particle*="inside"][data-x-particle*="c_c"] {
  transform: translate3d(-50%, -50%, 0);
}

[data-x-particle*="overlap"][data-x-particle~="scale-x_y"], [data-x-particle*="inside"][data-x-particle*="c_c"][data-x-particle~="scale-x_y"] {
  transform: translate3d(-50%, -50%, 0)scale3d(0, 0, 1);
}

[data-x-particle*="overlap"][data-x-particle~="scale-x"], [data-x-particle*="inside"][data-x-particle*="c_c"][data-x-particle~="scale-x"] {
  transform: translate3d(-50%, -50%, 0)scale3d(0, 1, 1);
}

[data-x-particle*="overlap"][data-x-particle~="scale-y"], [data-x-particle*="inside"][data-x-particle*="c_c"][data-x-particle~="scale-y"] {
  transform: translate3d(-50%, -50%, 0)scale3d(1, 0, 1);
}

.has-particle [data-x-particle][class*="active"], .has-particle:hover [data-x-particle], .has-particle.x-active [data-x-particle], [data-x-effect-provider*="particles"]:hover [data-x-particle] {
  opacity: 1;
}

.has-particle [data-x-particle][class*="active"][data-x-particle*="scale"], .has-particle:hover [data-x-particle][data-x-particle*="scale"], .has-particle.x-active [data-x-particle][data-x-particle*="scale"], [data-x-effect-provider*="particles"]:hover [data-x-particle][data-x-particle*="scale"] {
  transform: scale3d(1, 1, 1);
}

.has-particle [data-x-particle][class*="active"][data-x-particle*="scale"][data-x-particle*="overlap"], .has-particle [data-x-particle][class*="active"][data-x-particle*="scale"][data-x-particle*="inside"][data-x-particle*="c_c"], .has-particle:hover [data-x-particle][data-x-particle*="scale"][data-x-particle*="overlap"], .has-particle:hover [data-x-particle][data-x-particle*="scale"][data-x-particle*="inside"][data-x-particle*="c_c"], .has-particle.x-active [data-x-particle][data-x-particle*="scale"][data-x-particle*="overlap"], .has-particle.x-active [data-x-particle][data-x-particle*="scale"][data-x-particle*="inside"][data-x-particle*="c_c"], [data-x-effect-provider*="particles"]:hover [data-x-particle][data-x-particle*="scale"][data-x-particle*="overlap"], [data-x-effect-provider*="particles"]:hover [data-x-particle][data-x-particle*="scale"][data-x-particle*="inside"][data-x-particle*="c_c"] {
  transform: translate3d(-50%, -50%, 0)scale3d(1, 1, 1);
}

.ps {
  overflow-anchor: none;
  touch-action: auto;
  -ms-overflow-style: none;
  -ms-touch-action: auto;
  overflow: hidden !important;
}

.ps__rail-x, .ps__rail-y {
  opacity: 0;
  transition: background-color .2s, opacity .2s;
  display: none;
  position: absolute;
}

.ps__rail-x {
  height: 15px;
  bottom: 0;
}

.ps__rail-y {
  width: 15px;
  right: 0;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  background-color: #0000;
  display: block;
}

.ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y, .ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y {
  opacity: .6;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  opacity: .9;
  background-color: #eee;
}

.ps__thumb-x, .ps__thumb-y {
  background-color: #aaa;
  border-radius: 100px;
  transition-duration: .2s;
  transition-timing-function: ease;
  position: absolute;
}

.ps__thumb-x {
  height: 6px;
  transition-property: background-color, height;
  bottom: 2px;
}

.ps__thumb-y {
  width: 6px;
  transition-property: background-color, width;
  right: 2px;
}

.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x {
  height: 11px;
  background-color: #999;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  width: 11px;
  background-color: #999;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.x-rating {
  min-width: 1px;
  display: inline-flex;
}

.x-rating-graphic {
  min-width: 1px;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  letter-spacing: 0 !important;
  line-height: 1 !important;
}

.x-rating-text {
  min-width: 1px;
}

.x-search {
  cursor: text;
  border: 0;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.x-search:focus {
  outline: 0;
}

.x-search-btn {
  cursor: pointer;
  background-color: #0000;
  border: 0;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  line-height: 1;
  display: block;
}

.x-search-btn:focus {
  outline: 0;
}

.x-search-btn svg {
  width: 1em;
  height: 1em;
  line-height: inherit;
  stroke: currentColor;
  margin: 0 auto;
  display: block;
}

.x-search-btn-submit {
  order: 1;
}

.x-search-btn-clear {
  visibility: hidden;
  pointer-events: none;
  order: 3;
}

.x-search-has-content .x-search-btn-clear {
  visibility: visible;
  pointer-events: auto;
}

.x-search-input {
  width: 100%;
  min-width: 1px;
  appearance: none;
  flex-grow: 1;
  order: 2;
  height: auto !important;
  box-shadow: none !important;
  background-color: #0000 !important;
  border: 0 !important;
  padding: 0 !important;
}

.x-search-input::-ms-clear {
  display: none;
}

.x-search-input::-webkit-input-placeholder {
  color: currentColor;
  opacity: 1;
}

.x-search-input::-moz-placeholder {
  color: currentColor;
  opacity: 1;
}

.x-search-input:-ms-input-placeholder {
  color: currentColor;
  opacity: 1;
}

[class*="x-separator"] {
  z-index: -1;
  margin: 0 auto;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

[class*="x-separator"][class*="top"] {
  top: 0;
  transform: translate(0, -100%);
}

[class*="x-separator"][class*="bottom"] {
  bottom: 0;
  transform: translate(0, 100%);
}

[class*="x-separator"] svg {
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
}

[data-x-stem] {
  inset: auto;
}

[data-x-stem*="d"] {
  top: 100%;
}

[data-x-stem*="l"] {
  right: 0;
}

[data-x-stem*="r"] {
  left: 0;
}

[data-x-stem*="u"] {
  bottom: 100%;
}

[data-x-stem*="d"][data-x-stem-top*="h"] {
  top: 0;
}

[data-x-stem*="l"][data-x-stem-top*="h"] {
  right: 100%;
}

[data-x-stem*="r"][data-x-stem-top*="h"] {
  left: 100%;
}

[data-x-stem*="u"][data-x-stem-top*="h"] {
  bottom: 0;
}

[data-x-stem] [data-x-stem*="d"] {
  top: 0;
}

[data-x-stem] [data-x-stem*="l"] {
  right: 100%;
}

[data-x-stem] [data-x-stem*="r"] {
  left: 100%;
}

[data-x-stem] [data-x-stem*="u"] {
  bottom: 0;
}

.x-text {
  min-width: 1px;
}

a.x-text {
  display: block;
}

.x-text-headline {
  position: relative;
}

.x-text-content {
  display: flex;
}

.x-text-content-text {
  min-width: 1px;
  max-width: 100%;
  flex-grow: 1;
}

.x-text-content-text, .x-text-content-text-primary, .x-text-content-text-subheadline {
  display: block;
}

.x-text-content-text > p:last-child, .x-text-content-text > ul:last-child, .x-text-content-text > ol:last-child, .x-text-content-text-primary > p:last-child, .x-text-content-text-primary > ul:last-child, .x-text-content-text-primary > ol:last-child, .x-text-content-text-subheadline > p:last-child, .x-text-content-text-subheadline > ul:last-child, .x-text-content-text-subheadline > ol:last-child {
  margin-bottom: 0;
}

.x-text-content-text-primary, .x-text-content-text-subheadline {
  margin-top: 0;
  margin-bottom: 0;
}

.x-toggle {
  pointer-events: none;
  speak: none;
  display: block;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.x-toggle > span {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: currentColor;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.x-toggle-burger, .x-toggle-grid, .x-toggle-more-h, .x-toggle-more-v {
  height: 1em;
}

.x-toggle-grid, .x-toggle-more-h, .x-toggle-more-v {
  width: 1em;
}

.x-toggle-more-h, .x-toggle-more-v {
  border-radius: 100em;
}

.x-toggle-more-v {
  transform: translate3d(0, 0, 0)rotate(90deg);
}

[data-x-toggle-anim] {
  animation: none;
}

[data-x-toggle-anim].x-paused {
  animation-play-state: paused;
}

[data-x-toggle-anim].x-running {
  animation-play-state: running;
}

@keyframes x-bun-t-1 {
  50% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0)rotate(45deg);
  }
}

@keyframes x-patty-1 {
  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes x-bun-b-1 {
  50% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0)rotate(-45deg);
  }
}

@keyframes x-grid-1 {
  50% {
    transform: rotate3d(0, 0, 1, 0);
    box-shadow: 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0;
  }

  100% {
    transform: rotate3d(0, 0, 1, -45deg);
    box-shadow: -1em 0, -2em 0, 0 -1em, 0 2em, 0 -2em, 0 1em, 2em 0, 1em 0;
  }
}

@keyframes x-more-1-1 {
  50% {
    transform: translate3d(0, 0, 0);
  }

  75% {
    border-radius: 0;
  }

  100% {
    border-radius: 0;
    transform: translate3d(0, 0, 0)rotate(45deg)scale3d(4, .5, 1);
  }
}

@keyframes x-more-2-1 {
  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes x-more-3-1 {
  50% {
    transform: translate3d(0, 0, 0)rotate(-45deg);
  }

  75% {
    border-radius: 0;
  }

  100% {
    border-radius: 0;
    transform: translate3d(0, 0, 0)rotate(-45deg)scale3d(4, .5, 1);
  }
}

.x-overflow-hidden {
  overflow: hidden !important;
}

p {
  margin: 0 0 1.313em;
}

small {
  font-size: smaller;
}

b, strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

cite {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  text-rendering: optimizelegibility;
  margin: 1.25em 0 .2em;
}

h1, .h1 {
  margin-top: 1em;
  font-size: 400%;
  line-height: 1.1;
}

h2, .h2 {
  font-size: 285.7%;
  line-height: 1.2;
}

h3, .h3 {
  font-size: 228.5%;
  line-height: 1.3;
}

h4, h5, h6, .h4, .h5, .h6 {
  margin-top: 1.75em;
  margin-bottom: .5em;
  line-height: 1.4;
}

h4, .h4 {
  font-size: 171.4%;
}

h5, .h5 {
  font-size: 150%;
}

h6, .h6 {
  text-transform: uppercase;
  font-size: 100%;
}

ul, ol {
  margin: 0 0 1.313em 1.655em;
  padding: 0;
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul.unstyled, ol.unstyled, ul.inline, ol.inline {
  margin-left: 0;
  list-style: none;
}

ul.inline > li, ol.inline > li {
  padding-left: 1em;
  padding-right: 1em;
  display: inline-block;
}

dl {
  margin-bottom: 1.313em;
}

dt {
  font-weight: bolder;
}

dd {
  margin-left: 1.313em;
}

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
}

address {
  margin-bottom: 1.313em;
  font-style: normal;
}

hr {
  height: 0;
  border: 0;
  border-top: 1px solid #f2f2f2;
  margin: 1.313em 0;
}

blockquote {
  color: #272727;
  border: 1px solid #f2f2f2;
  border-left: 0;
  border-right: 0;
  margin: 1.313em 0;
  padding: 1em 0;
  font-size: 171.4%;
  font-weight: 400;
  line-height: 1.3;
}

code, pre {
  color: #555;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  font-family: Consolas, Andale Mono WT, Andale Mono, Lucida Console, Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono, Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace;
}

code {
  border-radius: 3px;
  padding: .143em .286em;
  font-size: .85em;
}

pre {
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  border-radius: 4px;
  margin: 0 0 1.313em;
  padding: .786em 1.071em;
  font-size: 1.15em;
  line-height: 1.7;
  display: block;
}

pre code {
  background-color: #0000;
  border: 0;
  padding: 0;
}

.pre-scrollable {
  max-height: 360px;
  overflow-y: scroll;
}

form {
  margin: 0 0 1.313em;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 0;
  padding: 0;
  line-height: 1.4;
  display: block;
}

label, input, button, select, textarea {
  font-size: 100%;
  line-height: 1.7;
}

input, button, select, textarea {
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
}

label {
  margin-bottom: 2px;
  display: block;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #c5c5c5;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #c5c5c5;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #c5c5c5;
}

.radio, .checkbox {
  min-height: 18px;
  padding-left: 18px;
}

.radio input[type="radio"], .checkbox input[type="checkbox"] {
  float: left;
  margin-left: -18px;
  position: relative;
}

.controls > .radio:first-child, .controls > .checkbox:first-child {
  padding-top: 5px;
}

.radio.inline, .checkbox.inline {
  vertical-align: middle;
  margin-bottom: 0;
  padding-top: 5px;
  display: inline-block;
}

.radio.inline + .radio.inline, .checkbox.inline + .checkbox.inline {
  margin-left: 10px;
}

input[disabled], select[disabled], textarea[disabled], input[readonly], select[readonly], textarea[readonly] {
  cursor: not-allowed;
}

input[disabled]:not(input[type="submit"]), select[disabled], textarea[disabled], input[readonly]:not(input[type="submit"]), select[readonly], textarea[readonly] {
  background-color: #eee;
}

input[type="radio"][disabled], input[type="checkbox"][disabled], input[type="radio"][readonly], input[type="checkbox"][readonly] {
  background-color: #0000;
}

.wpcf7 p {
  margin-bottom: .32825em;
}

.wpcf7 select, .wpcf7 textarea, .wpcf7 input[type="text"], .wpcf7 input[type="password"], .wpcf7 input[type="datetime"], .wpcf7 input[type="datetime-local"], .wpcf7 input[type="date"], .wpcf7 input[type="month"], .wpcf7 input[type="time"], .wpcf7 input[type="week"], .wpcf7 input[type="number"], .wpcf7 input[type="email"], .wpcf7 input[type="url"], .wpcf7 input[type="search"], .wpcf7 input[type="tel"], .wpcf7 input[type="color"] {
  width: 100%;
}

.wpcf7 select:not(:last-child), .wpcf7 textarea:not(:last-child), .wpcf7 input[type="text"]:not(:last-child), .wpcf7 input[type="password"]:not(:last-child), .wpcf7 input[type="datetime"]:not(:last-child), .wpcf7 input[type="datetime-local"]:not(:last-child), .wpcf7 input[type="date"]:not(:last-child), .wpcf7 input[type="month"]:not(:last-child), .wpcf7 input[type="time"]:not(:last-child), .wpcf7 input[type="week"]:not(:last-child), .wpcf7 input[type="number"]:not(:last-child), .wpcf7 input[type="email"]:not(:last-child), .wpcf7 input[type="url"]:not(:last-child), .wpcf7 input[type="search"]:not(:last-child), .wpcf7 input[type="tel"]:not(:last-child), .wpcf7 input[type="color"]:not(:last-child) {
  margin-bottom: 0;
}

.wpcf7-response-output, .wpcf7-display-none {
  display: none;
}

.wpcf7-not-valid-tip {
  margin: 1px 0 0;
  font-size: .75em;
  display: block;
}

.wpcf7 .ajax-loader {
  visibility: hidden;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  border: 0;
  margin: 0 0 0 1em;
  padding: 0;
  font-size: 16px;
  display: inline-block;
  position: relative;
}

.wpcf7 .ajax-loader:before {
  content: "";
  width: 1em;
  height: 1em;
  border: 2px solid;
  border-top-color: #0000;
  border-radius: 1em;
  animation: 1.25s linear infinite xCF7Spin;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.wpcf7 .ajax-loader.is-active {
  visibility: visible;
}

.wpcf7-list-item {
  margin: 0 0 0 1em;
}

.wpcf7-list-item-label {
  margin: 0 .5em 0 0;
}

@keyframes xCF7Spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
  height: 2.65em;
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 9px;
  padding: 0 .65em;
  font-size: 13px;
  line-height: normal;
  display: inline-block;
  box-shadow: inset 0 1px 1px #00000013;
}

input, textarea {
  width: auto;
}

textarea {
  height: auto;
  line-height: 1.3;
}

textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input {
  transition: border .2s linear, box-shadow .2s linear;
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
  border-color: #0000004d;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #0003;
}

input[type="radio"], input[type="checkbox"] {
  cursor: pointer;
  margin: .425em 0;
  line-height: normal;
}

@media (max-width: 479.98px) {
  input[type="radio"], input[type="checkbox"] {
    border: 1px solid #ccc;
  }
}

input[type="file"], input[type="image"], input[type="submit"], input[type="reset"], input[type="button"], input[type="radio"], input[type="checkbox"] {
  width: auto;
}

select, input[type="file"] {
  height: 2.65em;
  padding-top: .5em;
  padding-bottom: .5em;
}

select {
  width: auto;
}

select[multiple], select[size] {
  height: auto;
  border: 1px solid #ddd;
}

.form-search, .widget_product_search form {
  margin-bottom: 0;
  line-height: 1;
  position: relative;
}

.form-search:before, .widget_product_search form:before {
  content: "ï€‚";
  text-decoration: inherit;
  text-shadow: none;
  color: #272727;
  text-decoration: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: -6px;
  font-size: 12px;
  line-height: 1;
  display: block;
  position: absolute;
  bottom: 50%;
  left: 12px;
  font-family: FontAwesome !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.form-search .search-query, .form-search input[type="search"], .widget_product_search form .search-query, .widget_product_search form input[type="search"] {
  width: 100%;
  margin-bottom: 0;
  padding-left: 30px;
}

textarea {
  padding-top: 6px;
  padding-bottom: 6px;
}

input[type="color"] {
  padding: 1px 3px;
}

input[type="file"] {
  margin-top: 2px;
}

.checkbox input[type="checkbox"] {
  top: 2px;
}

.radio input[type="radio"] {
  top: 1px;
}

#recaptcha_table #recaptcha_response_field {
  height: 16px;
  border-width: 1px;
  border-radius: 0;
  font-size: 10px;
  line-height: 16px;
}

table {
  width: 100%;
  border-spacing: 0;
  background-color: #0000;
  margin-bottom: 1.313em;
}

table th, table td {
  text-align: left;
  vertical-align: middle;
  padding: .5em .625em;
  line-height: 1.3;
}

table th {
  font-weight: bold;
}

table td {
  line-height: 1.4;
}

table thead th {
  vertical-align: bottom;
}

table caption + thead tr:first-child th, table caption + thead tr:first-child td, table colgroup + thead tr:first-child th, table colgroup + thead tr:first-child td, table thead:first-child tr:first-child th, table thead:first-child tr:first-child td {
  border-top: 0;
}

table p:last-child, table ul:last-child, table ol:last-child {
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
}

table th, table td {
  border-top: 1px solid #ddd;
}

table tbody + tbody {
  border-top: 2px solid #ddd;
}

.x-btn, .button, [type="submit"] {
  cursor: pointer;
  font-size: 16px;
  font-weight: inherit;
  text-align: center;
  vertical-align: middle;
  color: #fff;
  background-color: #ff2a13;
  border: 1px solid #ac1100;
  border-radius: 0;
  padding: .563em 1.125em .813em;
  line-height: 1.3;
  transition: all .15s linear;
  display: inline-block;
  position: relative;
}

.x-btn:hover, .button:hover, [type="submit"]:hover {
  color: #fff;
  background-color: #df1600;
  border-color: #600900;
  text-decoration: none;
}

.x-btn:hover, .x-btn:active, .button:hover, .button:active, [type="submit"]:hover, [type="submit"]:active {
  outline: 0 !important;
}

.x-btn[disabled], .x-btn.disabled, .button[disabled], .button.disabled, [type="submit"][disabled], [type="submit"].disabled {
  opacity: .25;
  cursor: not-allowed;
}

.x-btn.x-btn-jumbo {
  padding: .643em 1.429em .786em;
  font-size: 28px;
}

.x-btn.x-btn-x-large {
  padding: .714em 1.286em .857em;
  font-size: 21px;
}

.x-btn.x-btn-large {
  padding: .579em 1.105em .842em;
  font-size: 19px;
}

.x-btn.x-btn-regular {
  padding: .563em 1.125em .813em;
  font-size: 16px;
}

.x-btn.x-btn-small {
  padding: .429em 1.143em .643em;
  font-size: 14px;
}

.x-btn.x-btn-mini {
  padding: .385em .923em .538em;
  font-size: 13px;
}

.x-btn.x-btn-square {
  border-radius: 0;
}

.x-btn.x-btn-rounded {
  border-radius: .225em;
}

.x-btn.x-btn-pill {
  border-radius: 100em;
}

.x-btn-block {
  width: 100%;
  display: block;
}

.x-btn-block + .x-btn-block {
  margin-top: 1em;
}

.x-btn [class*="x-icon-"] {
  width: auto;
  height: auto;
  margin: 0 .5em 0 0;
  display: inline;
}

.x-btn [class*="x-icon-"]:before {
  display: inline !important;
  position: static !important;
  transform: none !important;
}

.x-btn.x-btn-icon-only [class*="x-icon-"] {
  margin: 0;
}

.x-btn-circle-wrap {
  display: inline-block;
  position: relative;
}

.x-btn-circle-wrap.x-btn-jumbo, .x-btn-circle-wrap.x-btn-x-large, .x-btn-circle-wrap.x-btn-large {
  margin: 0 0 21px;
  padding: 0;
}

.x-btn-circle-wrap:before, .x-btn-circle-wrap:after {
  content: "";
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
  display: block;
  position: relative;
}

.fade {
  opacity: 0;
  transition: opacity .3s;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

.collapsing {
  height: 0;
  transition: height .3s;
  position: relative;
  overflow: hidden;
}

.x-searchform-overlay {
  width: 100%;
  height: 100%;
  z-index: 2030;
  background-color: #ffffffec;
  transition: top .3s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.x-searchform-overlay.in {
  display: table;
}

.x-searchform-overlay .form-search {
  padding-bottom: 2em;
  font-size: 10px;
}

.x-searchform-overlay .form-search:before {
  display: none;
}

.x-searchform-overlay .form-search label {
  color: #272727;
  margin-bottom: .85em;
  font-size: 2.1em;
  line-height: 1.4;
}

.x-searchform-overlay .form-search .search-query {
  height: auto;
  letter-spacing: -.05em;
  color: #272727;
  box-shadow: none;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-size: 9.4em;
  line-height: 1;
}

.x-searchform-overlay .form-search .search-query:focus, .x-searchform-overlay .form-search .search-query:active {
  outline: 0;
}

@media (max-width: 766.98px) {
  .x-searchform-overlay .form-search {
    font-size: 8px;
  }
}

@media (max-width: 479.98px) {
  .x-searchform-overlay .form-search {
    font-size: 6px;
  }
}

.x-searchform-overlay-inner {
  vertical-align: middle;
  display: table-cell;
}

.x-nav {
  margin-bottom: 1.313em;
  margin-left: 0;
  list-style: none;
}

.x-nav > li > a {
  cursor: pointer;
  display: block;
}

.x-nav > li > a:hover {
  background-color: #0000;
  text-decoration: none;
}

.x-nav > .right {
  float: right;
}

.x-nav-tabs {
  border: 1px solid #00000026;
  border-bottom: 0;
  margin-bottom: 0;
  position: relative;
  box-shadow: 0 .125em .275em #00000020;
}

.x-nav-tabs:before, .x-nav-tabs:after {
  content: " ";
  width: 0;
  display: table;
}

.x-nav-tabs:after {
  clear: both;
}

.x-nav-tabs > li {
  float: left;
  text-align: center;
  border-bottom: 1px solid #00000026;
  border-right: 1px solid #00000026;
  margin-bottom: 0;
}

@media (max-width: 978.98px) {
  .x-nav-tabs > li {
    border-right: 0;
  }
}

.x-nav-tabs > li > a {
  color: #bfbfbf;
  background-color: #f7f7f7;
  padding: .75em;
  font-size: 14px;
  line-height: 1.3;
  box-shadow: inset 0 1px #ffffffd9;
}

.x-nav-tabs > li > a:hover {
  color: gray;
  background-color: #f7f7f7;
}

.x-nav-tabs.left, .x-nav-tabs.right {
  width: 24%;
  max-width: 200px;
}

@media (max-width: 978.98px) {
  .x-nav-tabs.left, .x-nav-tabs.right {
    float: none;
    width: 100%;
    max-width: none;
  }
}

.x-nav-tabs.left > .x-nav-tabs-item, .x-nav-tabs.right > .x-nav-tabs-item {
  float: none;
  width: 100%;
  border-bottom: 1px solid #00000026;
  border-left: 0;
  border-right: 0;
}

.x-nav-tabs.left {
  border-right: 0;
}

@media (max-width: 978.98px) {
  .x-nav-tabs.left {
    border-right: 1px solid #00000026;
  }
}

.x-nav-tabs.right {
  border-left: 0;
}

@media (max-width: 978.98px) {
  .x-nav-tabs.right {
    border-left: 1px solid #00000026;
  }
}

.x-nav-tabs > .active > a, .x-nav-tabs > .active > a:hover {
  color: #272727;
  cursor: default;
  background-color: #fff;
  box-shadow: inset 0 3px #ff2a13;
}

.x-nav-tabs.one-up > li {
  width: 100%;
}

.x-nav-tabs.one-up > li:nth-child(n) {
  border-right: 0;
}

.x-nav-tabs.two-up > li {
  width: 50%;
}

.x-nav-tabs.two-up > li:nth-child(2n) {
  border-right: 0;
}

.x-nav-tabs.three-up > li {
  width: 33.3333%;
}

.x-nav-tabs.three-up > li:nth-child(3n) {
  border-right: 0;
}

.x-nav-tabs.four-up > li {
  width: 25%;
}

.x-nav-tabs.four-up > li:nth-child(4n) {
  border-right: 0;
}

.x-nav-tabs.five-up > li {
  width: 20%;
}

.x-nav-tabs.five-up > li:nth-child(5n) {
  border-right: 0;
}

@media (max-width: 978.98px) {
  .x-nav-tabs.two-up > li, .x-nav-tabs.three-up > li, .x-nav-tabs.four-up > li, .x-nav-tabs.five-up > li {
    float: none;
    width: 100%;
  }
}

.tabbable:before, .tabbable:after {
  content: " ";
  width: 0;
  display: table;
}

.tabbable:after {
  clear: both;
}

.x-tab-content {
  background-color: #fff;
  border: 1px solid #00000026;
  border-top: 0;
  margin-bottom: 1.313em;
  position: relative;
  overflow: auto;
  box-shadow: 0 .125em .275em #00000020;
}

.x-tab-content img, .x-tab-content .x-btn {
  transform: translate3d(0, 0, 0);
}

.x-tab-content .x-tab-pane {
  padding: 4%;
}

.x-nav-tabs.left + .x-tab-content, .x-nav-tabs.right + .x-tab-content {
  border-top: 1px solid #00000026;
}

@media (max-width: 978.98px) {
  .x-nav-tabs.left + .x-tab-content, .x-nav-tabs.right + .x-tab-content {
    border-top: 0;
  }
}

.x-tab-content > .x-tab-pane {
  display: none;
}

.x-tab-content > .active {
  display: block;
}

.x-nav-articles {
  float: right;
  width: 10%;
  text-align: right;
}

@media (max-width: 766.98px) {
  .x-nav-articles {
    float: none;
    width: 100%;
    text-align: center;
    margin-top: .65em;
  }
}

.x-nav-articles .next {
  margin-left: .5em;
}

.x-breadcrumb-wrap {
  letter-spacing: 1px;
  text-shadow: 0 1px #ffffff8c;
  text-transform: uppercase;
  background-color: #00000006;
  border-bottom: 1px solid #0000001a;
  margin: 0;
  padding: 1.05em 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
  box-shadow: 0 1px #ffffffb6;
}

.x-breadcrumb-wrap a {
  color: #bababa;
}

.x-breadcrumb-wrap a:hover {
  color: #ff2a13;
}

.x-breadcrumbs {
  float: left;
  width: 85%;
}

@media (max-width: 766.98px) {
  .x-breadcrumbs {
    float: none;
    width: 100%;
    text-align: center;
  }
}

.x-breadcrumbs a, .x-breadcrumbs span {
  display: inline-block;
}

.x-breadcrumbs .delimiter {
  color: #bababa;
  margin: 0 .25em 0 .45em;
}

.x-breadcrumbs .current {
  color: #272727;
}

.x-pagination {
  height: 32px;
  text-align: center;
  margin: 40px 0 0;
}

.x-pagination ul {
  margin: 0;
  display: inline-block;
}

.x-pagination li {
  display: inline;
}

.x-pagination a, .x-pagination span {
  float: left;
  width: 32px;
  height: 32px;
  text-shadow: 0 1px 1px #ffffffd9;
  color: #bababa;
  background-color: #fff;
  border-radius: 4px;
  margin: 0 .35em;
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none;
  box-shadow: inset 0 0 #0000, 0 .1em .45em #00000040;
}

.x-pagination a:hover {
  color: #7a7a7a;
}

@media (max-width: 766.98px) {
  .x-pagination a.prev, .x-pagination a.next, .x-pagination a.prev-next {
    display: none;
  }
}

.x-pagination span.current {
  text-shadow: 0 1px 2px #000000a6;
  color: #fff;
  background-color: #ff2a13;
  box-shadow: inset 0 .1em .35em #000000a6, 0 1px #fffffff2;
}

.x-pagination span.pages {
  display: none;
}

.x-pagination span.dots, .x-pagination span.expand {
  width: 20px;
  color: #272727;
  box-shadow: none;
  background-color: #0000;
  margin: 0;
}

.tooltip {
  z-index: 1020;
  visibility: visible;
  text-shadow: none;
  opacity: 0;
  padding: .455em;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.2;
  transition: opacity .3s;
  display: block;
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.tooltip.top {
  margin-top: -.273em;
}

.tooltip.right {
  margin-left: .273em;
}

.tooltip.bottom {
  margin-top: .273em;
}

.tooltip.left {
  margin-left: -.273em;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #272727;
  border-radius: 4px;
  padding: .727em .818em;
  text-decoration: none;
}

.tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.tooltip.top .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #272727;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

.tooltip.right .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-right-color: #272727;
  margin-top: -5px;
  top: 50%;
  left: 0;
}

.tooltip.left .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-left-color: #272727;
  margin-top: -5px;
  top: 50%;
  right: 0;
}

.tooltip.bottom .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #272727;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

.popover {
  z-index: 1010;
  width: 236px;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: 6px;
  padding: 1px;
  transition: opacity .3s;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 5px 10px #0003;
}

.popover.top {
  margin-top: -.825em;
}

.popover.right {
  margin-left: .825em;
}

.popover.bottom {
  margin-top: .825em;
}

.popover.left {
  margin-left: -.825em;
}

.popover-title {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: .571em .75em;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
}

.popover-content {
  padding: .563em .875em;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
}

.popover-content p, .popover-content ul, .popover-content ol {
  margin-bottom: 0;
}

.popover-content ul, .popover-content ol {
  margin-left: 1.286em;
}

.popover .arrow, .popover .arrow:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #0000;
  display: inline-block;
  position: absolute;
}

.popover .arrow:after {
  content: "";
  z-index: -1;
}

.popover.top .arrow {
  border-width: 10px 10px 0;
  border-top-color: #fff;
  margin-left: -10px;
  bottom: -10px;
  left: 50%;
}

.popover.top .arrow:after {
  border-width: 11px 11px 0;
  border-top-color: #00000040;
  bottom: -1px;
  left: -11px;
}

.popover.right .arrow {
  border-width: 10px 10px 10px 0;
  border-right-color: #fff;
  margin-top: -10px;
  top: 50%;
  left: -10px;
}

.popover.right .arrow:after {
  border-width: 11px 11px 11px 0;
  border-right-color: #00000040;
  bottom: -11px;
  left: -1px;
}

.popover.bottom .arrow {
  border-width: 0 10px 10px;
  border-bottom-color: #f7f7f7;
  margin-left: -10px;
  top: -10px;
  left: 50%;
}

.popover.bottom .arrow:after {
  border-width: 0 11px 11px;
  border-bottom-color: #00000040;
  top: -1px;
  left: -11px;
}

.popover.left .arrow {
  border-width: 10px 0 10px 10px;
  border-left-color: #fff;
  margin-top: -10px;
  top: 50%;
  right: -10px;
}

.popover.left .arrow:after {
  border-width: 11px 0 11px 11px;
  border-left-color: #00000040;
  bottom: -11px;
  right: -1px;
}

.flex-container a:active, .x-flexslider a:active {
  outline: none;
}

.x-slides, .flex-control-nav, .flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none !important;
}

.x-flexslider {
  margin: 0;
  padding: 0;
}

.x-flexslider .x-slides .x-slide {
  display: none;
  position: relative;
}

.x-flexslider .x-slides img {
  width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.x-slides:before, .x-slides:after {
  content: " ";
  width: 0;
  display: table;
}

.x-slides:after {
  clear: both;
}

.no-js .x-slides > li:first-child {
  display: block;
}

.x-flexslider {
  width: 100%;
  margin: 0 0 1.313em;
  position: relative;
  overflow: hidden;
}

.x-flexslider-featured-gallery {
  background: #fff;
}

.flex-viewport {
  max-height: 2000px;
  transition: all 1s;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flex-direction-nav a {
  cursor: pointer;
  height: 30px;
  width: 30px;
  text-align: center;
  text-shadow: none;
  color: #fff;
  opacity: .5;
  z-index: 10;
  background-color: #ff2a13;
  border-radius: 4px;
  font-size: 14px;
  line-height: 30px;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 14px;
}

.flex-direction-nav a [class*="x-icon-"] {
  width: auto;
}

.flex-direction-nav a:hover {
  color: #fff;
  opacity: 1;
  text-decoration: none;
}

.flex-direction-nav a.flex-prev {
  left: 14px;
}

.flex-direction-nav a.flex-next {
  left: 50px;
}

.flex-control-nav {
  text-align: center;
  z-index: 10;
  line-height: 1;
  position: absolute;
}

.flex-control-nav:not(.flex-control-thumbs) {
  background-color: #fff;
  border-radius: 4px;
  padding: 0 4px;
  top: 14px;
  right: 14px;
}

.flex-control-nav:not(.flex-control-thumbs) li {
  text-indent: -9999px;
  margin: 7px 4px 6px;
}

.flex-control-nav.flex-control-thumbs {
  padding: 1em;
  top: 0;
  right: 0;
}

.flex-control-nav.flex-control-thumbs li {
  background-color: #000;
  box-shadow: 0 .15em .625em #00000037;
}

.flex-control-nav.flex-control-thumbs li:not(:first-child) {
  margin-left: .5em;
}

.flex-control-nav.flex-control-thumbs img {
  width: auto;
  height: 1.5em;
  vertical-align: top;
  cursor: pointer;
  transition: opacity .3s;
}

.flex-control-nav.flex-control-thumbs img:not(:hover):not(.flex-active) {
  opacity: .65;
}

.flex-control-nav li {
  display: inline-block;
}

.flex-control-nav a {
  width: 14px;
  height: 14px;
  background-color: #272727;
  border-radius: 3px;
  display: block;
}

.flex-control-nav a:hover {
  cursor: pointer;
  background-color: #ff2a13;
}

.flex-control-nav a.flex-active {
  background-color: #ff2a13;
}

.x-slider-container {
  position: relative;
}

.x-slider-container.below {
  border-bottom: 5px solid #ff2a13;
}

.x-slider-container.bg-video {
  background-color: #000;
}

.x-slider-container .rev_slider_wrapper {
  padding: 0 !important;
}

.page-template-template-blank-3-php .x-slider-container.above, .page-template-template-blank-6-php .x-slider-container.above {
  border-bottom: 5px solid #ff2a13;
}

.rev_slider_wrapper .x-btn {
  padding: .563em 1.125em .813em !important;
  font-size: 1.6rem !important;
}

@media (max-width: 978.98px) {
  .rev_slider_wrapper .x-btn {
    font-size: 1.3rem !important;
  }
}

@media (max-width: 766.98px) {
  .rev_slider_wrapper .x-btn {
    font-size: 1.1rem !important;
  }
}

@media (max-width: 479.98px) {
  .rev_slider_wrapper .x-btn {
    font-size: .8rem !important;
  }
}

.x-slider-scroll-bottom {
  width: 60px;
  height: 60px;
  text-align: center;
  color: #fff;
  z-index: 1020;
  border: 3px solid;
  border-radius: 100em;
  font-size: 41px;
  line-height: 53px;
  transition: all .3s;
  display: block;
  position: absolute;
}

.x-slider-scroll-bottom.top {
  top: 20px;
}

.x-slider-scroll-bottom.left {
  left: 20px;
}

.x-slider-scroll-bottom.right {
  right: 20px;
}

.x-slider-scroll-bottom.bottom {
  bottom: 20px;
}

.x-slider-scroll-bottom.center {
  margin-left: -30px;
  left: 50%;
}

.x-slider-scroll-bottom:hover {
  color: #ff2a13;
}

@media (min-width: 767px) and (max-width: 978.98px) {
  .x-slider-scroll-bottom {
    width: 50px;
    height: 50px;
    font-size: 38px;
    line-height: 43px;
  }

  .x-slider-scroll-bottom.center {
    margin-left: -25px;
  }
}

@media (max-width: 766.98px) {
  .x-slider-scroll-bottom {
    width: 46px;
    height: 46px;
    border-width: 2px;
    font-size: 31px;
    line-height: 40px;
  }

  .x-slider-scroll-bottom.center {
    margin-left: -23px;
  }
}

@media (max-width: 479.98px) {
  .x-slider-scroll-bottom {
    width: 40px;
    height: 40px;
    font-size: 25px;
    line-height: 34px;
  }

  .x-slider-scroll-bottom.center {
    margin-left: -20px;
  }
}

.x-loading, .x-loading:before, .x-loading:after {
  width: 2.5em;
  height: 2.5em;
  border-radius: 100em;
  animation: 2s ease-in-out infinite xLoader;
  display: block;
}

.x-loading {
  text-indent: -9999em;
  color: currentColor;
  margin: 0 auto;
  font-size: 8px;
  animation-delay: -.16s;
  position: relative;
  transform: translateZ(0);
}

.x-loading:before, .x-loading:after {
  content: "";
  position: absolute;
  top: 0;
}

.x-loading:before {
  animation-delay: -.32s;
  left: -4em;
}

.x-loading:after {
  left: 4em;
}

@keyframes xLoader {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em;
  }
}

.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope, .isotope .isotope-item {
  transition-duration: .8s;
}

.isotope {
  transition-property: height, width;
}

.isotope .isotope-item {
  transition-property: transform;
}

.isotope.no-transition, .isotope.no-transition .isotope-item, .isotope .isotope-item.no-transition {
  transition-duration: 0s;
}

.x-iso-container {
  box-sizing: content-box;
}

.x-iso-container.cols-1 > * {
  width: 100%;
}

.x-iso-container.cols-2 > * {
  width: 49.95%;
}

.x-iso-container.cols-3 > * {
  width: 33.28%;
}

@media (min-width: 767px) and (max-width: 978.98px) {
  .x-iso-container.cols-3 > * {
    width: 49.95%;
  }
}

.x-iso-container.cols-4 > * {
  width: 33.28%;
}

@media (min-width: 1200px) {
  .x-iso-container.cols-4 > * {
    width: 24.95%;
  }
}

@media (min-width: 767px) and (max-width: 978.98px) {
  .x-iso-container.cols-4 > * {
    width: 49.95%;
  }
}

@media (max-width: 766.98px) {
  .x-iso-container[class*="cols-"] > * {
    width: 100%;
  }
}

#x-isotope-loading {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
}

.x-iso-container {
  margin: 0 -1em;
}

.x-iso-container > * {
  opacity: 0;
  margin: 0 !important;
  padding: 0 1em 2em !important;
}

.x-iso-container + .pagination {
  margin-top: 5px;
}

.option-set {
  margin-top: .875333em;
  margin-bottom: 0;
}

.x-portfolio-filters, .x-portfolio-filter {
  float: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  color: #c5c5c5;
  color: #00000060;
  background-color: #0000;
  border: 1px solid #00000020;
  border-radius: .35em;
  margin: .45em .375em;
  padding: .692em 1.154em .769em;
  font-size: 13px;
  line-height: 1.3;
  transition: color .3s, border-color .3s, background-color .3s, box-shadow .3s;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 0 #0000, 0 1px 1px #fffffff2;
}

@media (max-width: 766.98px) {
  .x-portfolio-filters, .x-portfolio-filter {
    font-size: 11px;
  }
}

@media (max-width: 479.98px) {
  .x-portfolio-filters, .x-portfolio-filter {
    font-size: 10px;
  }
}

.x-portfolio-filters:hover, .x-portfolio-filter:hover {
  color: #777;
  color: #000000bf;
  background-color: #fff;
  border-color: #00000040;
}

.x-portfolio-filters:active, .x-portfolio-filter:active {
  color: #777;
  color: #000000bf;
  background-color: #00000013;
  border-color: #00000040;
  box-shadow: inset 0 1px 2px #00000039, 0 1px 1px #fffffff2;
}

.x-portfolio-filters {
  margin: 0;
}

.x-portfolio-filters-menu {
  text-align: center;
  margin-top: 1.313em;
}

.x-portfolio-filters-menu li {
  display: inline-block;
}

.x-portfolio-filters-menu .selected {
  color: #777;
  color: #000000bf;
  background-color: #00000013;
  border-color: #00000040;
  box-shadow: inset 0 1px 2px #00000039, 0 1px 1px #fffffff2;
}

.x-portfolio-filter {
  display: inline-block;
}

.x-social-global {
  font-weight: 400;
  line-height: 1;
}

.x-social-global a {
  text-align: center;
  display: inline-block;
}

.x-social-global a i {
  display: block;
}

.twitter-tweet {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 0 1.313em !important;
}

.x-scroll-top {
  z-index: 10000;
  width: 35px;
  height: 35px;
  text-align: center;
  color: #272727;
  border: 2px solid #272727;
  border-radius: 100%;
  margin-bottom: -75px;
  font-size: 25px;
  line-height: 27px;
  transition: margin-bottom .6s, opacity .3s;
  position: fixed;
  bottom: 10px;
}

.x-scroll-top.in {
  opacity: .375;
  margin-bottom: 0;
}

.x-scroll-top.in:hover {
  opacity: .825;
}

.x-scroll-top.left {
  left: 10px;
}

.x-scroll-top.right {
  right: 10px;
}

.x-scroll-top:hover {
  color: #272727;
}

.x-container {
  margin: 0 auto;
}

.x-container:not(.x-row):not(.x-grid):before, .x-container:not(.x-row):not(.x-grid):after {
  content: " ";
  width: 0;
  display: table;
}

.x-container:not(.x-row):not(.x-grid):after {
  clear: both;
}

.x-gap {
  border-color: #0000;
  margin: 0 0 1.313em;
}

.x-clear {
  clear: both;
  border: 0;
  margin: 0;
}

.x-map {
  margin-bottom: 1.313em;
}

.x-map .x-map-inner {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.x-map .x-map-inner iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.x-map .gm-style img {
  max-width: none;
}

.x-code {
  line-height: 1;
}

.x-block-grid {
  margin: 0 0 1.313em;
  display: block;
  overflow: hidden;
}

.x-block-grid > li {
  float: left;
  height: auto;
  margin: 5% 5% 0 0;
  padding: 0;
  display: block;
}

.x-block-grid.two-up > li {
  width: 47.5%;
}

.x-block-grid.two-up > li:nth-child(-n+2) {
  margin-top: 0;
}

.x-block-grid.two-up > li:nth-child(2n) {
  margin-right: 0;
}

.x-block-grid.two-up > li:nth-child(2n+1) {
  clear: both;
}

@media (max-width: 479.98px) {
  .x-block-grid.two-up > li {
    width: 100%;
    margin-right: 0;
  }

  .x-block-grid.two-up > li:nth-child(2) {
    margin-top: 5%;
  }
}

.x-block-grid.three-up > li {
  width: 30%;
}

.x-block-grid.three-up > li:nth-child(-n+3) {
  margin-top: 0;
}

.x-block-grid.three-up > li:nth-child(3n) {
  margin-right: 0;
}

.x-block-grid.three-up > li:nth-child(3n+1) {
  clear: both;
}

@media (max-width: 978.98px) {
  .x-block-grid.three-up > li {
    width: 47.5%;
  }

  .x-block-grid.three-up > li:nth-child(3) {
    margin-top: 5%;
  }

  .x-block-grid.three-up > li:nth-child(3n) {
    margin-right: 5%;
  }

  .x-block-grid.three-up > li:nth-child(3n+1) {
    clear: none;
  }

  .x-block-grid.three-up > li:nth-child(2n) {
    margin-right: 0;
  }

  .x-block-grid.three-up > li:nth-child(2n+1) {
    clear: both;
  }
}

@media (max-width: 479.98px) {
  .x-block-grid.three-up > li {
    width: 100%;
    margin-right: 0;
  }

  .x-block-grid.three-up > li:nth-child(2) {
    margin-top: 5%;
  }

  .x-block-grid.three-up > li:nth-child(3n) {
    margin-right: 0;
  }
}

.x-block-grid.four-up > li {
  width: 21.25%;
}

.x-block-grid.four-up > li:nth-child(-n+4) {
  margin-top: 0;
}

.x-block-grid.four-up > li:nth-child(4n) {
  margin-right: 0;
}

.x-block-grid.four-up > li:nth-child(4n+1) {
  clear: both;
}

@media (max-width: 978.98px) {
  .x-block-grid.four-up > li {
    width: 47.5%;
  }

  .x-block-grid.four-up > li:nth-child(3), .x-block-grid.four-up > li:nth-child(4) {
    margin-top: 5%;
  }

  .x-block-grid.four-up > li:nth-child(4n) {
    margin-right: 5%;
  }

  .x-block-grid.four-up > li:nth-child(4n+1) {
    clear: none;
  }

  .x-block-grid.four-up > li:nth-child(2n) {
    margin-right: 0;
  }

  .x-block-grid.four-up > li:nth-child(2n+1) {
    clear: both;
  }
}

@media (max-width: 479.98px) {
  .x-block-grid.four-up > li {
    width: 100%;
    margin-right: 0;
  }

  .x-block-grid.four-up > li:nth-child(2) {
    margin-top: 5%;
  }

  .x-block-grid.four-up > li:nth-child(4n) {
    margin-right: 0;
  }
}

.x-ul-icons {
  margin-left: 1.5em;
  list-style: none;
}

.x-ul-icons li [class*="x-icon-"] {
  text-indent: -1.5em;
}

.x-video {
  margin-bottom: 1.313em;
}

.x-video .x-video-inner {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.x-video .x-video-inner.five-by-three {
  padding-bottom: 60%;
}

.x-video .x-video-inner.five-by-four {
  padding-bottom: 80%;
}

.x-video .x-video-inner.four-by-three {
  padding-bottom: 75%;
}

.x-video .x-video-inner.three-by-two {
  padding-bottom: 66.67%;
}

.x-video .x-video-inner embed, .x-video .x-video-inner iframe, .x-video .x-video-inner img, .x-video .x-video-inner object, .x-video .x-video-inner video {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.x-video.bg, .x-video.x-video-bg {
  margin: 0;
}

.x-video.bg, .x-video.bg .x-video-inner, .x-video.x-video-bg, .x-video.x-video-bg .x-video-inner {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  transition: opacity 1s;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.x-video.bg.poster, .x-video.x-video-bg.poster {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.x-flexslider-shortcode-container {
  margin-bottom: 1.313em;
}

.x-flexslider-shortcode-container .x-flexslider-shortcode {
  margin: 0;
}

.x-audio {
  margin-bottom: 1.313em;
}

.x-audio.player {
  height: 30px;
}

.x-search-shortcode {
  margin-bottom: 1.313em;
}

.x-counter {
  text-align: center;
}

.x-counter .number-wrap {
  font-size: 3.25em;
  line-height: 1;
}

.x-counter .text-above, .x-counter .text-below {
  letter-spacing: .125em;
  text-transform: uppercase;
  line-height: 1.5;
  display: block;
}

.x-counter .text-above {
  margin-bottom: .5em;
}

.x-counter .text-below {
  margin-top: .5em;
}

.x-counter .x-counter-number-wrap:first-child {
  margin-top: 0 !important;
}

.x-counter .x-counter-number-wrap:last-child {
  margin-bottom: 0 !important;
}

.x-typed-cursor {
  animation: 1s infinite x-blink;
}

@keyframes x-blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.x-card-outer {
  text-align: center;
  cursor: pointer;
  margin: 0 auto 1.313em;
}

.x-card-outer .x-card-inner {
  position: relative;
}

.x-card-outer .x-face-outer {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.x-card-outer .x-face-outer.front {
  z-index: 2;
}

.x-card-outer .x-face-outer.back {
  z-index: 1;
}

.x-card-outer .x-face-inner {
  height: 100%;
}

.x-card-outer .x-face-graphic {
  margin: 0 0 10px;
  line-height: 1;
}

.x-card-outer .x-face-title, .x-card-outer .x-face-text {
  color: inherit;
}

.x-card-outer .x-face-title {
  margin: 0;
  padding: 0;
  font-size: 1.75em;
}

.x-card-outer .x-face-text {
  margin: 8px 0 0;
}

.x-card-outer .x-face-button {
  box-shadow: none;
  border: 0;
  border-radius: .35em;
  margin: 15px 0 0;
  padding: .825em 1em;
  line-height: 1;
  text-decoration: none;
  display: inline-block;
}

.x-card-outer.flipped .x-face-outer.front {
  z-index: 1;
}

.x-card-outer.flipped .x-face-outer.back {
  z-index: 2;
}

.x-card-outer.center-vertically .x-face-inner:before {
  content: "";
  width: 0;
  height: 100%;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.x-card-outer.center-vertically .x-face-content {
  vertical-align: middle;
  display: inline-block;
}

.x-card-outer.flip-3d {
  perspective: 1200px;
  -ms-perspective: 1200px;
}

.x-card-outer.flip-3d .x-card-inner {
  -webkit-transition: -webkit-transform 1.25s cubic-bezier(.23, 1, .32, 1);
  transform-style: preserve-3d;
  transition: transform 1.25s cubic-bezier(.23, 1, .32, 1);
}

.x-card-outer.flip-3d .x-face-outer {
  backface-visibility: hidden;
}

.x-card-outer.flip-3d .x-face-outer.front, .x-card-outer.flip-3d .x-face-outer.back, .x-card-outer.flip-3d.flipped .x-face-outer.front, .x-card-outer.flip-3d.flipped .x-face-outer.back {
  z-index: 1;
}

.x-card-outer.flip-3d.flip-from-left .back, .x-card-outer.flip-3d.flip-from-right .back, .x-card-outer.flip-3d.flip-from-left.flipped .x-card-inner {
  transform: rotateY(180deg);
}

.x-card-outer.flip-3d.flip-from-right.flipped .x-card-inner {
  transform: rotateY(-180deg);
}

.x-card-outer.flip-3d.flip-from-top .back, .x-card-outer.flip-3d.flip-from-bottom .back {
  transform: rotateX(180deg);
}

.x-card-outer.flip-3d.flip-from-top.flipped .x-card-inner {
  transform: rotateX(-180deg);
}

.x-card-outer.flip-3d.flip-from-bottom.flipped .x-card-inner {
  transform: rotateX(180deg);
}

.x-creative-cta {
  text-align: center;
  box-shadow: none;
  border: 0;
  text-decoration: none;
  transition: background-color .3s;
  display: block;
  position: relative;
  overflow: hidden;
}

.x-creative-cta > span {
  transition: all .65s cubic-bezier(.23, 1, .32, 1);
  display: block;
}

.x-creative-cta > span.text {
  opacity: 1;
  line-height: 1.2;
}

.x-creative-cta > span.graphic {
  opacity: 0;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
}

.x-creative-cta > span.graphic > i, .x-creative-cta > span.graphic > img {
  transition: all .65s cubic-bezier(.23, 1, .32, 1);
}

.x-creative-cta:hover > span.text {
  opacity: 0;
}

.x-creative-cta:hover > span.graphic {
  opacity: 1;
}

.x-creative-cta.slide-top:hover > span.text {
  transform: translate(0, -150%);
}

.x-creative-cta.slide-left:hover > span.text {
  transform: translate(-80%);
}

.x-creative-cta.slide-right:hover > span.text {
  transform: translate(80%);
}

.x-creative-cta.slide-bottom:hover > span.text {
  transform: translate(0, 150%);
}

.x-feature-box {
  margin: 0 auto 1.313em;
  position: relative;
}

.x-feature-box.left-text, .x-feature-box.right-text {
  display: flex;
}

.x-feature-box.left-text .x-feature-box-content, .x-feature-box.right-text .x-feature-box-content {
  flex: 1 0;
}

.x-feature-box-graphic {
  margin: 0 0 10px;
  display: inline-block;
}

.x-feature-box.left-text .x-feature-box-graphic, .x-feature-box.right-text .x-feature-box-graphic {
  margin-bottom: 0;
}

.x-feature-box-graphic i.rounded, .x-feature-box-graphic img.rounded {
  border-radius: .15em;
}

.x-feature-box-graphic i.circle, .x-feature-box-graphic img.circle {
  border-radius: 100em;
}

.x-feature-box-graphic i {
  width: 1em;
  height: 1em;
  text-align: center;
  display: block;
  position: relative;
}

.x-feature-box-graphic i:before {
  height: 1em;
  margin-top: -.5em;
  font-size: .395em;
  line-height: 1;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.x-feature-box-graphic img {
  display: block;
}

.x-feature-box-graphic-outer, .x-feature-box-graphic-inner {
  z-index: 2;
  position: relative;
}

.x-feature-box-graphic-inner {
  width: 1em;
}

.x-feature-box-graphic-inner.hexagon:before, .x-feature-box-graphic-inner.hexagon:after {
  content: "";
  border: 0 solid #0000;
  border-width: 0 .5em;
  display: block;
}

.x-feature-box-graphic-inner.hexagon:before {
  border-bottom-width: .285em;
  border-bottom-color: inherit;
}

.x-feature-box-graphic-inner.hexagon:after {
  border-top-width: .285em;
  border-top-color: inherit;
}

.x-feature-box-graphic-inner.hexagon i {
  height: .6em;
}

.x-feature-box-graphic-inner.badge {
  transform: scale(.725)rotate(15deg);
}

.x-feature-box-graphic-inner.badge:before, .x-feature-box-graphic-inner.badge:after {
  content: "";
  border: .5em solid;
  border-color: inherit;
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.x-feature-box-graphic-inner.badge:before {
  transform: rotate(30deg);
}

.x-feature-box-graphic-inner.badge:after {
  transform: rotate(60deg);
}

.x-feature-box-graphic-inner.badge i {
  z-index: 2;
  position: relative;
}

.x-feature-box-graphic-inner.badge i:before {
  transform: scale(1.275)rotate(-15deg);
}

.x-feature-box-connector {
  width: 0;
  z-index: 1;
  margin: 0 auto;
  display: block;
  position: absolute;
}

.x-feature-box-connector.full {
  height: 100%;
  top: .5em;
}

.x-feature-box-connector.upper {
  height: 50%;
  top: 0;
}

.x-feature-box-connector.lower {
  height: 50%;
  top: 50%;
}

.x-feature-box-title {
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2;
}

.x-feature-box-text {
  margin: 8px 0 0;
}

.x-feature-box-text a {
  box-shadow: none;
  border: 0;
}

.x-feature-box-align-v-middle {
  flex-flow: column;
  justify-content: center;
  align-self: stretch;
  display: flex;
}

.x-feature-list {
  counter-reset: xFeatureListNum;
  margin: 0 0 1.313em;
  padding: 1px 0;
  list-style: none;
}

.x-feature-list .x-feature-box {
  counter-increment: xFeatureListNum;
  margin-bottom: 0;
}

.x-feature-list .x-feature-box.top-text > div {
  padding: 0 0 1.313em;
}

.x-feature-list .x-feature-box.top-text:last-child > div {
  padding-bottom: 0;
}

.x-feature-list .x-feature-box.top-text:last-child .full {
  display: none;
}

.x-feature-list .x-feature-box.middle-text > div {
  padding: .6565em 0;
}

.x-feature-list .x-feature-box.middle-text:first-child {
  margin-top: -.6565em;
}

.x-feature-list .x-feature-box.middle-text:last-child {
  margin-bottom: -.6565em;
}

.x-feature-list .x-feature-box.middle-text:first-child .upper, .x-feature-list .x-feature-box.middle-text:last-child .lower {
  display: none;
}

.x-feature-list .x-feature-box-graphic i.number:before {
  content: counter(xFeatureListNum);
  font-style: normal;
}

.x-dropcap {
  float: left;
  text-shadow: 0 1px 3px #00000073;
  color: #fff;
  background-color: #ff2a13;
  border-radius: 4px;
  margin: .175em .215em 0 0;
  padding: .105em .2em .135em;
  font-size: 3.3em;
  font-weight: bold;
  line-height: 1;
  display: block;
}

.x-highlight {
  text-shadow: 0 1px 1px #000000a6;
  color: #fff;
  background-color: #ff2a13;
  border-radius: 4px;
  padding: .188em .375em;
}

.x-highlight.dark {
  text-shadow: 0 -1px 1px #000;
  color: #fff;
  background-color: #484848;
}

.x-pullquote {
  width: 40%;
  margin: .45em 1.1em .55em 0;
  font-size: 1.313em;
}

.x-pullquote.right {
  margin-left: 1.1em;
  margin-right: 0;
}

@media (max-width: 766.98px) {
  .x-pullquote.left, .x-pullquote.right {
    float: none;
    width: 100%;
    margin: 1.313em 0;
  }
}

.x-cite {
  color: #7a7a7a;
  margin-top: .75em;
  font-size: .725em;
  font-weight: 300;
  display: block;
}

.x-cite:before {
  content: "â€“Â ";
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #ffffffe6;
  opacity: .4;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.7;
  transition: opacity .3s;
}

.close:hover {
  color: #000;
  cursor: pointer;
  opacity: .6;
  text-decoration: none;
}

button.close {
  cursor: pointer;
  -webkit-appearance: none;
  background-color: #0000;
  border: 0;
  padding: 0;
}

.x-alert, .wpcf7-response-output, .buddypress #message, .bbp-template-notice {
  text-shadow: 0 1px #ffffffe6;
  color: #c09853;
  background-color: #fcf8e3;
  border: 1px solid #f6dca7;
  border-radius: 4px;
  margin-bottom: 1.313em;
  padding: .786em 2.25em 1em 1.15em;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  box-shadow: inset 0 1px #fffc, 0 2px 3px #0001;
}

.x-alert .h-alert, .wpcf7-response-output .h-alert, .buddypress #message .h-alert, .bbp-template-notice .h-alert {
  letter-spacing: -1px;
  text-transform: none;
  color: inherit;
  margin: .05em 0 .25em;
  font-size: 18px;
  line-height: 1.3;
}

.x-alert .close, .wpcf7-response-output .close, .buddypress #message .close, .bbp-template-notice .close {
  color: #c09853;
  line-height: 1;
  position: relative;
  top: -10px;
  right: -26px;
}

.x-alert > p, .x-alert .x-alert-content > p, .wpcf7-response-output > p, .wpcf7-response-output .x-alert-content > p, .buddypress #message > p, .buddypress #message .x-alert-content > p, .bbp-template-notice > p, .bbp-template-notice .x-alert-content > p {
  margin-bottom: 0;
}

.x-alert > p + p, .x-alert .x-alert-content > p + p, .wpcf7-response-output > p + p, .wpcf7-response-output .x-alert-content > p + p, .buddypress #message > p + p, .buddypress #message .x-alert-content > p + p, .bbp-template-notice > p + p, .bbp-template-notice .x-alert-content > p + p {
  margin-top: 6px;
}

.x-alert-muted {
  color: #999;
  background-color: #eee;
  border-color: #cfcfcf;
}

.x-alert-muted .close {
  color: #999;
}

.x-alert-info, .buddypress #message.info, .bbp-template-notice.info {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #b3d1ef;
}

.x-alert-info .close, .buddypress #message.info .close, .bbp-template-notice.info .close {
  color: #3a87ad;
}

.x-alert-success, .wpcf7-mail-sent-ok, .buddypress #message.updated, .bbp-template-notice.updated {
  color: #468847;
  background-color: #dff0d8;
  border-color: #c1dea8;
}

.x-alert-success .close, .wpcf7-mail-sent-ok .close, .buddypress #message.updated .close, .bbp-template-notice.updated .close {
  color: #468847;
}

.x-alert-danger, .buddypress #message.error, .bbp-template-notice.error {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #e5bdc4;
}

.x-alert-danger .close, .buddypress #message.error .close, .bbp-template-notice.error .close {
  color: #b94a48;
}

.x-alert-block {
  padding: .786em 1.15em 1em;
}

.wpcf7-response-output {
  border-width: 1px !important;
  margin: 1.313em 0 0 !important;
  padding: .786em 1.15em 1em !important;
}

.h-skill-bar {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 0;
  font-size: 11px;
  font-weight: 700;
  line-height: 1;
}

.x-skill-bar {
  height: 25px;
  background-color: #f2f2f2;
  border-radius: 4px;
  margin-bottom: 1.313em;
  box-shadow: inset 0 1px 2px #00000026;
}

.x-skill-bar .bar {
  width: 0%;
  height: 100%;
  float: left;
  background-color: #ff2a13;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 1px 5px #00000073;
}

.x-skill-bar .bar .percent {
  height: 17px;
  text-shadow: 0 1px #000000bf;
  color: #fff;
  background-color: #00000059;
  border-radius: 3px;
  padding: 0 5px;
  font-size: 11px;
  line-height: 17px;
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.x-img {
  margin-bottom: 1.313em;
}

.x-img.left {
  margin: .35em 1.313em .35em 0;
}

.x-img.right {
  margin: .35em 0 .35em 1.313em;
}

a.x-img {
  display: block;
}

a.x-img > img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

a.x-img:not(.x-img-thumbnail) {
  transition: opacity .3s;
  transform: translate3d(0, 0, 0);
}

a.x-img:not(.x-img-thumbnail):hover {
  opacity: .75;
}

.x-img-rounded, .x-img-rounded img {
  border-radius: 6px;
}

.x-img-circle, .x-img-circle img {
  border-radius: 100em;
}

.x-img-thumbnail {
  background-color: #fff;
  border: 1px solid #00000026;
  border-radius: 2px;
  padding: 5px;
  line-height: 1.7;
  transition: border .3s, box-shadow .3s;
  box-shadow: 0 1px 3px #0000001a;
}

a.x-img-thumbnail:hover {
  border-color: #ff2a13;
  box-shadow: 0 1px 4px #00000040;
}

.x-columnize {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 3em;
  -moz-column-gap: 3em;
  -webkit-column-rule: 1px solid #e5e5e5;
  -moz-column-rule: 1px solid #e5e5e5;
  column-rule: 1px solid #e5e5e5;
  column-gap: 3em;
  margin: 0 0 1.313em;
}

.x-columnize p:last-child, .x-columnize ul:last-child, .x-columnize ol:last-child {
  margin-bottom: 0;
}

@media (max-width: 479.98px) {
  .x-columnize {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    -webkit-column-rule: 0;
    -moz-column-rule: 0;
    column-rule: 0;
    column-gap: 0;
  }
}

.x-accordion {
  margin-bottom: 1.313em;
}

.x-accordion-group {
  border: 1px solid #00000026;
  margin: 0 0 8px;
  box-shadow: 0 .125em .275em #00000020;
}

.x-accordion-group:last-child {
  margin-bottom: 0;
}

.x-accordion-heading {
  border-bottom: 0;
  overflow: hidden;
}

.x-accordion-heading .x-accordion-toggle {
  color: #272727;
  cursor: pointer;
  background-color: #fff;
  padding: 10px 15px;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 114.2%;
  font-weight: 400;
  display: block;
}

.x-accordion-heading .x-accordion-toggle.collapsed {
  background-color: #fff;
}

.x-accordion-heading .x-accordion-toggle.collapsed:before {
  color: #ccc;
  transform: rotate(0);
}

.x-accordion-heading .x-accordion-toggle:hover {
  color: #ff2a13;
}

.x-accordion-heading .x-accordion-toggle:hover:before {
  color: #ccc;
}

.x-accordion-heading .x-accordion-toggle:before {
  content: "ï§";
  color: #ccc;
  text-decoration: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 74%;
  transition: all .3s;
  display: inline-block;
  position: relative;
  bottom: .1em;
  transform: rotate(45deg);
  font-family: FontAwesome !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.x-accordion-inner {
  box-shadow: none;
  background-color: #fff;
  border-top: 1px solid #00000026;
  padding: 10px 15px;
}

.x-accordion-inner p:last-child, .x-accordion-inner ul:last-child, .x-accordion-inner ol:last-child {
  margin-bottom: 0;
}

.x-column {
  z-index: 1;
  float: left;
  margin-right: 4%;
  position: relative;
}

.x-column.x-1-1 {
  width: 100%;
}

.x-column.x-1-2 {
  width: 48%;
}

.x-column.x-1-3 {
  width: 30.6667%;
}

.x-column.x-2-3 {
  width: 65.3333%;
}

.x-column.x-1-4 {
  width: 22%;
}

.x-column.x-3-4 {
  width: 74%;
}

.x-column.x-1-5 {
  width: 16.8%;
}

.x-column.x-2-5 {
  width: 37.6%;
}

.x-column.x-3-5 {
  width: 58.4%;
}

.x-column.x-4-5 {
  width: 79.2%;
}

.x-column.x-1-6 {
  width: 13.3333%;
}

.x-column.x-5-6 {
  width: 82.6667%;
}

.x-column.last, .x-column:last-of-type {
  margin-right: 0;
}

@media (max-width: 479.98px) {
  .x-column.x-xs {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 766.98px) {
  .x-column.x-sm {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 978.98px) {
  .x-column.x-md {
    float: none;
    width: 100%;
    margin-right: 0;
  }
}

.x-column[data-fade="1"], .x-column[data-fade="true"] {
  transition: opacity cubic-bezier(.23, 1, .32, 1), transform cubic-bezier(.23, 1, .32, 1);
}

.x-protect {
  text-align: center;
  background-color: #f2f2f2;
  border-radius: .65em;
  padding: 7% 18%;
}

.x-protect label {
  margin: .75em 0 0;
  font-size: 16px;
}

.x-protect input[type="text"], .x-protect input[type="password"] {
  width: 100%;
  max-width: 380px;
  text-align: center;
}

.h-protect {
  color: #272727;
  font-size: 24px;
  line-height: 1.2;
}

.x-btn-protect {
  margin-top: .75em;
}

.x-recent-posts {
  margin: 0 0 1.313em;
}

.x-recent-posts + .x-recent-posts {
  margin-top: 4%;
}

.x-recent-posts a {
  float: left;
  background-color: #fff;
  border: 1px solid #00000026;
  border-radius: 3px;
  margin: 0 4% 0 0;
  padding: 5px 5px 0;
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 .125em .275em #00000013;
}

.x-recent-posts a:last-child {
  margin-right: 0;
}

.x-recent-posts a:hover .no-post-thumbnail .x-recent-posts-img:before, .x-recent-posts a:hover .no-post-thumbnail .x-recent-posts-img:after, .x-recent-posts a:hover .has-post-thumbnail .x-recent-posts-img:before {
  opacity: 1;
}

.x-recent-posts a:hover .has-post-thumbnail .x-recent-posts-img:after {
  opacity: .85;
}

.x-recent-posts a.no-image {
  padding: 0 5px;
}

.x-recent-posts a.x-recent-post1 {
  width: 100%;
}

.x-recent-posts a.x-recent-post2 {
  width: 48%;
}

@media (max-width: 766.98px) {
  .x-recent-posts a.x-recent-post2 {
    width: 100%;
    float: none;
    margin-bottom: 4%;
    margin-right: 0;
  }

  .x-recent-posts a.x-recent-post2:last-child {
    margin-bottom: 0;
  }
}

.x-recent-posts a.x-recent-post3 {
  width: 30.6666%;
}

@media (max-width: 766.98px) {
  .x-recent-posts a.x-recent-post3 {
    width: 100%;
    float: none;
    margin-bottom: 4%;
    margin-right: 0;
  }

  .x-recent-posts a.x-recent-post3:last-child {
    margin-bottom: 0;
  }
}

.x-recent-posts a[class^="x-recent-post"]:not([class*="1"]):not([class*="2"]):not([class*="3"]) {
  width: 22%;
  margin-right: 4%;
}

.x-recent-posts a[class^="x-recent-post"]:not([class*="1"]):not([class*="2"]):not([class*="3"]):nth-child(4n) {
  margin-bottom: 4%;
  margin-right: 0;
}

@media (max-width: 978.98px) {
  .x-recent-posts a[class^="x-recent-post"]:not([class*="1"]):not([class*="2"]):not([class*="3"]) {
    width: 48%;
  }

  .x-recent-posts a[class^="x-recent-post"]:not([class*="1"]):not([class*="2"]):not([class*="3"]):first-child {
    margin-bottom: 4%;
  }

  .x-recent-posts a[class^="x-recent-post"]:not([class*="1"]):not([class*="2"]):not([class*="3"]):nth-child(2n) {
    margin-bottom: 4%;
    margin-right: 0;
  }
}

@media (max-width: 766.98px) {
  .x-recent-posts a[class^="x-recent-post"]:not([class*="1"]):not([class*="2"]):not([class*="3"]) {
    width: 100%;
    float: none;
    margin-bottom: 4%;
    margin-right: 0;
  }

  .x-recent-posts a[class^="x-recent-post"]:not([class*="1"]):not([class*="2"]):not([class*="3"]):last-child {
    margin-bottom: 0;
  }
}

.x-recent-posts article.hentry > .entry-wrap {
  box-shadow: none;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.x-recent-posts .x-recent-posts-img {
  background: center / cover no-repeat;
  border-radius: 2px;
  padding-bottom: 56.25%;
  position: relative;
}

.x-recent-posts .x-recent-posts-img:before, .x-recent-posts .x-recent-posts-img:after {
  opacity: 0;
  transition: opacity .3s;
  display: block;
  position: absolute;
}

.x-recent-posts .x-recent-posts-img:before {
  width: 60px;
  height: 60px;
  text-align: center;
  color: #fff;
  z-index: 2;
  text-decoration: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: -30px 0 0 -30px;
  font-size: 32px;
  line-height: 60px;
  top: 50%;
  left: 50%;
  font-family: FontAwesome !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.x-recent-posts .x-recent-posts-img:after {
  content: "";
  z-index: 1;
  background-color: #ff2a13;
  border-radius: 2px;
  inset: 0;
}

.x-recent-posts .no-post-thumbnail .x-recent-posts-img:before {
  opacity: .35;
}

.x-recent-posts .no-post-thumbnail .x-recent-posts-img:after {
  opacity: 1;
}

.x-recent-posts .format-standard .x-recent-posts-img:before {
  content: "ï…œ";
}

.x-recent-posts .format-video .x-recent-posts-img:before {
  content: "ï€ˆ";
}

.x-recent-posts .format-audio .x-recent-posts-img:before {
  content: "ï€";
}

.x-recent-posts .format-image .x-recent-posts-img:before {
  content: "ï‚ƒ";
}

.x-recent-posts .format-gallery .x-recent-posts-img:before {
  content: "ï€¾";
}

.x-recent-posts .format-link .x-recent-posts-img:before {
  content: "ïƒ";
}

.x-recent-posts .format-quote .x-recent-posts-img:before {
  content: "ï„";
}

.x-recent-posts .x-portfolio .x-recent-posts-img:before {
  content: "ï§";
}

.x-recent-posts .x-recent-posts-content {
  padding: 10px 5px;
}

.x-recent-posts .h-recent-posts, .x-recent-posts .x-recent-posts-date {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.x-recent-posts .h-recent-posts {
  margin: -1px 0 5px;
  font-size: 17px;
  line-height: 1.2;
  transition: color .3s;
}

.x-recent-posts .x-recent-posts-date {
  text-transform: uppercase;
  color: #000000a6;
  margin: 0;
  font-size: 10px;
  line-height: 1;
}

.x-recent-posts.vertical a[class^="x-recent-post"] {
  float: none;
  margin-bottom: 4%;
  margin-right: 0;
  width: 100% !important;
}

.x-recent-posts.vertical a[class^="x-recent-post"]:last-child {
  margin-bottom: 0;
}

.js .x-recent-posts[data-fade="true"] a {
  opacity: 0;
}

.x-pricing-table {
  width: 100%;
  margin: 1.313em 0;
}

.x-pricing-table.one-column .x-pricing-column {
  width: 100%;
}

.x-pricing-table.two-columns .x-pricing-column {
  width: 50%;
}

.x-pricing-table.three-columns .x-pricing-column {
  width: 33.3333%;
}

.x-pricing-table.four-columns .x-pricing-column {
  width: 25%;
}

.x-pricing-table.five-columns .x-pricing-column {
  width: 20%;
}

@media (max-width: 978.98px) {
  .x-pricing-table[class*="-column"] .x-pricing-column {
    width: 50%;
  }

  .x-pricing-table.three-columns .x-pricing-column:last-child, .x-pricing-table.five-columns .x-pricing-column:last-child {
    width: 99.9%;
  }
}

@media (max-width: 766.98px) {
  .x-pricing-table[class*="-column"] .x-pricing-column {
    width: 100%;
  }
}

.x-pricing-column {
  float: left;
  text-align: center;
  margin: 0 0 -1px -1px;
}

@media (max-width: 766.98px) {
  .x-pricing-column {
    float: none;
    margin-left: 0;
  }
}

.x-pricing-column h2 {
  letter-spacing: 0;
  color: #7a7a7a;
  background-color: #fbfbfb;
  border: 1px solid #ebebeb;
  padding: 20px 20px 25px;
  font-size: 32px;
  line-height: 1.1;
}

.x-pricing-column h2 span {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000;
  opacity: .5;
  margin-top: 5px;
  font-size: 13px;
  display: block;
}

.x-pricing-column.featured {
  margin-top: -20px;
  position: relative;
  box-shadow: 0 0 10px #00000020;
}

@media (max-width: 978.98px) {
  .x-pricing-column.featured {
    margin-top: 0;
  }
}

.x-pricing-column.featured h2 {
  color: #fff;
  background-color: #ff2a13;
  border: 0;
  padding-bottom: 28px;
  box-shadow: inset 0 0 0 5px #00000026;
}

.x-pricing-column.featured .x-pricing-column-info {
  padding-bottom: 50px;
}

.x-pricing-column.featured .x-pricing-column-info ul {
  margin-bottom: 40px;
}

.x-pricing-column-info {
  border: 1px solid #ebebeb;
  border-top: 0;
  padding: 10px 0 32px;
}

.x-pricing-column-info .x-price {
  letter-spacing: -3px;
  margin: 0;
  font-size: 54px;
}

.x-pricing-column-info span {
  color: #bababa;
  margin-top: -4px;
  font-size: 12px;
  display: block;
}

.x-pricing-column-info p {
  margin: 0;
}

.x-pricing-column-info ul {
  margin: 15px 0 25px;
}

.x-pricing-column-info ul > li {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 40px 11px;
  font-size: 16px;
  line-height: 1.4;
}

.x-pricing-column-info ul > li [class*="x-icon-"] {
  margin-left: .85em;
}

.x-pricing-column-info ul > li:first-child {
  border-top: 1px solid #ebebeb;
}

.x-pricing-column-info ul > li:nth-child(2n+1) {
  background-color: #fbfbfb;
}

.x-pricing-column-info .x-btn {
  margin-left: 20px;
  margin-right: 20px;
}

.x-callout {
  background-color: #f7f7f7;
  border: 1px solid #ebebeb;
  border-radius: .5em;
  margin: 1.313em 0;
  padding: 2.35em;
  font-size: 21px;
  position: relative;
  box-shadow: inset 0 1px #fffffff2, 0 1px 3px #0000000d;
}

@media (max-width: 978.98px) {
  .x-callout {
    font-size: 18px;
  }
}

@media (max-width: 479.98px) {
  .x-callout {
    font-size: 16px;
  }
}

.x-callout:before {
  content: "";
  z-index: 0;
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: .275em;
  display: block;
  position: absolute;
  inset: 14px;
  box-shadow: inset 0 1px 3px #0000000d, 0 1px #fffffff2;
}

.x-callout .x-btn {
  font-size: 1em;
}

.h-callout {
  margin-top: 0;
  margin-bottom: .35em;
  font-size: 2.15em;
  line-height: 1.1;
  position: relative;
}

.p-callout {
  line-height: 1.4;
  position: relative;
}

.x-promo {
  border: 1px solid #00000026;
  border-radius: 4px;
  margin-bottom: 1.313em;
  overflow: hidden;
  box-shadow: 0 .125em .275em #00000020;
}

.x-promo-image-wrap img {
  min-width: 100%;
}

.x-promo-content {
  box-shadow: none;
  background-color: #fff;
  padding: 1.5em;
}

.x-author-box {
  margin: 0 0 1.313em;
}

.x-author-box .h-about-the-author {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d3d3d3;
  border-bottom: 1px solid #f2f2f2;
  margin: 0 0 .925em;
  padding-bottom: .45em;
  font-size: 12px;
  font-weight: 300;
}

.x-author-box .avatar {
  float: left;
  width: 90px;
  border-radius: .35em;
}

@media (max-width: 479.98px) {
  .x-author-box .avatar {
    display: none;
  }
}

.x-author-box .x-author-info {
  margin-left: 110px;
}

@media (max-width: 479.98px) {
  .x-author-box .x-author-info {
    margin-left: 0;
  }
}

.x-author-box .h-author {
  margin-bottom: .35em;
  line-height: 1;
}

.x-author-box .x-author-social {
  white-space: nowrap;
  margin-right: 1em;
  font-size: 13px;
  display: inline-block;
}

.x-author-box .x-author-social [class*="x-social"] {
  position: relative;
  top: 2px;
}

.x-author-box .p-author {
  margin-top: .5em;
}

.x-prompt {
  background-color: #fff;
  border: 1px solid #00000026;
  border-radius: 4px;
  margin: 0 0 1.313em;
  padding: 1.75em;
  box-shadow: 0 .125em .275em #00000013;
}

.x-prompt.message-left .x-prompt-section.x-prompt-section-message {
  text-align: left;
  padding-right: 2.25em;
}

.x-prompt.message-right .x-prompt-section.x-prompt-section-message {
  text-align: right;
  padding-left: 2.25em;
}

.x-prompt-section {
  vertical-align: middle;
  display: table-cell;
  position: relative;
}

.x-prompt-section p:last-child {
  margin-bottom: 0;
}

.x-prompt-section.x-prompt-section-message {
  width: 46%;
  font-size: 16px;
  line-height: 1.4;
}

.x-prompt-section.x-prompt-section-button {
  width: 30%;
}

.h-prompt {
  margin-top: 0;
  font-size: 28px;
  line-height: 1.1;
}

@media (max-width: 766.98px) {
  .x-prompt {
    display: block;
  }

  .x-prompt.message-left .x-prompt-section.x-prompt-section-message {
    padding: 0 0 1.25em;
  }

  .x-prompt.message-right .x-prompt-section.x-prompt-section-message {
    text-align: left;
    padding: 1.25em 0 0;
  }

  .x-prompt-section {
    display: block;
  }

  .x-prompt-section.x-prompt-section-message, .x-prompt-section.x-prompt-section-button {
    width: 100%;
  }
}

.x-content-band {
  margin: 0 0 1.313em;
  padding: 40px 0;
}

.x-content-band:before, .x-content-band:after {
  content: " ";
  width: 0;
  display: table;
}

.x-content-band:after {
  clear: both;
}

.x-content-band.bg-image, .x-content-band.bg-pattern {
  background-position: 50%;
}

.x-content-band.bg-image.parallax, .x-content-band.bg-pattern.parallax {
  background-attachment: fixed;
}

.x-content-band.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.x-content-band.bg-pattern {
  background-repeat: repeat;
}

.x-content-band.bg-video {
  position: relative;
  overflow: hidden;
}

.x-content-band.border-top {
  border-top: 1px solid #00000013;
}

.x-content-band.border-left {
  border-left: 1px solid #00000013;
}

.x-content-band.border-right {
  border-right: 1px solid #00000013;
}

.x-content-band.border-bottom {
  border-bottom: 1px solid #00000013;
}

.x-content-band.marginless-columns .x-container {
  table-layout: fixed;
  width: 100%;
  display: table;
}

.x-content-band.marginless-columns .x-container:before, .x-content-band.marginless-columns .x-container:after {
  display: none;
}

.x-content-band.marginless-columns .x-container > .x-column {
  float: none;
  vertical-align: top;
  margin-right: 0;
  display: table-cell;
}

.x-content-band.marginless-columns .x-container > .x-column.x-1-1 {
  width: 100%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-1-2 {
  width: 50%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-1-3 {
  width: 33.3333%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-2-3 {
  width: 66.6667%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-1-4 {
  width: 25%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-3-4 {
  width: 75%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-1-5 {
  width: 20%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-2-5 {
  width: 40%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-3-5 {
  width: 60%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-4-5 {
  width: 80%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-1-6 {
  width: 16.6667%;
}

.x-content-band.marginless-columns .x-container > .x-column.x-5-6 {
  width: 83.3333%;
}

@media (max-width: 766.98px) {
  .x-content-band.marginless-columns .x-container {
    display: block;
  }

  .x-content-band.marginless-columns .x-container:before, .x-content-band.marginless-columns .x-container:after {
    display: table;
  }

  .x-content-band.marginless-columns .x-container > .x-column[class*="x-"] {
    width: 100%;
    vertical-align: inherit;
    display: block;
  }
}

.x-content-band.vc .wpb_row, .x-content-band.vc .wpb_content_element {
  margin-bottom: 0;
}

.x-content-band .x-container {
  position: relative;
}

.x-entry-share {
  text-align: center;
  border: 1px solid #f2f2f2;
  border-left: 0;
  border-right: 0;
  margin: 0 0 1.313em;
  padding: 10px 0;
  line-height: 1;
}

.x-entry-share p {
  text-transform: uppercase;
  margin: 8px 0 10px;
  font-size: 12px;
  font-weight: 400;
}

.x-entry-share .x-share {
  width: 45px;
  height: 45px;
  border-radius: 2px;
  margin: 0 .05em;
  font-size: 24px;
  line-height: 45px;
  display: inline-block;
}

.x-entry-share .x-share:hover {
  color: #fff;
  background-color: #ff2a13;
}

.x-toc {
  width: 210px;
  background-color: #fff;
  border: 1px solid #00000026;
  border-radius: 3px;
  margin: .55em 0;
  padding: 15px;
  font-size: 11px;
  box-shadow: 0 .125em .275em #00000013;
}

.x-toc.left {
  margin-right: 1.75em;
}

.x-toc.right {
  margin-left: 1.75em;
}

@media (max-width: 479.98px) {
  .x-toc.left, .x-toc.right {
    width: auto;
    float: none;
    margin: 0 0 1.313em;
  }
}

.x-toc.block {
  width: auto;
  margin: 0 0 1.313em;
}

.x-toc ul {
  margin-bottom: -10px !important;
}

.h-toc {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 10px;
  font-size: 14px;
}

.x-toc.block.two-columns .x-toc-item {
  float: left;
  width: 48%;
  margin-right: 4%;
}

.x-toc.block.two-columns .x-toc-item:nth-child(2n) {
  margin-right: 0;
}

.x-toc.block.three-columns .x-toc-item {
  float: left;
  width: 30.6667%;
  margin-right: 4%;
}

.x-toc.block.three-columns .x-toc-item:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 978.98px) {
  .x-toc.block.three-columns .x-toc-item {
    width: 48%;
  }

  .x-toc.block.three-columns .x-toc-item:nth-child(3n) {
    margin-right: 4%;
  }

  .x-toc.block.three-columns .x-toc-item:nth-child(2n) {
    margin-right: 0;
  }
}

@media (max-width: 479.98px) {
  .x-toc.block.two-columns .x-toc-item, .x-toc.block.three-columns .x-toc-item {
    width: 100%;
    margin-right: 0;
  }
}

.x-toc-item {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.3;
}

.x-toc-item a {
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #00000026;
  display: block;
  overflow: hidden;
}

.h-custom-headline {
  letter-spacing: -1px;
  line-height: 1.1;
}

.h-custom-headline.accent {
  overflow: hidden;
}

.h-custom-headline.accent span {
  padding-bottom: 2px;
  display: inline-block;
  position: relative;
}

.h-custom-headline.accent span:before, .h-custom-headline.accent span:after {
  content: "";
  height: 3px;
  width: 9999px;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  margin-top: -2px;
  display: block;
  position: absolute;
  top: 50%;
}

.h-custom-headline.accent span:before {
  margin-right: .5em;
  right: 100%;
}

.h-custom-headline.accent span:after {
  margin-left: .5em;
  left: 100%;
}

.h-feature-headline {
  line-height: 1.1;
}

.h-feature-headline span {
  padding-left: 1.5em;
  display: inline-block;
  position: relative;
}

.h-feature-headline span i {
  width: 2em;
  height: 2em;
  letter-spacing: 0;
  text-align: center;
  color: #fff;
  background-color: #272727;
  border-radius: 100em;
  margin-top: -1em;
  font-size: .65em;
  line-height: 2em;
  position: absolute;
  top: 50%;
  left: 0;
}

.x-section {
  margin: 0 0 1.313em;
  padding: 45px 0;
  display: block;
  position: relative;
}

.x-section:before, .x-section:after {
  content: " ";
  width: 0;
  display: table;
}

.x-section:after {
  clear: both;
}

.x-section.bg-image, .x-section.bg-pattern {
  background-position: 50%;
}

.x-section.bg-image.parallax, .x-section.bg-pattern.parallax {
  background-attachment: fixed;
}

@media (max-width: 978.98px) {
  .x-section.bg-image.parallax, .x-section.bg-pattern.parallax {
    background-size: auto !important;
  }
}

.x-section.bg-image {
  background-repeat: no-repeat;
  background-size: cover;
}

.x-section.bg-pattern {
  background-repeat: repeat;
}

.x-section.bg-video {
  position: relative;
  overflow: hidden;
}

.x-section .x-container:not(.x-row) {
  z-index: 1;
  position: relative;
}

.x-section .x-container.marginless-columns {
  table-layout: fixed;
  display: table;
}

.x-section .x-container.marginless-columns:not(.width) {
  width: 100%;
}

.x-section .x-container.marginless-columns:before, .x-section .x-container.marginless-columns:after {
  display: none;
}

.x-section .x-container.marginless-columns > .x-column {
  float: none;
  vertical-align: top;
  margin-right: 0;
  display: table-cell;
}

.x-section .x-container.marginless-columns > .x-column.x-1-1 {
  width: 100%;
}

.x-section .x-container.marginless-columns > .x-column.x-1-2 {
  width: 50%;
}

.x-section .x-container.marginless-columns > .x-column.x-1-3 {
  width: 33.3333%;
}

.x-section .x-container.marginless-columns > .x-column.x-2-3 {
  width: 66.6667%;
}

.x-section .x-container.marginless-columns > .x-column.x-1-4 {
  width: 25%;
}

.x-section .x-container.marginless-columns > .x-column.x-3-4 {
  width: 75%;
}

.x-section .x-container.marginless-columns > .x-column.x-1-5 {
  width: 20%;
}

.x-section .x-container.marginless-columns > .x-column.x-2-5 {
  width: 40%;
}

.x-section .x-container.marginless-columns > .x-column.x-3-5 {
  width: 60%;
}

.x-section .x-container.marginless-columns > .x-column.x-4-5 {
  width: 80%;
}

.x-section .x-container.marginless-columns > .x-column.x-1-6 {
  width: 16.6667%;
}

.x-section .x-container.marginless-columns > .x-column.x-5-6 {
  width: 83.3333%;
}

@media (max-width: 766.98px) {
  .x-section .x-container.marginless-columns {
    display: block;
  }

  .x-section .x-container.marginless-columns:before, .x-section .x-container.marginless-columns:after {
    display: table;
  }

  .x-section .x-container.marginless-columns > .x-column[class*="x-"] {
    width: 100%;
    vertical-align: inherit;
    display: block;
  }
}

.x-section-separator {
  z-index: 10;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
}

.x-section-separator svg {
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
}

.x-section-separator-top {
  top: 0;
  transform: translate(0, -100%);
}

.x-section-separator-bottom {
  bottom: 0;
  transform: translate(0, 100%);
}

.with-container {
  background-color: #fff;
  border: 1px solid #00000026;
  border-radius: 2px;
  padding: 2.5%;
  box-shadow: 0 1px 3px #0000001a;
}

.mejs-container:focus, .mejs-container:active {
  outline: 0;
}

.mejs-container.mejs-container-fullscreen {
  background-color: #000;
}

.mejs-controls {
  background-color: #000;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.mejs-controls > div {
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  line-height: 1;
  display: flex;
  position: relative;
}

.mejs-video .mejs-controls {
  width: auto;
  margin: 0 20px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.mejs-button button {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: inherit;
  text-align: center;
  vertical-align: top;
  color: #ffffff80;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  transition-property: color, background-color, text-shadow;
  transition-duration: .3s;
  transition-timing-function: ease;
}

.mejs-button button:hover, .mejs-button button:focus {
  color: #fff;
}

.mejs-button button:focus, .mejs-button button:active {
  outline: 0;
}

.mejs-button button:before {
  content: "ï¥";
  text-decoration: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  display: block;
  font-family: FontAwesome !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.mejs-button.mejs-play button:before {
  content: "ï‹";
}

.mejs-button.mejs-replay button:before {
  content: "ï€ž";
}

.mejs-button.mejs-pause button:before {
  content: "ïŒ";
}

.mejs-button.mejs-mute button:before {
  content: "ï€¨";
}

.mejs-button.mejs-unmute button:before {
  content: "ï€¦";
}

.mejs-button.mejs-fullscreen button:before {
  content: "ï¥";
}

.mejs-button.mejs-unfullscreen button:before {
  content: "ï¦";
}

.mejs-time {
  letter-spacing: .025em;
  text-align: center;
  color: #ffffff80;
  font-size: 10px;
  width: 46px !important;
}

@media (max-width: 766.98px) {
  .mejs-time {
    display: none !important;
  }
}

.mejs-time-rail {
  flex: 1;
  padding: 0 10px;
}

.mejs-time-total {
  z-index: 1;
  height: 10px;
  cursor: pointer;
  background-color: #ffffff40;
  margin: 0;
  display: block;
  position: relative;
  overflow: hidden;
  width: 100% !important;
}

.mejs-time-total:focus, .mejs-time-total:active {
  outline: 0;
}

.mejs-time-loaded, .mejs-time-current {
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.mejs-time-loaded {
  background-color: #ffffff40;
}

.mejs-time-current {
  background-color: #ffffff80;
  transform: scaleX(0);
}

.advanced-controls .mejs-time-rail {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 766.98px) {
  .advanced-controls .mejs-time-rail {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.mejs-video .mejs-poster {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.mejs-video .mejs-poster img {
  object-fit: contain;
}

.mejs-video .mejs-overlay-play {
  cursor: pointer;
}

.mejs-fullscreen {
  overflow: hidden !important;
}

.mejs-container-fullscreen {
  z-index: 1000;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.mejs-container-fullscreen video, .mejs-container-fullscreen .mejs-mediaelement {
  width: 100% !important;
  height: 100% !important;
}

.mejs-fullscreen-button > button {
  background-position: -80px 0;
}

.mejs-unfullscreen > button {
  background-position: -100px 0;
}

.bg .mejs-mediaelement {
  overflow: hidden;
}

.me-plugin, .me-cannotplay, .bg .mejs-mediaelement, .mejs-video .mejs-layer, .hide-controls.autoplay .mejs-video .mejs-layers {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mejs-clear, .mejs-offscreen, .mejs-time-float, .mejs-time-handle, .mejs-time-hovered, .mejs-volume-slider, .mejs-time-buffering, .mejs-audio .mejs-layers, .mejs-poster .mejs-poster-img, .mejs-video .mejs-overlay-error, .vimeo .mejs-video .mejs-controls, .vimeo .mejs-mediaelement video, .mejs-video .mejs-overlay-loading, .hide-controls .mejs-video .mejs-controls, .mejs-video .mejs-overlay-play .mejs-overlay-button {
  display: none !important;
}

.x-mejs-no-source {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.has-stack-styles .mejs-controls {
  background-color: #414141;
  background-image: linear-gradient(#5a5a5a, #272727);
  background-repeat: repeat-x;
  border: 1px solid #000;
  border-radius: 3px;
  box-shadow: inset 0 1px #ffffff26, 0 0 3px 1px #00000059;
}

.has-stack-styles .mejs-button.mejs-playpause-button {
  border-right: 1px solid #272727;
  box-shadow: 1px 0 #ffffff1a;
}

.has-stack-styles .mejs-button.mejs-volume-button, .has-stack-styles .mejs-button.mejs-fullscreen-button {
  border-left: 1px solid #272727;
  box-shadow: inset 1px 0 #ffffff1a;
}

.has-stack-styles .mejs-button button {
  color: #000;
  text-shadow: 0 1px 1px #ffffff26;
}

.has-stack-styles .mejs-button button:hover, .has-stack-styles .mejs-button button:focus {
  color: #bababa;
  text-shadow: 0 1px 1px #00000080;
}

.has-stack-styles .mejs-time {
  color: #7a7a7a;
  text-shadow: 0 1px #000;
}

.has-stack-styles .mejs-time-total {
  height: 10px;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 0 1px #ffffff1a;
}

.has-stack-styles .mejs-time-loaded {
  height: 8px;
  background-color: #545454;
  box-shadow: inset 0 1px #ffffff26;
}

.has-stack-styles .mejs-time-current {
  height: 8px;
  background-color: #ff2a13;
  box-shadow: inset 0 1px #ffffff40;
}

@media (max-width: 978.98px) {
  .x-sidebar {
    margin-top: 4em;
  }
}

.x-sidebar .widget {
  margin-top: 2.75em;
}

.x-sidebar .widget:first-child {
  margin-top: 0;
}

.x-main {
  position: relative;
}

.x-main .widget {
  margin-top: 3em;
}

.x-main .widget:first-child {
  margin-top: 0;
}

.hentry {
  margin-top: 4em;
}

.hentry:first-child {
  margin-top: 0;
}

.entry-featured {
  position: relative;
  transform: translate3d(0, 0, 0);
  box-shadow: 4px 2px 4px -4px #0002, -4px 2px 4px -4px #0002;
}

.has-post-thumbnail .entry-featured {
  border-bottom: 1px solid #ddd;
}

.x-boxed-layout-active .has-post-thumbnail .entry-featured {
  border: 1px solid #ddd;
}

.entry-wrap {
  background-color: #fff;
  border-radius: 4px;
  padding: 60px;
  display: block;
  box-shadow: 0 .15em .35em #0002;
}

@media (max-width: 766.98px) {
  .entry-wrap {
    padding: 36px;
  }
}

@media (max-width: 479.98px) {
  .entry-wrap {
    padding: 25px;
  }
}

.x-boxed-layout-active .entry-wrap {
  border: 1px solid #ddd;
  margin-top: -1px;
}

.x-boxed-layout-active .entry-wrap:first-child {
  margin-top: 0;
}

.has-post-thumbnail .entry-wrap {
  border-radius: 0 0 4px 4px;
}

.entry-thumb {
  background-color: #000;
  display: block;
  position: relative;
}

.entry-thumb img {
  min-width: 100%;
  transition: opacity .75s;
}

.entry-thumb:before {
  content: "ïƒ";
  width: 70px;
  height: 70px;
  text-align: center;
  text-shadow: 0 .035em #00000059;
  vertical-align: middle;
  color: #fff;
  opacity: 0;
  text-decoration: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 100em;
  margin: -36px 0 0 -35px;
  font-size: 42px;
  line-height: 72px;
  transition: opacity .75s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: FontAwesome !important;
  font-style: normal !important;
  font-weight: normal !important;
}

a.entry-thumb:hover img {
  opacity: .15;
}

a.entry-thumb:hover:before {
  opacity: 1;
}

.entry-title {
  margin: 0;
  font-size: 250%;
  line-height: 1.05;
}

@media (max-width: 479.98px) {
  .entry-title {
    font-size: 200%;
  }
}

.entry-title-sub {
  margin: .75em 0 0;
  font-size: 128.5%;
  line-height: 1.05;
  display: block;
}

.p-meta {
  text-transform: uppercase;
  margin: 8px 0 0;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 85.7%;
  line-height: 1.5;
}

.p-meta > span {
  display: inline-block;
}

.p-meta > span > a {
  white-space: nowrap;
}

.p-meta > span:after {
  content: "/";
  padding: 0 .5em 0 .45em;
}

.p-meta > span:last-child:after {
  display: none;
}

.entry-content {
  margin-top: 1.25em;
}

.entry-content:before, .entry-content:after {
  content: " ";
  width: 0;
  display: table;
}

.entry-content:after {
  clear: both;
}

.entry-content embed, .entry-content iframe, .entry-content object {
  width: 100%;
  max-width: 100%;
}

.entry-content > p:last-child, .entry-content > ul:last-child, .entry-content > ol:last-child {
  margin-bottom: 0;
}

.more-link {
  margin: 0;
  font-size: 128.5%;
  font-weight: 700;
  line-height: 1.4;
  display: inline-block;
}

.entry-footer {
  margin-top: .325em;
}

.entry-footer a {
  float: left;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  color: #c5c5c5;
  color: #00000060;
  background-color: #0000;
  border: 1px solid #00000020;
  border-radius: .35em;
  margin: .615em .615em 0 0;
  padding: .692em 1.154em .769em;
  font-size: 13px;
  line-height: 1.3;
  transition: color .3s, border-color .3s, background-color .3s, box-shadow .3s;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 0 #0000, 0 1px 1px #fffffff2;
}

@media (max-width: 766.98px) {
  .entry-footer a {
    font-size: 11px;
  }
}

@media (max-width: 479.98px) {
  .entry-footer a {
    font-size: 10px;
  }
}

.entry-footer a:hover {
  color: #777;
  color: #000000bf;
  background-color: #fff;
  border-color: #00000040;
}

.entry-footer a:active {
  color: #777;
  color: #000000bf;
  background-color: #00000013;
  border-color: #00000040;
  box-shadow: inset 0 1px 2px #00000039, 0 1px 1px #fffffff2;
}

.x-page-title-disabled .entry-content {
  margin-top: 0;
}

.x-iso-container-posts.cols-2 .entry-title {
  font-size: 200%;
}

.x-iso-container-posts.cols-2 .p-meta {
  font-size: 79%;
}

@media (max-width: 766.98px) {
  .x-iso-container-posts.cols-2 .p-meta {
    font-size: 85.7%;
  }
}

@media (max-width: 479.98px) {
  .x-iso-container-posts.cols-2 .p-meta {
    font-size: 85.7%;
  }
}

.x-iso-container-posts.cols-2 .entry-wrap {
  padding: 6.75% 7.25%;
}

.x-iso-container-posts.cols-2 .more-link {
  font-size: 110%;
}

@media (max-width: 766.98px) {
  .x-iso-container-posts.cols-2 .more-link {
    font-size: 128.5%;
  }
}

.x-iso-container-posts.cols-3 .entry-title {
  font-size: 165%;
}

@media (max-width: 978.98px) {
  .x-iso-container-posts.cols-3 .entry-title {
    font-size: 200%;
  }
}

.x-iso-container-posts.cols-3 .p-meta {
  font-size: 70%;
}

@media (max-width: 978.98px) {
  .x-iso-container-posts.cols-3 .p-meta {
    font-size: 85.7%;
  }
}

.x-iso-container-posts.cols-3 .entry-wrap {
  padding: 7.75% 8.25%;
}

@media (max-width: 978.98px) {
  .x-iso-container-posts.cols-3 .entry-wrap {
    padding: 6.75% 7.25%;
  }
}

@media (max-width: 479.98px) {
  .x-iso-container-posts.cols-3 .entry-wrap {
    padding: 7.75% 8.25%;
  }
}

.x-iso-container-posts.cols-3 .more-link {
  font-size: 105%;
}

@media (max-width: 978.98px) {
  .x-iso-container-posts.cols-3 .more-link {
    font-size: 128.5%;
  }
}

.format-audio.no-post-thumbnail .entry-featured {
  box-shadow: none;
  margin-bottom: 1.5em;
}

.format-audio.has-post-thumbnail .entry-featured .x-audio {
  margin: 0 auto;
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.format-audio.has-post-thumbnail .entry-featured .x-audio iframe {
  position: absolute;
  bottom: 0;
}

.blog .format-image .entry-featured, .search .format-image .entry-featured, .archive .format-image .entry-featured {
  box-shadow: none;
}

.blog.x-blog-standard-active .format-image h2.entry-title, .search.x-blog-standard-active .format-image h2.entry-title, .archive.x-blog-standard-active .format-image h2.entry-title {
  margin-top: -9px;
}

.blog.x-blog-masonry-active .format-image h2.entry-title, .search.x-blog-masonry-active .format-image h2.entry-title, .archive.x-blog-masonry-active .format-image h2.entry-title {
  margin-top: -6px;
}

@media (max-width: 766.98px) {
  .blog.x-blog-standard-active .format-image h2.entry-title, .search.x-blog-standard-active .format-image h2.entry-title, .archive.x-blog-standard-active .format-image h2.entry-title {
    margin-top: -6px;
  }
}

@media (max-width: 479.98px) {
  .blog.x-blog-standard-active .format-image h2.entry-title, .search.x-blog-standard-active .format-image h2.entry-title, .archive.x-blog-standard-active .format-image h2.entry-title {
    margin-top: -5px;
  }
}

.format-link .x-hgroup {
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 1.5em;
  padding: 0 0 1.5em;
}

.format-link .entry-title-sub a {
  word-break: break-all;
  border-bottom: 1px dotted;
}

.format-link .p-meta {
  text-align: center;
}

.x-iso-container-posts.cols-2 .format-link .entry-title-sub, .x-iso-container-posts.cols-3 .format-link .entry-title-sub {
  font-size: 110%;
}

.blog.x-post-meta-disabled .format-link .x-hgroup, .search.x-post-meta-disabled .format-link .x-hgroup, .archive.x-post-meta-disabled .format-link .x-hgroup {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.format-quote .x-hgroup {
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 1.5em;
  padding: 0 0 1.5em;
}

.format-quote .entry-title:before, .format-quote .entry-title:after {
  font-weight: 300;
}

.format-quote .entry-title:before {
  content: "â€œ";
  margin-right: .115em;
}

.format-quote .entry-title:after {
  content: "â€";
  margin-left: .115em;
}

.format-quote .entry-title-sub:before {
  content: "â€“Â ";
}

.format-quote .p-meta {
  text-align: center;
}

.x-iso-container-posts.cols-2 .format-quote .entry-title-sub, .x-iso-container-posts.cols-3 .format-quote .entry-title-sub {
  font-size: 110%;
}

.blog.x-post-meta-disabled .format-quote .x-hgroup, .search.x-post-meta-disabled .format-quote .x-hgroup, .archive.x-post-meta-disabled .format-quote .x-hgroup {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.x-iso-container-portfolio .entry-wrap {
  padding: 29px 6.5% 35px;
}

.x-iso-container-portfolio .x-entry-share {
  border-color: #f0f0f0;
  padding: 5px 0;
  margin: 15px 0 -10px !important;
}

.x-iso-container-portfolio .x-entry-share p {
  display: none;
}

.x-portfolio .entry-info {
  float: left;
  width: 64%;
}

.x-portfolio .entry-extra {
  float: right;
  width: 28%;
  font-size: 114.2%;
}

@media (max-width: 766.98px) {
  .x-portfolio .entry-extra {
    margin-top: 1.663em;
  }
}

.x-portfolio .entry-extra .x-ul-icons {
  margin-bottom: 1.5em;
}

.x-portfolio .entry-extra .x-btn {
  margin-bottom: 2.5em;
}

@media (max-width: 766.98px) {
  .x-portfolio .entry-info, .x-portfolio .entry-extra {
    float: none;
    width: 100%;
  }
}

.x-portfolio .h-extra {
  margin-top: .5em;
  font-size: 150%;
}

.x-portfolio .h-extra.skills {
  margin: 0 0 .3em;
}

.x-portfolio .h-extra.launch {
  margin: 0 0 .4em;
}

h1.entry-title.entry-title-portfolio {
  margin: 0;
  font-size: 285.7%;
  line-height: 1.05;
}

@media (max-width: 479.98px) {
  h1.entry-title.entry-title-portfolio {
    font-size: 32px;
  }
}

h2.entry-title.entry-title-portfolio {
  text-align: center;
  margin: 0;
  padding: 0 7%;
  font-size: 150%;
  font-weight: 400;
  line-height: 1.05;
}

[class*="page-template-template-blank"] .entry-content {
  margin-top: 0;
}

.page-template-template-blank-3-php.x-navbar-fixed-left-active, .page-template-template-blank-3-php.x-navbar-fixed-right-active, .page-template-template-blank-6-php.x-navbar-fixed-left-active, .page-template-template-blank-6-php.x-navbar-fixed-right-active, .page-template-template-blank-7-php.x-navbar-fixed-left-active, .page-template-template-blank-7-php.x-navbar-fixed-right-active, .page-template-template-blank-8-php.x-navbar-fixed-left-active, .page-template-template-blank-8-php.x-navbar-fixed-right-active {
  padding: 0;
}

[class*="page-template-template-blank"] .x-main, .page-template-template-layout-full-width-php .x-main {
  float: none;
  width: auto;
  display: block;
}

.error404 .entry-404 p {
  max-width: 750px;
  margin: 0 auto 1.313em;
  font-size: 1.5em;
}

@media (max-width: 766.98px) {
  .error404 .entry-404 p {
    font-size: 1.25em;
  }
}

@media (max-width: 479.98px) {
  .error404 .entry-404 p {
    font-size: 1em;
  }
}

.error404 .entry-404 .form-search {
  max-width: 500px;
  margin: 0 auto;
}

.x-header-landmark {
  text-align: center;
  margin: 34px auto 0;
}

.h-landmark {
  letter-spacing: -3px;
  margin: 0;
  padding-bottom: 8px;
  line-height: 1;
  overflow: hidden;
}

@media (max-width: 766.98px) {
  .h-landmark {
    font-size: 48px;
  }
}

@media (max-width: 479.98px) {
  .h-landmark {
    font-size: 42px;
  }
}

.h-landmark span {
  display: inline-block;
  position: relative;
}

.h-landmark span:before, .h-landmark span:after {
  content: "";
  height: 1px;
  width: 275%;
  background-color: #0000001a;
  display: block;
  position: absolute;
  top: 50%;
  box-shadow: 0 1px #ffffffcb;
}

.h-landmark span:before {
  margin-right: .5em;
  right: 100%;
}

.h-landmark span:after {
  margin-left: .5em;
  left: 100%;
}

.p-landmark-sub {
  letter-spacing: -1px;
  margin: 5px 0 0;
  padding: 0 5%;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 24px;
  line-height: 1.3;
}

@media (max-width: 479.98px) {
  .p-landmark-sub {
    font-size: 18px;
  }
}

.p-landmark-sub strong {
  color: #272727;
}

.x-comments-area {
  margin: 4em 0 0;
}

.h-comments-title {
  margin: 0 0 .5em;
  font-size: 285.7%;
  line-height: 1.05;
}

.h-comments-title span {
  position: relative;
}

.h-comments-title small {
  width: 35px;
  height: 35px;
  letter-spacing: 0;
  text-align: center;
  text-shadow: 0 1px 1px #0009;
  color: #fff;
  background-color: #ff2a13;
  border-radius: 100em;
  font-size: 16px;
  font-weight: 300;
  line-height: 34px;
  display: inline-block;
  position: absolute;
  top: -4px;
  right: -40px;
  box-shadow: inset 0 2px 3px #0000004d, 0 2px 1px #fff;
}

.x-comments-list {
  margin: 0;
  list-style: none;
}

.x-comments-list .children {
  margin: 0 0 0 89px;
  list-style: none;
}

@media (max-width: 978.98px) {
  .x-comments-list .children {
    margin-left: 0;
  }
}

li.comment {
  margin: 0;
  padding: 0;
}

article.comment {
  background-color: #fff;
  border-radius: 4px;
  margin: 0 0 30px 89px;
  padding: 30px;
  position: relative;
  box-shadow: 0 .15em .35em #0002;
}

@media (max-width: 479.98px) {
  article.comment {
    padding: 7%;
  }
}

.x-boxed-layout-active article.comment {
  border: 1px solid #ddd;
}

.x-comment-header {
  margin-bottom: 1.313em;
}

.x-comment-content {
  position: relative;
}

.x-comment-author {
  letter-spacing: -1px;
  color: #272727;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 171.4%;
  line-height: 1;
}

.x-comment-author a {
  color: #272727;
}

.x-comment-author a:hover {
  color: #ff2a13;
}

.x-comment-time {
  text-transform: uppercase;
  color: #7a7a7a;
  margin-top: 2px;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 85.7%;
  font-weight: 400;
  line-height: 1.3;
  display: inline-block;
  position: relative;
}

.x-comment-time:hover {
  color: #ff2a13;
}

.x-comment-awaiting-moderation {
  text-align: center;
  color: #ff2a13;
  background-color: #f5f5f5;
  border-radius: 3px;
  padding: .5em .5em .75em;
  line-height: 1.2;
  box-shadow: inset 0 2px 3px #0000001a;
}

.comment-edit-link {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

.comment-reply-link {
  font-size: 128.5%;
  font-weight: 700;
  line-height: 1.4;
  position: relative;
}

.comment-reply-link-after {
  font-size: .722em;
  line-height: 1;
  position: relative;
  bottom: 1px;
}

.x-comment-img {
  float: left;
}

.x-comment-img .avatar-wrap {
  display: block;
  position: relative;
}

.x-comment-img .avatar-wrap:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: block;
  position: absolute;
  box-shadow: inset 0 1px 3px #00000073, 0 1px #ffffffd9;
}

.x-comment-img .avatar-wrap .avatar {
  width: 60px;
  border-radius: 4px;
}

.x-comment-img .bypostauthor {
  width: 100%;
  clear: left;
  text-align: center;
  text-transform: lowercase;
  text-shadow: 0 1px #fff;
  color: #999;
  margin-top: 3px;
  padding: 3px 0 4px;
  font-size: 13px;
  line-height: 1.2;
  display: block;
  bottom: 0;
}

#respond .required {
  color: #ff2a13;
  font-weight: 300;
}

#reply-title {
  margin: 1em 0 0;
  font-size: 285.7%;
  line-height: 1.05;
}

#reply-title small {
  text-transform: uppercase;
  margin: .55em 0 .3em;
  font-size: 40%;
  display: block;
}

#reply-title small a {
  color: #ff2a13;
}

#reply-title small a:hover, #reply-title small a:focus {
  color: #c61300;
}

#commentform {
  margin-bottom: 0;
}

.comment-notes, .logged-in-as {
  margin-bottom: 1em;
  font-size: 114.2%;
}

.comment-form-author, .comment-form-email, .comment-form-url, .comment-form-rating, .comment-form-comment {
  margin: 0;
}

.comment-form-author label, .comment-form-email label, .comment-form-url label, .comment-form-rating label, .comment-form-comment label {
  color: #272727;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 171.4%;
}

.comment-form-author label .required, .comment-form-email label .required, .comment-form-url label .required, .comment-form-rating label .required, .comment-form-comment label .required {
  position: relative;
  right: .175em;
}

.comment-form-author input, .comment-form-email input, .comment-form-url input {
  width: 100%;
  margin-bottom: .85em;
  font-size: 18px;
}

.comment-form-comment textarea {
  width: 100%;
  resize: none;
  margin-bottom: .85em;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 18px;
}

.comment-form-cookies-consent {
  flex-flow: row;
  justify-content: flex-start;
  align-items: baseline;
  display: flex;
}

.comment-form-cookies-consent input {
  margin: 0;
}

.comment-form-cookies-consent label {
  line-height: inherit;
  cursor: pointer;
  margin: 0 0 0 .65em;
}

.form-submit {
  margin: 0;
}

.widget {
  text-shadow: 0 1px #fffffff2;
}

.widget .h-widget:before, .widget .h-widget:after {
  opacity: .35;
}

.widget a:before, .widget a:after {
  text-decoration: inherit;
  opacity: .35;
  text-decoration: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-family: FontAwesome !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.widget p:last-child, .widget ul:last-child, .widget ol:last-child {
  margin-bottom: 0;
}

.widget ul, .widget ol {
  background-color: #0000;
  border: 1px solid #0000001a;
  border-radius: 6px;
  margin-left: 0;
  list-style: none;
  overflow: hidden;
  box-shadow: 0 1px 1px #fffffff2;
}

.widget ul li, .widget ol li {
  border-bottom: 1px solid #0000001a;
  padding: .65em .85em;
  line-height: 1.5;
  transition: background-color .3s;
  box-shadow: 0 1px 1px #fffffff2;
}

.widget ul li:hover, .widget ol li:hover {
  background-color: #fff;
}

.widget ul li a, .widget ol li a {
  color: #7a7a7a;
  border-bottom: 1px dotted;
}

.widget ul li a:hover, .widget ol li a:hover {
  color: #ff2a13;
}

.widget ul li:last-child, .widget ol li:last-child {
  border-bottom: 0;
}

.widget select {
  width: 100%;
}

#lang_sel ul {
  box-shadow: none;
  border: 0;
  overflow: visible;
}

#lang_sel li {
  box-shadow: none;
}

.h-widget {
  margin: 0 0 .5em;
  font-size: 150%;
  line-height: 1;
}

.widget_calendar #wp-calendar {
  background-color: #0000;
  font-size: 87.5%;
}

.widget_calendar #wp-calendar caption {
  letter-spacing: -1px;
  text-transform: uppercase;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 128.5%;
  font-weight: 400;
}

.widget_calendar #wp-calendar th, .widget_calendar #wp-calendar td {
  text-align: center;
  line-height: 1;
}

.widget_calendar #wp-calendar th {
  color: #272727;
  border-bottom: 2px solid #272727;
  padding: 3% 0;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.widget_calendar #wp-calendar td {
  padding: 4.75% 0;
}

.widget_calendar #wp-calendar tbody td {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  box-shadow: inset 0 1px 2px #0000000b;
}

.widget_calendar #wp-calendar tbody td.pad {
  box-shadow: none;
  background-color: #0000;
}

.widget_calendar #wp-calendar tbody td.pad:first-child {
  border-left: 0;
}

.widget_calendar #wp-calendar tbody td.pad:last-child {
  border-right: 0;
}

.widget_calendar #wp-calendar #prev, .widget_calendar #wp-calendar #next {
  text-shadow: none;
  text-transform: uppercase;
  padding: 4.5% 0;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 87.5%;
  font-weight: 300;
  line-height: 1;
}

.widget.widget_categories li ul, .widget.widget_product_categories li ul {
  background-color: #0000;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  margin: .65em -.85em -.65em;
  box-shadow: inset 0 1px 1px #fffffff2;
}

.widget.widget_categories li ul li, .widget.widget_product_categories li ul li {
  box-shadow: 0 1px 1px #fffffff2;
}

.widget.widget_categories li li a, .widget.widget_product_categories li li a {
  margin-left: 1.25em;
}

.widget.widget_categories li li li a, .widget.widget_product_categories li li li a {
  margin-left: 2.5em;
}

.widget_categories select, .widget_product_categories select {
  margin-bottom: 0;
}

.widget_nav_menu .menu > li:last-child {
  margin-bottom: -1px;
}

.widget_nav_menu .current-menu-item > a {
  background-color: #fff;
}

.widget.widget_meta ul, .widget.widget_meta li abbr[title], .widget.widget_pages ul {
  border-bottom: 0;
}

.widget.widget_recent_comments li {
  padding: 1.4em 1.4em 1.5em;
  font-size: 81.25%;
  line-height: 1.3;
}

.widget_recent_comments li > a:last-child {
  border-bottom: 0;
  margin-top: .5em;
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 135.7%;
  font-weight: 400;
  line-height: 1.2;
  display: block;
}

.widget_recent_comments .url {
  border-bottom: 0;
}

.widget_recent_comments .url:before {
  content: "ï";
  padding-right: .4em;
}

.widget.widget_recent_entries li {
  padding: 1.4em;
  font-size: 81.25%;
}

.widget.widget_recent_entries li a {
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 135.7%;
  font-weight: 400;
  line-height: 1.2;
  display: block;
  border-bottom: 0 !important;
}

.widget.widget_recent_entries li .post-date {
  text-transform: uppercase;
  opacity: .575;
  margin-top: .5em;
  display: block;
}

.widget_rss .rsswidget img {
  display: none;
}

.widget.widget_rss li {
  padding: 1.4em;
  font-size: 81.25%;
}

.widget.widget_rss li .rsswidget {
  font-family: Lato, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 135.7%;
  font-weight: 400;
  line-height: 1.2;
  display: block;
  border-bottom: 0 !important;
}

.widget.widget_rss li .rsswidget:before {
  content: "ï";
  padding-right: .4em;
}

.widget_rss .rss-date {
  text-transform: uppercase;
  opacity: .575;
  margin-top: .5em;
  display: block;
}

.widget_rss .rssSummary {
  margin: .75em 0;
  font-size: 84.6%;
}

.widget_rss cite {
  text-transform: uppercase;
  opacity: .575;
  display: block;
}

.widget_rss cite:before {
  content: "By: ";
}

.widget_tag_cloud, .widget_product_tag_cloud {
  text-shadow: none;
}

.widget_tag_cloud .tagcloud, .widget_product_tag_cloud .tagcloud {
  margin-bottom: -.615em;
}

.widget_tag_cloud .tagcloud:before, .widget_tag_cloud .tagcloud:after, .widget_product_tag_cloud .tagcloud:before, .widget_product_tag_cloud .tagcloud:after {
  content: " ";
  width: 0;
  display: table;
}

.widget_tag_cloud .tagcloud:after, .widget_product_tag_cloud .tagcloud:after {
  clear: both;
}

.widget_tag_cloud .tagcloud a, .widget_product_tag_cloud .tagcloud a {
  float: left;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  color: #c5c5c5;
  color: #00000060;
  background-color: #0000;
  border: 1px solid #00000020;
  border-radius: .35em;
  margin: .615em .615em 0 0;
  padding: .692em 1.154em .769em;
  font-size: 13px;
  line-height: 1.3;
  transition: color .3s, border-color .3s, background-color .3s, box-shadow .3s;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 0 #0000, 0 1px 1px #fffffff2;
}

@media (max-width: 766.98px) {
  .widget_tag_cloud .tagcloud a, .widget_product_tag_cloud .tagcloud a {
    font-size: 11px;
  }
}

@media (max-width: 479.98px) {
  .widget_tag_cloud .tagcloud a, .widget_product_tag_cloud .tagcloud a {
    font-size: 10px;
  }
}

.widget_tag_cloud .tagcloud a:hover, .widget_product_tag_cloud .tagcloud a:hover {
  color: #777;
  color: #000000bf;
  background-color: #fff;
  border-color: #00000040;
}

.widget_tag_cloud .tagcloud a:active, .widget_product_tag_cloud .tagcloud a:active {
  color: #777;
  color: #000000bf;
  background-color: #00000013;
  border-color: #00000040;
  box-shadow: inset 0 1px 2px #00000039, 0 1px 1px #fffffff2;
}

.widget_text ol, .widget_text ul {
  box-shadow: none;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  margin: 0 0 1.313em 1.655em;
  list-style: disc;
  overflow: visible;
}

.widget_text ol li, .widget_text ul li {
  box-shadow: none;
  background-color: #0000;
  border: 0;
  padding: 0;
}

.widget_text ol li:hover, .widget_text ul li:hover {
  background-color: #0000;
}

.widget_text ol li a, .widget_text ul li a {
  border-bottom: 0;
}

.widget_text ol.x-ul-icons, .widget_text ul.x-ul-icons {
  list-style: none;
}

.widget_nav_menu ul li, .widget_meta ul li, .widget_pages ul li {
  box-shadow: none;
  border: 0;
  padding: 0;
}

.widget_nav_menu ul li:hover, .widget_meta ul li:hover, .widget_pages ul li:hover {
  background-color: #0000;
}

.widget_nav_menu ul li a, .widget_meta ul li a, .widget_pages ul li a {
  border-bottom: 1px solid #0000001a;
  padding: .65em .85em;
  display: block;
  box-shadow: 0 1px 1px #fffffff2;
}

.widget_nav_menu ul li a:before, .widget_meta ul li a:before, .widget_pages ul li a:before {
  content: "ïƒš";
  padding-right: .4em;
}

.widget_nav_menu ul li a:hover, .widget_meta ul li a:hover, .widget_pages ul li a:hover {
  background-color: #fff;
}

.widget_nav_menu ul li ul, .widget_nav_menu ul li .sub-menu, .widget_meta ul li ul, .widget_meta ul li .sub-menu, .widget_pages ul li ul, .widget_pages ul li .sub-menu {
  background-color: #0000;
  border: 0;
  border-radius: 0;
  box-shadow: 0 1px 1px #fffffff2;
}

.widget_nav_menu ul li ul li a, .widget_nav_menu ul li .sub-menu li a, .widget_meta ul li ul li a, .widget_meta ul li .sub-menu li a, .widget_pages ul li ul li a, .widget_pages ul li .sub-menu li a {
  padding-left: 2.55em;
}

.widget_nav_menu ul li ul li:first-child a, .widget_nav_menu ul li .sub-menu li:first-child a, .widget_meta ul li ul li:first-child a, .widget_meta ul li .sub-menu li:first-child a, .widget_pages ul li ul li:first-child a, .widget_pages ul li .sub-menu li:first-child a {
  border-radius: 0;
}

.widget_nav_menu ul li ul ul li a, .widget_nav_menu ul li .sub-menu ul li a, .widget_meta ul li ul ul li a, .widget_meta ul li .sub-menu ul li a, .widget_pages ul li ul ul li a, .widget_pages ul li .sub-menu ul li a {
  padding-left: 4.25em;
}

.sticky {
  margin: 0;
}

.alignright {
  float: right;
  margin: .35em 0 .35em 1.313em;
}

.alignleft {
  float: left;
  margin: .35em 1.313em .35em 0;
}

.alignnone {
  float: none;
  margin: 0 0 1.313em;
}

.aligncenter {
  margin: 0 auto 1.313em;
  display: block;
}

.wp-caption, .gallery-item {
  max-width: 100%;
  text-align: center;
  background-color: #fff;
  border: 1px solid #00000026;
  border-radius: 3px;
  padding: 5px;
  box-shadow: 0 .125em .275em #00000013;
}

.wp-caption img, .gallery-item img {
  border-radius: 2px;
}

.wp-caption > .gallery-caption, .wp-caption > .wp-caption-text, .wp-caption > .wp-caption-text:last-child, .gallery-item > .gallery-caption, .gallery-item > .wp-caption-text, .gallery-item > .wp-caption-text:last-child {
  text-transform: uppercase;
  margin: 9px 0 5px;
  padding: 0 5%;
  font-size: 10px;
  line-height: 1.2;
  display: block;
}

.gallery {
  clear: both;
  margin: -4% 0 1.313em;
}

.gallery:before, .gallery:after {
  content: " ";
  width: 0;
  display: table;
}

.gallery:after {
  clear: both;
}

.gallery .gallery-item {
  float: left;
  margin: 4% 4% 0 0;
}

.gallery-columns-0 .gallery-item, .gallery-columns-1 .gallery-item {
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  width: 48%;
}

.gallery-columns-2 .gallery-item:nth-child(2n) {
  margin-right: 0;
}

.gallery-columns-3 .gallery-item {
  width: 30.6666%;
}

.gallery-columns-3 .gallery-item:nth-child(3n) {
  margin-right: 0;
}

.gallery-columns-4 .gallery-item {
  width: 22%;
}

.gallery-columns-4 .gallery-item:nth-child(4n) {
  margin-right: 0;
}

@media (max-width: 978.98px) {
  .gallery-columns-4 .gallery-item {
    width: 48%;
  }

  .gallery-columns-4 .gallery-item:nth-child(2n) {
    margin-right: 0;
  }
}

@media (max-width: 766.98px) {
  .gallery-columns-0 .gallery-item, .gallery-columns-1 .gallery-item, .gallery-columns-2 .gallery-item, .gallery-columns-3 .gallery-item, .gallery-columns-4 .gallery-item {
    width: 100%;
    float: none;
    margin-right: 0;
  }
}

.alignwide, .alignfull {
  width: auto;
  max-width: none;
}

.alignwide img, .alignfull img {
  margin: 0 auto;
  display: block;
}

.alignwide {
  margin-left: -30px;
  margin-right: -30px;
}

@media (max-width: 766.98px) {
  .alignwide {
    margin-left: -18px;
    margin-right: -18px;
  }
}

@media (max-width: 479.98px) {
  .alignwide {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.alignfull {
  margin-left: -60px;
  margin-right: -60px;
}

@media (max-width: 766.98px) {
  .alignfull {
    margin-left: -36px;
    margin-right: -36px;
  }
}

@media (max-width: 479.98px) {
  .alignfull {
    margin-left: -25px;
    margin-right: -25px;
  }
}

.page-template-template-blank-4-php .alignwide, .page-template-template-blank-4-php .alignfull, .page-template-template-blank-5-php .alignwide, .page-template-template-blank-5-php .alignfull, .page-template-template-blank-6-php .alignwide, .page-template-template-blank-6-php .alignfull, .page-template-template-blank-8-php .alignwide, .page-template-template-blank-8-php .alignfull {
  margin-left: 0;
  margin-right: 0;
}

.x-resp-embed, .is-type-video {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.wp-embed-aspect-5-3 .x-resp-embed {
  padding-bottom: 60%;
}

.wp-embed-aspect-4-4 .x-resp-embed {
  padding-bottom: 80%;
}

.wp-embed-aspect-4-3 .x-resp-embed {
  padding-bottom: 75%;
}

.wp-embed-aspect-3-2 .x-resp-embed {
  padding-bottom: 66.67%;
}

.x-resp-embed iframe, .is-type-video iframe {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

@media print {
  * {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: "";
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #999;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: .5cm;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  .x-root {
    display: initial;
  }
}

[data-x-particle] {
  transition-delay: 0s;
}

.x-dropdown.x-active {
  transition-delay: 0s, 0s, 0s;
}

.x-modal.x-active {
  transition-delay: 0s, 0s;
}

.x-td, .x-div, .x-row, .x-col, .x-grid, .x-cell, .x-section, .x-acc-header, .x-acc-header-indicator, .x-crumbs-link, .x-paginate-inner > *, .x-anchor, .x-anchor-text-primary, .x-anchor-text-secondary, .x-anchor-sub-indicator, .x-anchor.has-int-content .x-anchor-content, .x-bar-is-sticky, .x-bar-is-sticky .x-bar-content, [data-x-toggle-collapse], .x-graphic-child, .x-image, .x-mini-cart li, .x-mini-cart a:not(.x-anchor), .x-modal-close, .x-search, .x-search-btn, .x-search-input, .x-icon, .x-text, .x-text-content-text-primary, .x-text-content-text-subheadline, .x-text-typing, .x-typed-cursor, .x-menu-first-level, .x-menu-layered:not(.x-menu-first-level), .x-wc-add-to-cart-form, .x-wc-shop-sort, [data-x-particle], .x-bar, .x-bar-scroll-button, .x-bar-container, .x-loader {
  transition-duration: .3s;
}

.x-card, .x-card-faces, .x-statbar, .x-statbar-bar, .x-statbar-label {
  transition-duration: .75s;
}

[class^="x-bg"][data-x-params*="parallax"] {
  transition-duration: .3s, 0s;
}

.has-particle:hover [data-x-particle][data-x-particle*="scale"], .has-particle.x-active [data-x-particle][data-x-particle*="scale"], [data-x-effect-provider*="particles"]:hover [data-x-particle][data-x-particle*="scale"] {
  transition-duration: 0s, .3s;
}

[data-x-effect] {
  animation-duration: 1s;
}

.x-dropdown {
  transition-property: opacity, transform, visibility;
}

.x-modal {
  transition-property: opacity, visibility;
}

.x-modal-close, .x-off-canvas-close {
  transition-property: color, opacity, transform;
}

.x-modal-content, .x-off-canvas-content {
  transition-property: transform;
}

.x-off-canvas {
  transition-property: visibility;
}

.x-off-canvas-bg {
  transition-property: opacity;
}

[data-x-particle] {
  transition-property: opacity, transform;
}

.x-bar, .x-bar-container {
  transition-property: opacity, filter, transform;
}

.x-bar-is-sticky, .x-bar-is-sticky .x-bar-content {
  transition-property: height, opacity;
}

.x-bar-is-visible {
  transition-property: height, opacity, transform;
}

[data-x-effect], .x-card, .x-statbar, .x-menu-first-level, .x-wc-add-to-cart-form, .x-wc-shop-sort {
  transition-property: opacity, filter, transform;
}

.x-menu-layered:not(.x-menu-first-level) {
  transition-property: height;
}

.x-menu-layered.x-menu-first-level {
  transition-property: height, opacity, filter, transform;
}

.x-div, .x-row, .x-col, .x-grid, .x-cell, .x-section, .x-image, .x-anchor, .x-text-headline {
  transition-property: border-color, background-color, box-shadow, opacity, filter, transform;
}

.x-icon, .x-search {
  transition-property: color, border-color, background-color, box-shadow, text-shadow, opacity, filter, transform;
}

.x-text:not(.x-text-headline) {
  transition-property: color, border-color, background-color, box-shadow, text-shadow, column-rule, opacity, filter, transform;
}

.x-loader {
  transition-property: opacity;
}

[data-x-toggle-collapse] {
  transition-property: height;
}

.x-card-faces, .x-acc-header-indicator {
  transition-property: transform;
}

.x-anchor-text-primary, .x-anchor-text-secondary, .x-anchor-sub-indicator, .x-text-content-text-primary, .x-text-content-text-subheadline, .x-text-typing, .x-typed-cursor {
  transition-property: color, text-shadow;
}

.x-anchor.has-int-content .x-anchor-content, [class^="x-bg"][data-x-params*="parallax"], .x-menu-layered .x-anchor {
  transition-property: opacity, transform;
}

.x-statbar-bar, .x-statbar-label {
  transition-property: width, height, transform, opacity;
}

.x-acc-header, .x-crumbs-link, .x-paginate-inner > *, .x-mini-cart li, .x-mini-cart a:not(.x-anchor), .x-search-btn, .x-search-input {
  transition-property: color, border-color, background-color, text-shadow, box-shadow;
}

.x-graphic-child {
  transition-property: color, background-color, text-shadow, box-shadow, opacity, transform;
}

.x-bar-scroll-button {
  transition-property: color, border-color, background-color, box-shadow, text-shadow, opacity, transform;
}

.x-ttf, .x-div, .x-row, .x-col, .x-grid, .x-cell, .x-section, .x-acc-header, .x-acc-header-indicator, .x-crumbs-link, .x-paginate-inner > *, .x-anchor, .x-anchor-text-primary, .x-anchor-text-secondary, .x-anchor-sub-indicator, .x-anchor.has-int-content .x-anchor-content, .x-bar-is-sticky, .x-bar-is-sticky .x-bar-content, [data-x-toggle-collapse], .x-graphic-child, .x-image, .x-mini-cart li, .x-mini-cart a:not(.x-anchor), .x-modal-close, .x-search, .x-search-btn, .x-search-input, .x-icon, .x-text, .x-text-content-text-primary, .x-text-content-text-subheadline, .x-text-typing, .x-typed-cursor, .x-menu-first-level, .x-menu-layered:not(.x-menu-first-level), .x-card, .x-card-faces, .x-statbar, .x-statbar-bar, .x-statbar-label, .x-wc-add-to-cart-form, .x-wc-shop-sort, [data-x-particle], .x-bar, .x-bar-scroll-button, .x-bar-container, .x-loader {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

[class^="x-bg"][data-x-params*="parallax"] {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1), linear;
}

.x-off-canvas {
  transition-timing-function: linear;
}

[data-x-effect] {
  animation-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.x-crumbs-link .x-icon, .x-paginate-inner > * .x-icon, .x-bar-scroll-button .x-icon {
  transition: initial;
}

[data-x-disable-animation], [data-x-disable-animation] * {
  transition: none !important;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

.animated.bounceIn, .animated.bounceOut, .animated.flipOutX, .animated.flipOutY {
  animation-duration: .75s;
}

.x-anim-hide, .animated-hide {
  opacity: 0;
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.heartBeat {
  animation-name: heartBeat;
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
}

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.shakeY {
  animation-name: shakeY;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.bounceOut {
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }
}

.animated.flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.flipOutX {
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.flipOutY {
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.hinge {
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

.x-effect-exit, .x-effect-entering, .x-effect-exiting {
  pointer-events: none !important;
}

.x-effect-animated {
  animation-fill-mode: both !important;
}

@media print, (prefers-reduced-motion: reduce) {
  .x-effect-animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .x-effect-animated[class*="Out"] {
    opacity: 0;
  }
}

.x-no-at, .x-no-at a, .x-no-at i, .x-no-at ul, .x-no-at ol, .x-no-at img, .x-no-at div, .x-no-at span, .x-no-at button {
  transition-duration: 1ms !important;
  transition-delay: 1ms !important;
  animation-duration: 1ms !important;
  animation-delay: 1ms !important;
}

.cf:before, .cf:after {
  content: " ";
  width: 0;
  display: table;
}

.cf:after {
  clear: both;
}

.right {
  float: right;
}

.left {
  float: left;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

.center-list {
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.invisible {
  visibility: hidden;
}

.visually-hidden, .screen-reader-text, .screen-reader-response, .bp-screen-reader-text {
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visually-hidden.focusable:active, .visually-hidden.focusable:focus {
  clip: auto;
  height: auto;
  width: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.transparent {
  opacity: 0;
}

.opaque {
  opacity: 1;
}

.w-900 {
  font-weight: 900;
}

.w-800 {
  font-weight: 800;
}

.w-700 {
  font-weight: 700;
}

.w-600 {
  font-weight: 600;
}

.w-500 {
  font-weight: 500;
}

.w-400 {
  font-weight: 400;
}

.w-300 {
  font-weight: 300;
}

.w-200 {
  font-weight: 200;
}

.w-100 {
  font-weight: 100;
}

.tt-upper {
  text-transform: uppercase;
}

.tt-lower {
  text-transform: lowercase;
}

.tt-none {
  text-transform: none;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.left-text {
  text-align: left;
}

.justify-text {
  text-align: justify;
}

@media (min-width: 1200px) {
  .x-hide-xl {
    display: none !important;
  }
}

@media (min-width: 979px) and (max-width: 1199.98px) {
  .x-hide-lg {
    display: none !important;
  }
}

@media (min-width: 767px) and (max-width: 978.98px) {
  .x-hide-md {
    display: none !important;
  }
}

@media (min-width: 480px) and (max-width: 766.98px) {
  .x-hide-sm {
    display: none !important;
  }
}

@media (max-width: 479.98px) {
  .x-hide-xs {
    display: none !important;
  }
}

.x-visible-phone, .x-visible-tablet {
  display: none !important;
}

.x-visible-desktop {
  display: inherit !important;
}

span.x-visible-desktop {
  display: inline !important;
}

.x-hidden-desktop {
  display: none !important;
}

@media (min-width: 767px) and (max-width: 978.98px) {
  .x-visible-tablet {
    display: inherit !important;
  }

  span.x-visible-tablet {
    display: inline !important;
  }

  .x-hidden-tablet {
    display: none !important;
  }

  .x-hidden-desktop {
    display: inherit !important;
  }

  span.x-hidden-desktop {
    display: inline !important;
  }

  .x-visible-desktop {
    display: none !important;
  }
}

@media (max-width: 766.98px) {
  .x-visible-phone {
    display: inherit !important;
  }

  span.x-visible-phone {
    display: inline !important;
  }

  .x-hidden-phone {
    display: none !important;
  }

  .x-hidden-desktop {
    display: inherit !important;
  }

  span.x-hidden-desktop {
    display: inline !important;
  }

  .x-visible-desktop {
    display: none !important;
  }
}

.ptn, .pvn, .pan {
  padding-top: 0 !important;
}

.pts, .pvs, .pas {
  padding-top: .5em !important;
}

.ptm, .pvm, .pam {
  padding-top: 1em !important;
}

.ptl, .pvl, .pal {
  padding-top: 2em !important;
}

.prn, .phn, .pan {
  padding-right: 0 !important;
}

.prs, .phs, .pas {
  padding-right: .5em !important;
}

.prm, .phm, .pam {
  padding-right: 1em !important;
}

.prl, .phl, .pal {
  padding-right: 2em !important;
}

.pbn, .pvn, .pan {
  padding-bottom: 0 !important;
}

.pbs, .pvs, .pas {
  padding-bottom: .5em !important;
}

.pbm, .pvm, .pam {
  padding-bottom: 1em !important;
}

.pbl, .pvl, .pal {
  padding-bottom: 2em !important;
}

.pln, .phn, .pan {
  padding-left: 0 !important;
}

.pls, .phs, .pas {
  padding-left: .5em !important;
}

.plm, .phm, .pam {
  padding-left: 1em !important;
}

.pll, .phl, .pal {
  padding-left: 2em !important;
}

.mtn, .mvn, .man {
  margin-top: 0 !important;
}

.mts, .mvs, .mas {
  margin-top: .5em !important;
}

.mtm, .mvm, .mam {
  margin-top: 1em !important;
}

.mtl, .mvl, .mal {
  margin-top: 2em !important;
}

.mrn, .mhn, .man {
  margin-right: 0 !important;
}

.mrs, .mhs, .mas {
  margin-right: .5em !important;
}

.mrm, .mhm, .mam {
  margin-right: 1em !important;
}

.mrl, .mhl, .mal {
  margin-right: 2em !important;
}

.mbn, .mvn, .man {
  margin-bottom: 0 !important;
}

.mbs, .mvs, .mas {
  margin-bottom: .5em !important;
}

.mbm, .mvm, .mam {
  margin-bottom: 1em !important;
}

.mbl, .mvl, .mal {
  margin-bottom: 2em !important;
}

.mln, .mhn, .man {
  margin-left: 0 !important;
}

.mls, .mhs, .mas {
  margin-left: .5em !important;
}

.mlm, .mhm, .mam {
  margin-left: 1em !important;
}

.mll, .mhl, .mal {
  margin-left: 2em !important;
}

.cs-cf:before, .cs-cf:after, .cs-clearfix:before, .cs-clearfix:after {
  content: " ";
  width: 0;
  display: table;
}

.cs-cf:after, .cs-clearfix:after {
  clear: both;
}

.cs-f-n {
  float: none;
}

.cs-f-l {
  float: left;
}

.cs-f-r {
  float: right;
}

.cs-hide {
  display: none !important;
}

.cs-visually-hidden {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0 0 0 0) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.cs-o-1 {
  opacity: 0 !important;
}

.cs-o-0 {
  opacity: 1 !important;
}

.cs-pos-r {
  position: relative !important;
}

.cs-pos-a {
  position: absolute !important;
}

.cs-pos-f {
  position: fixed !important;
}

.cs-fw-900 {
  font-weight: 900 !important;
}

.cs-fw-800 {
  font-weight: 800 !important;
}

.cs-fw-700 {
  font-weight: 700 !important;
}

.cs-fw-600 {
  font-weight: 600 !important;
}

.cs-fw-500 {
  font-weight: 500 !important;
}

.cs-fw-400 {
  font-weight: 400 !important;
}

.cs-fw-300 {
  font-weight: 300 !important;
}

.cs-fw-200 {
  font-weight: 200 !important;
}

.cs-fw-100 {
  font-weight: 100 !important;
}

.cs-ta-left {
  text-align: left !important;
}

.cs-ta-right {
  text-align: right !important;
}

.cs-ta-center {
  text-align: center !important;
}

.cs-ta-justify {
  text-align: justify !important;
}

.cs-tt-none {
  text-transform: none !important;
}

.cs-tt-uppercase {
  text-transform: uppercase !important;
}

.cs-tt-lowercase {
  text-transform: lowercase !important;
}

.cs-tt-capitalize {
  text-transform: capitalize !important;
}

@media (min-width: 1200px) {
  .cs-hide-xl {
    display: none !important;
  }
}

@media (min-width: 979px) and (max-width: 1199.98px) {
  .cs-hide-lg {
    display: none !important;
  }
}

@media (min-width: 767px) and (max-width: 978.98px) {
  .cs-hide-md {
    display: none !important;
  }
}

@media (min-width: 480px) and (max-width: 766.98px) {
  .cs-hide-sm {
    display: none !important;
  }
}

@media (max-width: 479.98px) {
  .cs-hide-xs {
    display: none !important;
  }
}

.cs-ptn, .cs-pvn, .cs-pan {
  padding-top: 0 !important;
}

.cs-pts, .cs-pvs, .cs-pas {
  padding-top: .5em !important;
}

.cs-ptm, .cs-pvm, .cs-pam {
  padding-top: 1em !important;
}

.cs-ptl, .cs-pvl, .cs-pal {
  padding-top: 2em !important;
}

.cs-prn, .cs-phn, .cs-pan {
  padding-right: 0 !important;
}

.cs-prs, .cs-phs, .cs-pas {
  padding-right: .5em !important;
}

.cs-prm, .cs-phm, .cs-pam {
  padding-right: 1em !important;
}

.cs-prl, .cs-phl, .cs-pal {
  padding-right: 2em !important;
}

.cs-pbn, .cs-pvn, .cs-pan {
  padding-bottom: 0 !important;
}

.cs-pbs, .cs-pvs, .cs-pas {
  padding-bottom: .5em !important;
}

.cs-pbm, .cs-pvm, .cs-pam {
  padding-bottom: 1em !important;
}

.cs-pbl, .cs-pvl, .cs-pal {
  padding-bottom: 2em !important;
}

.cs-pln, .cs-phn, .cs-pan {
  padding-left: 0 !important;
}

.cs-pls, .cs-phs, .cs-pas {
  padding-left: .5em !important;
}

.cs-plm, .cs-phm, .cs-pam {
  padding-left: 1em !important;
}

.cs-pll, .cs-phl, .cs-pal {
  padding-left: 2em !important;
}

.cs-mtn, .cs-mvn, .cs-man {
  margin-top: 0 !important;
}

.cs-mts, .cs-mvs, .cs-mas {
  margin-top: .5em !important;
}

.cs-mtm, .cs-mvm, .cs-mam {
  margin-top: 1em !important;
}

.cs-mtl, .cs-mvl, .cs-mal {
  margin-top: 2em !important;
}

.cs-mrn, .cs-mhn, .cs-man {
  margin-right: 0 !important;
}

.cs-mrs, .cs-mhs, .cs-mas {
  margin-right: .5em !important;
}

.cs-mrm, .cs-mhm, .cs-mam {
  margin-right: 1em !important;
}

.cs-mrl, .cs-mhl, .cs-mal {
  margin-right: 2em !important;
}

.cs-mbn, .cs-mvn, .cs-man {
  margin-bottom: 0 !important;
}

.cs-mbs, .cs-mvs, .cs-mas {
  margin-bottom: .5em !important;
}

.cs-mbm, .cs-mvm, .cs-mam {
  margin-bottom: 1em !important;
}

.cs-mbl, .cs-mvl, .cs-mal {
  margin-bottom: 2em !important;
}

.cs-mln, .cs-mhn, .cs-man {
  margin-left: 0 !important;
}

.cs-mls, .cs-mhs, .cs-mas {
  margin-left: .5em !important;
}

.cs-mlm, .cs-mhm, .cs-mam {
  margin-left: 1em !important;
}

.cs-mll, .cs-mhl, .cs-mal {
  margin-left: 2em !important;
}

.desktop .menu-item, .desktop .menu-item > a {
  position: relative;
}

.desktop .menu-item.x-active > .sub-menu {
  display: block;
}

.desktop .sub-menu {
  float: left;
  min-width: 200px;
  z-index: 1000;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 4px;
  margin: 0;
  padding: .75em 0;
  font-size: 12px;
  list-style: none;
  display: none;
  position: absolute;
  box-shadow: 0 3px 5px #00000040;
}

.desktop .sub-menu a {
  clear: both;
  white-space: nowrap;
  color: #b7b7b7;
  padding: .5em 1.6em;
  line-height: 1.7;
  display: block;
}

.desktop .sub-menu a:hover {
  color: #272727;
  background-color: #00000004;
  text-decoration: none;
}

.desktop .sub-menu .x-active > a, .desktop .sub-menu .current-menu-item > a {
  background-color: #00000004;
}

.masthead-inline .x-navbar .desktop .sub-menu {
  left: auto;
  right: 0;
}

.masthead-inline .x-navbar .desktop .sub-menu .sub-menu {
  top: -.75em;
  right: 98%;
}

.masthead-inline .x-navbar.x-navbar-fixed-left .desktop .sub-menu {
  top: 0;
  left: 94%;
  right: auto;
}

.masthead-inline .x-navbar.x-navbar-fixed-left .desktop .sub-menu .sub-menu {
  top: -.75em;
  left: 98%;
  right: auto;
}

.masthead-inline .x-navbar.x-navbar-fixed-right .desktop .sub-menu {
  top: 0;
  left: auto;
  right: 94%;
}

.masthead-inline .x-navbar.x-navbar-fixed-right .desktop .sub-menu .sub-menu {
  top: -.75em;
  left: auto;
  right: 98%;
}

.masthead-stacked .x-navbar .desktop .sub-menu {
  left: 0;
  right: auto;
}

.masthead-stacked .x-navbar .desktop .sub-menu .sub-menu {
  top: -.75em;
  left: 98%;
}

.x-portfolio-filters-menu {
  display: none;
}

.no-js .x-portfolio-filters-menu {
  display: block;
}

.x-widgetbar {
  color: #727272;
  z-index: 1032;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 .15em .35em #0002;
}

@media (max-width: 978.98px) {
  .x-widgetbar {
    position: absolute;
  }
}

.x-widgetbar .widget {
  margin-top: 3em;
}

.x-widgetbar-inner {
  padding: 2.5% 0;
}

.x-widgetbar-inner .x-column .widget:first-child {
  margin-top: 0;
}

@media (max-width: 978.98px) {
  .x-widgetbar-inner {
    padding: 6.5% 0;
  }

  .x-widgetbar-inner .x-column .widget:first-child {
    margin-top: 3em;
  }

  .x-widgetbar-inner .x-column:first-child .widget:first-child {
    margin-top: 0;
  }
}

.x-btn-widgetbar {
  color: #ffffff80;
  z-index: 1033;
  background-color: #0000;
  border: 19px solid #272727;
  border-color: #272727 #272727 #0000 #0000;
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 1;
  position: fixed;
  top: 0;
  right: 0;
}

@media (max-width: 978.98px) {
  .x-btn-widgetbar {
    position: absolute;
  }
}

.x-btn-widgetbar i {
  position: absolute;
  top: -15px;
  right: -13px;
}

.x-btn-widgetbar:hover {
  color: #ffffffd9;
}

.x-navbar-fixed-left-active .x-widgetbar, .x-navbar-fixed-right-active .x-widgetbar {
  z-index: 1029;
}

@media (max-width: 978.98px) {
  .x-navbar-fixed-left-active .x-widgetbar, .x-navbar-fixed-right-active .x-widgetbar {
    z-index: 1032;
  }
}

.x-topbar {
  min-height: 46px;
  z-index: 1031;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  position: relative;
}

.x-topbar:before, .x-topbar:after {
  content: " ";
  width: 0;
  display: table;
}

.x-topbar:after {
  clear: both;
}

@media (max-width: 766.98px) {
  .x-topbar {
    text-align: center;
  }
}

.x-topbar .p-info {
  float: left;
  color: #b7b7b7;
  margin: 13px 0 0;
  font-size: 11px;
  line-height: 1.3;
}

@media (max-width: 766.98px) {
  .x-topbar .p-info {
    float: none;
    background-color: #f6f6f6;
    border-radius: 0 0 4px 4px;
    margin: 0;
    padding: .8em 1em 1em;
  }
}

.x-topbar .p-info a {
  color: #b7b7b7;
  border-bottom: 1px dotted;
}

.x-topbar .p-info a:hover {
  color: auto;
}

.x-topbar .x-social-global {
  float: right;
  margin-top: 10px;
  transform: translate3d(0, 0, 0);
}

@media (max-width: 766.98px) {
  .x-topbar .x-social-global {
    float: none;
    margin-bottom: 8px;
  }
}

.x-topbar .x-social-global a {
  margin-left: 7px;
  font-size: 24px;
}

@media (max-width: 766.98px) {
  .x-topbar .x-social-global a {
    margin: 0 1.5%;
  }
}

.x-topbar .x-social-global a.foursquare {
  font-size: 22px;
}

.x-topbar .x-social-global a i {
  display: block;
}

.x-navbar-fixed-left-active .x-topbar, .x-navbar-fixed-right-active .x-topbar {
  z-index: 1028;
}

@media (max-width: 978.98px) {
  .x-navbar-fixed-left-active .x-topbar, .x-navbar-fixed-right-active .x-topbar {
    z-index: 1031;
  }
}

.x-logobar {
  text-align: center;
  z-index: 1030;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  position: relative;
  overflow: visible;
}

.x-navbar {
  z-index: 1030;
  position: relative;
  overflow: visible;
}

.x-navbar .x-container {
  position: relative;
}

.x-nav-wrap.desktop {
  display: block;
}

.x-nav-wrap.mobile {
  clear: both;
  display: none;
  overflow: hidden;
}

@media (max-width: 978.98px) {
  .x-nav-wrap.desktop {
    display: none;
  }

  .x-nav-wrap.mobile {
    display: block;
  }

  .x-nav-wrap.mobile.x-collapsed {
    display: none;
  }
}

.x-brand {
  float: left;
  line-height: 1;
  text-decoration: none;
  display: block;
}

.masthead-stacked .x-brand {
  float: none;
  display: inline-block;
}

.x-btn-navbar {
  font-size: 24px;
  line-height: 1;
  display: none;
}

@media (max-width: 978.98px) {
  .masthead-inline .x-btn-navbar {
    float: right;
    display: block;
  }

  .masthead-stacked .x-btn-navbar {
    display: inline-block;
  }
}

.x-navbar .x-nav a {
  transition: none;
}

.x-navbar .x-nav > li > a > span {
  display: inline-block;
}

@media (max-width: 978.98px) {
  .masthead-stacked .x-navbar {
    text-align: center;
  }
}

.x-navbar .desktop .x-nav {
  margin: 0;
}

.x-navbar .desktop .x-nav > li {
  float: left;
}

.x-navbar .desktop .x-nav > li > a {
  float: none;
  line-height: 1;
  text-decoration: none;
}

.x-navbar .desktop .x-nav > li > a:hover {
  text-decoration: none;
}

.x-navbar .desktop .x-nav li > a > span:after {
  content: "ï„ƒ";
  text-decoration: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: .35em;
  font-family: FontAwesome !important;
  font-style: normal !important;
  font-weight: normal !important;
}

.x-navbar .desktop .x-nav li > a:only-child > span:after {
  content: "";
  display: none;
}

.masthead-inline .desktop .x-nav {
  float: right;
  display: block;
}

.masthead-stacked .desktop .x-nav {
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.x-navbar .mobile .x-nav {
  margin: 25px 0;
}

.x-navbar .mobile .x-nav li > a {
  white-space: normal;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  position: relative;
}

.x-navbar .mobile .x-nav ul {
  margin: 0 0 0 2.25em;
  list-style: none;
}

.masthead-stacked .mobile .x-nav {
  text-align: initial;
}

.x-sub-toggle {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.x-sub-toggle > span {
  width: 100%;
  letter-spacing: 0;
  margin-top: -9px;
  font-size: 18px;
  line-height: 1;
  display: block;
  position: absolute;
  top: 50%;
}

.x-navbar-fixed-top, .x-navbar-fixed-left, .x-navbar-fixed-right {
  z-index: 1030;
  position: fixed;
}

@media (max-width: 978.98px) {
  .x-navbar-fixed-top, .x-navbar-fixed-left, .x-navbar-fixed-right {
    position: relative;
  }
}

.x-navbar-fixed-top {
  top: 0;
  left: 0;
  right: 0;
}

@media (max-width: 978.98px) {
  .x-navbar-fixed-top-active .x-navbar-wrap {
    height: auto;
  }
}

.x-navbar-fixed-left, .x-navbar-fixed-right {
  width: 228px;
  top: 0;
  bottom: 0;
}

.x-navbar-fixed-left .x-navbar-inner, .x-navbar-fixed-right .x-navbar-inner {
  height: 100%;
}

.x-navbar-fixed-left .x-navbar-inner > .x-container.width, .x-navbar-fixed-right .x-navbar-inner > .x-container.width {
  width: 100%;
}

.x-navbar-fixed-left .x-brand, .x-navbar-fixed-right .x-brand {
  float: none;
  text-align: center;
}

.x-navbar-fixed-left .desktop .x-nav, .x-navbar-fixed-right .desktop .x-nav {
  float: none;
  margin-top: 2em;
}

.x-navbar-fixed-left .desktop .x-nav > li, .x-navbar-fixed-right .desktop .x-nav > li {
  float: none;
}

@media (max-width: 978.98px) {
  .x-navbar-fixed-left, .x-navbar-fixed-right {
    width: auto;
  }

  .x-navbar-fixed-left .x-brand, .x-navbar-fixed-right .x-brand {
    float: left;
  }
}

.x-navbar-fixed-left {
  left: 0;
}

.x-navbar-fixed-right {
  right: 0;
}

.x-navbar {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 .15em .35em #0002;
}

.x-btn-navbar {
  text-shadow: 0 1px 1px #ffffffbf;
  color: #919191;
  background-color: #f7f7f7;
  border: 0;
  border-radius: 4px;
  padding: .458em .625em;
  transition: box-shadow .3s, color .3s, background-color .3s;
  box-shadow: inset 0 1px 4px #00000040;
}

.x-btn-navbar:hover {
  color: #919191;
}

.x-btn-navbar.collapsed {
  color: #b7b7b7;
  background-color: #fff;
  box-shadow: inset 0 0 #0000, 0 1px 5px #00000040;
}

.x-btn-navbar.collapsed:hover {
  color: #919191;
  background-color: #f7f7f7;
  box-shadow: inset 0 1px 4px #00000040;
}

.x-navbar .desktop .x-nav > li > a {
  padding: 0;
}

.x-navbar .mobile .x-nav li > a {
  color: #b7b7b7;
  background-color: #0000;
  border: 1px solid #f2f2f2;
  border-left: 0;
  border-right: 0;
  margin: 0 0 -1px;
  padding: 1em 0;
}

.x-navbar .mobile .x-nav li > a:hover, .x-navbar .mobile .x-nav .x-active > a, .x-navbar .mobile .x-nav .current-menu-item > a {
  color: #272727;
}

.x-sub-toggle {
  width: 35px;
}

.x-navbar-fixed-left .desktop .x-nav > li > a, .x-navbar-fixed-right .desktop .x-nav > li > a {
  text-align: center;
  padding: 20px 35px;
}

@media (max-width: 978.98px) {
  .x-navbar-fixed-left, .x-navbar-fixed-right {
    box-shadow: 0 .15em .35em #0002;
  }
}

.x-navbar-fixed-left {
  border-right: 1px solid #ccc;
  box-shadow: 2px 0 4px #0000001a;
}

@media (max-width: 978.98px) {
  .x-navbar-fixed-left {
    border-right: 0;
  }
}

.x-navbar-fixed-left .desktop .x-nav > li > a:focus, .x-navbar-fixed-left .desktop .x-nav > li > a:hover {
  box-shadow: inset 8px 0 #ff2a13;
}

.x-navbar-fixed-right {
  border-left: 1px solid #ccc;
  box-shadow: -2px 0 4px #0000001a;
}

@media (max-width: 978.98px) {
  .x-navbar-fixed-right {
    border-left: 0;
  }
}

.x-navbar-fixed-right .desktop .x-nav > li > a:focus, .x-navbar-fixed-right .desktop .x-nav > li > a:hover {
  box-shadow: inset -8px 0 #ff2a13;
}

.x-navbar .mobile .x-nav > li.x-menu-item-search {
  text-align: center;
  margin: 25px 0 0;
}

.x-navbar .mobile .x-nav > li.x-menu-item-search > a {
  border: 1px solid;
  border-radius: 4px;
}

.desktop .x-nav .x-megamenu > .sub-menu a {
  white-space: normal;
  border-radius: 4px;
  padding: 6px 10px;
}

.desktop .x-nav .x-megamenu > .sub-menu > li {
  float: left;
  border-right: 1px solid #f2f2f2;
  margin: 18px 0;
  padding: 0 18px;
}

.desktop .x-nav .x-megamenu > .sub-menu > li > a {
  text-transform: uppercase;
  color: #272727;
  background-color: #0000;
  margin: 0;
  font-size: 14px;
}

.desktop .x-nav .x-megamenu > .sub-menu > li > a > span:after {
  display: none;
}

.desktop .x-nav .x-megamenu > .sub-menu > li > .sub-menu {
  width: 100%;
  min-width: 0;
  box-shadow: none;
  padding: 0;
  position: static;
  visibility: visible !important;
  display: block !important;
}

.desktop .x-nav .x-megamenu.col-2 > .sub-menu > li {
  width: 50%;
}

.desktop .x-nav .x-megamenu.col-2 > .sub-menu > li:nth-child(2n) {
  border-right: 0;
}

.desktop .x-nav .x-megamenu.col-2 > .sub-menu > li:nth-child(3n) {
  clear: left;
}

.desktop .x-nav .x-megamenu.col-3 > .sub-menu > li {
  width: 33.3333%;
}

.desktop .x-nav .x-megamenu.col-3 > .sub-menu > li:nth-child(3n) {
  border-right: 0;
}

.desktop .x-nav .x-megamenu.col-3 > .sub-menu > li:nth-child(4n) {
  clear: left;
}

.desktop .x-nav .x-megamenu.col-4 > .sub-menu > li {
  width: 25%;
}

.desktop .x-nav .x-megamenu.col-4 > .sub-menu > li:nth-child(4n) {
  border-right: 0;
}

.desktop .x-nav .x-megamenu.col-4 > .sub-menu > li:nth-child(5n) {
  clear: left;
}

.desktop .x-nav .x-megamenu.col-5 > .sub-menu > li {
  width: 20%;
}

.desktop .x-nav .x-megamenu.col-5 > .sub-menu > li:nth-child(5n) {
  border-right: 0;
}

.desktop .x-nav .x-megamenu.col-5 > .sub-menu > li:nth-child(6n) {
  clear: left;
}

.x-navbar-static-active .desktop .x-nav .x-megamenu, .x-navbar-fixed-top-active .desktop .x-nav .x-megamenu {
  position: static;
}

.x-navbar-static-active .desktop .x-nav .x-megamenu > .sub-menu, .x-navbar-fixed-top-active .desktop .x-nav .x-megamenu > .sub-menu {
  padding: 18px;
  left: 0;
  right: 0;
}

.x-navbar-fixed-left-active .desktop .x-nav .x-megamenu, .x-navbar-fixed-right-active .desktop .x-nav .x-megamenu {
  position: relative;
}

.x-navbar-fixed-left-active .desktop .x-nav .x-megamenu > .sub-menu, .x-navbar-fixed-right-active .desktop .x-nav .x-megamenu > .sub-menu {
  width: 600px;
  padding: 8px;
}

.x-colophon {
  background-color: #fff;
}

.x-colophon + .x-colophon {
  border-top: 1px solid #00000016;
  box-shadow: inset 0 1px #fffc;
}

.x-colophon.top {
  border-top: 1px solid #d4d4d4;
  padding: 5% 0 5.25%;
  box-shadow: 0 -.125em .25em #00000013;
}

.x-colophon.top .x-column .widget:first-child {
  margin-top: 0;
}

@media (max-width: 978.98px) {
  .x-colophon.top {
    padding: 6.5% 0;
  }

  .x-colophon.top .x-column .widget:first-child {
    margin-top: 3em;
  }

  .x-colophon.top .x-column:first-child .widget:first-child {
    margin-top: 0;
  }
}

.x-colophon.bottom {
  text-align: center;
  color: #7a7a7a;
  padding: 10px 0;
  font-size: 10px;
}

.x-colophon.bottom .x-nav {
  margin: 10px 0;
  line-height: 1.3;
}

.x-colophon.bottom .x-nav li {
  margin: 0 .5em;
  display: inline-block;
}

.x-colophon.bottom .x-nav li a {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
  display: block;
}

.x-colophon.bottom .x-nav li a:hover {
  background-color: #0000;
}

.x-colophon.bottom .x-social-global {
  margin: 10px 0;
}

.x-colophon.bottom .x-social-global a {
  margin: 0 1.25%;
  font-size: 21px;
}

.x-colophon.bottom .x-social-global a.foursquare {
  font-size: 20px;
}

.x-colophon.bottom .x-colophon-content {
  letter-spacing: 2px;
  margin: 30px 0 10px;
  font-weight: 400;
  line-height: 1.3;
}

.x-colophon .widget {
  margin-top: 3em;
}

a, h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, .x-breadcrumb-wrap a:hover, .widget ul li a:hover, .widget ol li a:hover, .widget.widget_text ul li a, .widget.widget_text ol li a, .widget_nav_menu .current-menu-item > a, .x-accordion-heading .x-accordion-toggle:hover, .x-comment-author a:hover, .x-comment-time:hover, .x-recent-posts a:hover .h-recent-posts {
  color: #0000a2;
}

a:hover, .widget.widget_text ul li a:hover, .widget.widget_text ol li a:hover, .x-twitter-widget ul li a:hover {
  color: #00f;
}

.rev_slider_wrapper, a.x-img-thumbnail:hover, .x-slider-container.below, .page-template-template-blank-3-php .x-slider-container.above, .page-template-template-blank-6-php .x-slider-container.above {
  border-color: #0000a2;
}

.entry-thumb:before, .x-pagination span.current, .flex-direction-nav a, .flex-control-nav a:hover, .flex-control-nav a.flex-active, .mejs-time-current, .x-dropcap, .x-skill-bar .bar, .x-pricing-column.featured h2, .h-comments-title small, .x-entry-share .x-share:hover, .x-highlight, .x-recent-posts .x-recent-posts-img:after {
  background-color: #0000a2;
}

.x-nav-tabs > .active > a, .x-nav-tabs > .active > a:hover {
  box-shadow: inset 0 3px #0000a2;
}

.x-main {
  width: 69.537%;
}

.x-sidebar {
  width: 25.5369%;
}

.x-comment-author, .x-comment-time, .comment-form-author label, .comment-form-email label, .comment-form-url label, .comment-form-rating label, .comment-form-comment label, .widget_calendar #wp-calendar caption, .widget.widget_rss li .rsswidget {
  font-family: Lato, sans-serif;
  font-weight: 700;
}

.p-landmark-sub, .p-meta, input, button, select, textarea {
  font-family: Lato, sans-serif;
}

.widget ul li a, .widget ol li a, .x-comment-time {
  color: #7a7a7a;
}

.widget_text ol li a, .widget_text ul li a {
  color: #0000a2;
}

.widget_text ol li a:hover, .widget_text ul li a:hover {
  color: #00f;
}

.comment-form-author label, .comment-form-email label, .comment-form-url label, .comment-form-rating label, .comment-form-comment label, .widget_calendar #wp-calendar th, .p-landmark-sub strong, .widget_tag_cloud .tagcloud a:hover, .widget_tag_cloud .tagcloud a:active, .entry-footer a:hover, .entry-footer a:active, .x-breadcrumbs .current, .x-comment-author, .x-comment-author a {
  color: #272727;
}

.widget_calendar #wp-calendar th {
  border-color: #272727;
}

.h-feature-headline span i {
  background-color: #272727;
}

html {
  font-size: 14px;
}

@media (min-width: 480px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 767px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 979px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 14px;
  }
}

body {
  color: #7a7a7a;
  background-color: #f3f3f3;
  font-style: normal;
  font-weight: 400;
}

.w-b {
  font-weight: 400 !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 700;
}

h1, .h1 {
  letter-spacing: .018em;
}

h2, .h2 {
  letter-spacing: .025em;
}

h3, .h3 {
  letter-spacing: .031em;
}

h4, .h4 {
  letter-spacing: .042em;
}

h5, .h5 {
  letter-spacing: .048em;
}

h6, .h6 {
  letter-spacing: .071em;
}

.w-h {
  font-weight: 700 !important;
}

.x-container.width {
  width: 88%;
}

.x-container.max {
  max-width: 1200px;
}

.x-main.full {
  float: none;
  width: auto;
  display: block;
}

@media (max-width: 978.98px) {
  .x-main.full, .x-main.left, .x-main.right, .x-sidebar.left, .x-sidebar.right {
    float: none;
    display: block;
    width: auto !important;
  }
}

.entry-header, .entry-content {
  font-size: 1rem;
}

body, input, button, select, textarea {
  font-family: Lato, sans-serif;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a, blockquote {
  color: #272727;
}

.cfc-h-tx {
  color: #272727 !important;
}

.cfc-h-bd {
  border-color: #272727 !important;
}

.cfc-h-bg {
  background-color: #272727 !important;
}

.cfc-b-tx {
  color: #7a7a7a !important;
}

.cfc-b-bd {
  border-color: #7a7a7a !important;
}

.cfc-b-bg {
  background-color: #7a7a7a !important;
}

.x-btn, .button, [type="submit"] {
  color: #fff;
  text-shadow: 0 .075em .075em #00000080;
  background-color: #0000a2;
  border-color: #00007c;
  border-radius: .25em;
  margin-bottom: .25em;
  box-shadow: 0 .25em #000090, 0 4px 9px #000000bf;
}

.x-btn:hover, .button:hover, [type="submit"]:hover {
  color: #fff;
  text-shadow: 0 .075em .075em #00000080;
  background-color: #0000ea;
  border-color: #00007c;
  margin-bottom: .25em;
  box-shadow: 0 .25em #000090, 0 4px 9px #000000bf;
}

.x-btn.x-btn-real, .x-btn.x-btn-real:hover {
  text-shadow: 0 .075em .075em #000000a6;
  margin-bottom: .25em;
  box-shadow: 0 .25em #000090, 0 4px 9px #000000bf;
}

.x-btn.x-btn-flat, .x-btn.x-btn-flat:hover {
  text-shadow: 0 .075em .075em #000000a6;
  box-shadow: none;
  margin-bottom: 0;
}

.x-btn.x-btn-transparent, .x-btn.x-btn-transparent:hover {
  text-shadow: none;
  text-transform: uppercase;
  box-shadow: none;
  background-color: #0000;
  border-width: 3px;
  margin-bottom: 0;
}

.x-topbar .p-info a:hover, .x-widgetbar .widget ul li a:hover {
  color: #0000a2;
}

.x-topbar .p-info, .x-topbar .p-info a, .x-navbar .desktop .x-nav > li > a, .x-navbar .desktop .sub-menu a, .x-navbar .mobile .x-nav li > a, .x-breadcrumb-wrap a, .x-breadcrumbs .delimiter {
  color: #f7f7f7;
}

.x-navbar .desktop .x-nav > li > a:hover, .x-navbar .desktop .x-nav > .x-active > a, .x-navbar .desktop .x-nav > .current-menu-item > a, .x-navbar .desktop .sub-menu a:hover, .x-navbar .desktop .sub-menu .x-active > a, .x-navbar .desktop .sub-menu .current-menu-item > a, .x-navbar .desktop .x-nav .x-megamenu > .sub-menu > li > a, .x-navbar .mobile .x-nav li > a:hover, .x-navbar .mobile .x-nav .x-active > a, .x-navbar .mobile .x-nav .current-menu-item > a {
  color: #fff;
}

.x-navbar {
  background-color: #0000;
}

.x-navbar .desktop .x-nav > li > a {
  padding: 31px 7%;
}

.desktop .x-megamenu > .sub-menu {
  width: 699px;
}

.x-navbar .desktop .x-nav > li > a:hover, .x-navbar .desktop .x-nav > .x-active > a, .x-navbar .desktop .x-nav > .current-menu-item > a {
  box-shadow: inset 8px 0 #0000a2;
}

.x-widgetbar {
  left: 180px;
}

.x-navbar .desktop .x-nav > li ul {
  top: -15px;
}

body.x-navbar-fixed-left-active {
  padding-left: 180px;
}

.x-navbar {
  width: 180px;
}

.x-navbar-inner {
  min-height: 0;
}

.x-brand {
  letter-spacing: 1em;
  color: #272727;
  margin-top: 100px;
  font-family: Lato, sans-serif;
  font-size: 1px;
  font-style: normal;
  font-weight: 700;
}

.x-brand:hover, .x-brand:focus {
  color: #272727;
}

.x-navbar .x-nav-wrap .x-nav > li > a {
  letter-spacing: 0;
  text-transform: uppercase;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.x-navbar .desktop .x-nav > li > a {
  font-size: 18px;
}

.x-navbar .desktop .x-nav > li > a > span {
  margin-right: 0;
}

.x-btn-navbar {
  margin-top: 20px;
}

.x-btn-navbar, .x-btn-navbar.collapsed {
  font-size: 28px;
}

@media (max-width: 979px) {
  body.x-navbar-fixed-left-active, body.x-navbar-fixed-right-active {
    padding: 0;
  }

  .x-navbar {
    width: auto;
  }

  .x-navbar .x-navbar-inner > .x-container.width {
    width: 88%;
  }

  .x-brand {
    margin-top: 22px;
  }

  .x-widgetbar {
    left: 0;
    right: 0;
  }
}

.e2-8.x-text {
  background-color: #0000;
  margin: 1.5em 0 2.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-9.x-text {
  background-color: #0000;
  margin: 0 0 1.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-12.x-text {
  background-color: #0000;
  margin: 0 0 2.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-8.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  color: #0000a2;
  margin-right: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 2em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-9.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-transform: none;
  color: #000;
  margin-right: 0;
  font-family: inherit;
  font-size: 2em;
  font-style: italic;
  font-weight: 400;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-12.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  color: #0000a2;
  margin-right: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 3.5em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-8.x-text .x-text-content-text-subheadline, .e2-9.x-text .x-text-content-text-subheadline, .e2-12.x-text .x-text-content-text-subheadline, .e2-8.x-text .x-text-typing, .e2-9.x-text .x-text-typing, .e2-12.x-text .x-text-typing, .e2-8.x-text .x-typed-cursor, .e2-9.x-text .x-typed-cursor, .e2-12.x-text .x-typed-cursor, .e2-8.x-text .x-graphic-child, .e2-9.x-text .x-graphic-child, .e2-12.x-text .x-graphic-child {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-4.x-image {
  width: 400px;
  background-color: #fffc;
  border-radius: 100em;
  padding: 12em 5em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-13.x-image {
  margin: 0 auto;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-4.x-image img {
  width: 100%;
}

.e2-3.x-col {
  z-index: 1;
  background-color: #0000;
  flex-flow: column wrap;
  place-content: center;
  align-items: center;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.e2-11.x-col {
  z-index: 1;
  background-color: #0000;
  flex-flow: column wrap;
  place-content: center flex-start;
  align-items: center;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.e2-7.x-col {
  z-index: 1;
  background-color: #0000;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-2.x-row, .e2-6.x-row {
  z-index: 1;
  background-color: #0000;
  margin: 0 auto;
  padding: 1px;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-2 > .x-row-inner, .e2-6 > .x-row-inner {
  flex-direction: row;
  place-content: stretch flex-start;
  align-items: stretch;
  margin: calc(-.5rem - 1px);
}

.e2-2 > .x-row-inner > * {
  flex-grow: 1;
  margin: .5rem;
}

.e2-6 > .x-row-inner > * {
  margin: .5rem;
}

.e2-1.x-section {
  z-index: 1;
  background-color: #0000;
  margin: 0;
  padding: 45px 0;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-5.x-section {
  z-index: 1;
  background-color: #fff;
  margin: 0;
  padding: 45px 0;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-10.x-text {
  letter-spacing: 0;
  text-transform: none;
  color: #000;
  background-color: #0000;
  font-family: inherit;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e2-10.x-text > :first-child {
  margin-top: 0;
}

.e2-10.x-text > :last-child {
  margin-bottom: 0;
}

@media (max-width: 479.98px) {
  .e2-2 > .x-row-inner > :nth-child(n), .e2-6 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }
}

@media (min-width: 480px) and (max-width: 766.98px) {
  .e2-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e2-6 > .x-row-inner > :nth-child(2n-1), .e2-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 767px) and (max-width: 978.98px) {
  .e2-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e2-6 > .x-row-inner > :nth-child(2n-1), .e2-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 979px) and (max-width: 1199.98px) {
  .e2-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e2-6 > .x-row-inner > :nth-child(2n-1), .e2-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 1200px) {
  .e2-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e2-6 > .x-row-inner > :nth-child(2n-1), .e2-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

.e2-9 .x-text-content-text-primary, .e2-12 .x-text-content-text-primary {
  font-family: arial-black, sans-serif !important;
}

.x-navbar {
  overflow: hidden;
}

.x-brand.text {
  display: none;
}

.footer-copyright {
  text-transform: uppercase;
}

.x-navbar-fixed-left {
  border-bottom: none;
  box-shadow: none !important;
}

@media (min-width: 979px) {
  .x-navbar-fixed-left {
    background-color: #fff3;
    border-right-color: #fff6;
  }
}

.x-colophon {
  background-color: #fff;
}

.x-colophon.bottom .x-colophon-content {
  margin-top: 15px;
}

.e5-8.x-text {
  background-color: #0000;
  margin: 1.5em 0 2.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-9.x-text {
  background-color: #0000;
  margin: 0 0 1.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-12.x-text {
  background-color: #0000;
  margin: 0 0 2.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-8.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  color: #0000a2;
  margin-right: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 2em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-9.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-transform: none;
  color: #000;
  margin-right: 0;
  font-family: inherit;
  font-size: 2em;
  font-style: italic;
  font-weight: 400;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-12.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  color: #0000a2;
  margin-right: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 3.5em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-8.x-text .x-text-content-text-subheadline, .e5-9.x-text .x-text-content-text-subheadline, .e5-12.x-text .x-text-content-text-subheadline, .e5-8.x-text .x-text-typing, .e5-9.x-text .x-text-typing, .e5-12.x-text .x-text-typing, .e5-8.x-text .x-typed-cursor, .e5-9.x-text .x-typed-cursor, .e5-12.x-text .x-typed-cursor, .e5-8.x-text .x-graphic-child, .e5-9.x-text .x-graphic-child, .e5-12.x-text .x-graphic-child {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-4.x-image {
  width: 300px;
  background-color: #fffc;
  border-radius: 100em;
  padding: 9em 3em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-4.x-image img {
  width: 100%;
}

.e5-13.x-image {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-3.x-col {
  z-index: 1;
  background-color: #0000;
  flex-flow: column wrap;
  place-content: center;
  align-items: center;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.e5-11.x-col {
  z-index: 1;
  background-color: #0000;
  flex-flow: column wrap;
  place-content: center flex-start;
  align-items: center;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.e5-7.x-col {
  z-index: 1;
  background-color: #0000;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-2.x-row, .e5-6.x-row {
  z-index: 1;
  background-color: #0000;
  margin: 0 auto;
  padding: 1px;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-2 > .x-row-inner, .e5-6 > .x-row-inner {
  flex-direction: row;
  place-content: stretch flex-start;
  align-items: stretch;
  margin: calc(-.5rem - 1px);
}

.e5-2 > .x-row-inner > * {
  flex-grow: 1;
  margin: .5rem;
}

.e5-6 > .x-row-inner > * {
  margin: .5rem;
}

.e5-1.x-section {
  z-index: 1;
  background-color: #0000;
  margin: 0;
  padding: 45px 0;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-5.x-section {
  z-index: 1;
  background-color: #fff;
  margin: 0;
  padding: 45px 0;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-10.x-text {
  letter-spacing: 0;
  text-transform: none;
  color: #000;
  background-color: #0000;
  font-family: inherit;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e5-10.x-text > :first-child {
  margin-top: 0;
}

.e5-10.x-text > :last-child {
  margin-bottom: 0;
}

@media (max-width: 479.98px) {
  .e5-2 > .x-row-inner > :nth-child(n), .e5-6 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }
}

@media (min-width: 480px) and (max-width: 766.98px) {
  .e5-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e5-6 > .x-row-inner > :nth-child(2n-1), .e5-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 767px) and (max-width: 978.98px) {
  .e5-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e5-6 > .x-row-inner > :nth-child(2n-1), .e5-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 979px) and (max-width: 1199.98px) {
  .e5-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e5-6 > .x-row-inner > :nth-child(2n-1), .e5-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 1200px) {
  .e5-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e5-6 > .x-row-inner > :nth-child(2n-1), .e5-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

.e5-9 .x-text-content-text-primary, .e5-12 .x-text-content-text-primary {
  font-family: arial-black, sans-serif !important;
}

.e7-8.x-text {
  background-color: #0000;
  margin: 1.5em 0 2.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-9.x-text {
  background-color: #0000;
  margin: 0 0 1.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-12.x-text {
  background-color: #0000;
  margin: 0 0 2.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-8.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  color: #0000a2;
  margin-right: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 2em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-9.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-transform: none;
  color: #000;
  margin-right: 0;
  font-family: inherit;
  font-size: 2em;
  font-style: italic;
  font-weight: 400;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-12.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  color: #0000a2;
  margin-right: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 3.5em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-8.x-text .x-text-content-text-subheadline, .e7-9.x-text .x-text-content-text-subheadline, .e7-12.x-text .x-text-content-text-subheadline, .e7-8.x-text .x-text-typing, .e7-9.x-text .x-text-typing, .e7-12.x-text .x-text-typing, .e7-8.x-text .x-typed-cursor, .e7-9.x-text .x-typed-cursor, .e7-12.x-text .x-typed-cursor, .e7-8.x-text .x-graphic-child, .e7-9.x-text .x-graphic-child, .e7-12.x-text .x-graphic-child {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-4.x-image {
  width: 300px;
  background-color: #fffc;
  border-radius: 100em;
  padding: 9em 3em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-4.x-image img {
  width: 100%;
}

.e7-13.x-image {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-3.x-col {
  z-index: 1;
  background-color: #0000;
  flex-flow: column wrap;
  place-content: center;
  align-items: center;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.e7-11.x-col {
  z-index: 1;
  background-color: #0000;
  flex-flow: column wrap;
  place-content: center flex-start;
  align-items: center;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.e7-7.x-col {
  z-index: 1;
  background-color: #0000;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-2.x-row, .e7-6.x-row {
  z-index: 1;
  background-color: #0000;
  margin: 0 auto;
  padding: 1px;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-2 > .x-row-inner, .e7-6 > .x-row-inner {
  flex-direction: row;
  place-content: stretch flex-start;
  align-items: stretch;
  margin: calc(-.5rem - 1px);
}

.e7-2 > .x-row-inner > * {
  flex-grow: 1;
  margin: .5rem;
}

.e7-6 > .x-row-inner > * {
  margin: .5rem;
}

.e7-1.x-section {
  z-index: 1;
  background-color: #0000;
  margin: 0;
  padding: 45px 0;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-5.x-section {
  z-index: 1;
  background-color: #fff;
  margin: 0;
  padding: 45px 0;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-10.x-text {
  letter-spacing: 0;
  text-transform: none;
  color: #000;
  background-color: #0000;
  font-family: inherit;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e7-10.x-text > :first-child {
  margin-top: 0;
}

.e7-10.x-text > :last-child {
  margin-bottom: 0;
}

@media (max-width: 479.98px) {
  .e7-2 > .x-row-inner > :nth-child(n), .e7-6 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }
}

@media (min-width: 480px) and (max-width: 766.98px) {
  .e7-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e7-6 > .x-row-inner > :nth-child(2n-1), .e7-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 767px) and (max-width: 978.98px) {
  .e7-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e7-6 > .x-row-inner > :nth-child(2n-1), .e7-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 979px) and (max-width: 1199.98px) {
  .e7-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e7-6 > .x-row-inner > :nth-child(2n-1), .e7-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 1200px) {
  .e7-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e7-6 > .x-row-inner > :nth-child(2n-1), .e7-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

.e7-9 .x-text-content-text-primary, .e7-12 .x-text-content-text-primary {
  font-family: arial-black, sans-serif !important;
}

.e9-8.x-text {
  background-color: #0000;
  margin: 1.5em 0;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-9.x-text {
  background-color: #0000;
  margin: 0 0 1.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-13.x-text {
  background-color: #0000;
  margin: 0 0 2.5em;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-8.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  color: #0000a2;
  margin-right: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 2em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-9.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-transform: none;
  color: #000;
  margin-right: 0;
  font-family: inherit;
  font-size: 2em;
  font-style: italic;
  font-weight: 400;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-13.x-text .x-text-content-text-primary {
  letter-spacing: 0;
  text-align: center;
  text-transform: none;
  color: #0000a2;
  margin-right: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 3.5em;
  font-style: italic;
  font-weight: 700;
  line-height: 1.4;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-8.x-text .x-text-content-text-subheadline, .e9-9.x-text .x-text-content-text-subheadline, .e9-13.x-text .x-text-content-text-subheadline, .e9-8.x-text .x-text-typing, .e9-9.x-text .x-text-typing, .e9-13.x-text .x-text-typing, .e9-8.x-text .x-typed-cursor, .e9-9.x-text .x-typed-cursor, .e9-13.x-text .x-typed-cursor, .e9-8.x-text .x-graphic-child, .e9-9.x-text .x-graphic-child, .e9-13.x-text .x-graphic-child {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-4.x-image {
  width: 300px;
  background-color: #fffc;
  border-radius: 100em;
  padding: 9em 3em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-4.x-image img {
  width: 100%;
}

.e9-14.x-image {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-3.x-col {
  z-index: 1;
  background-color: #0000;
  flex-flow: column wrap;
  place-content: center;
  align-items: center;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.e9-12.x-col {
  z-index: 1;
  background-color: #0000;
  flex-flow: column wrap;
  place-content: center flex-start;
  align-items: center;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.e9-7.x-col {
  z-index: 1;
  background-color: #0000;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-2.x-row, .e9-6.x-row {
  z-index: 1;
  background-color: #0000;
  margin: 0 auto;
  padding: 1px;
  font-size: 1em;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-2 > .x-row-inner, .e9-6 > .x-row-inner {
  flex-direction: row;
  place-content: stretch flex-start;
  align-items: stretch;
  margin: calc(-.5rem - 1px);
}

.e9-2 > .x-row-inner > * {
  flex-grow: 1;
  margin: .5rem;
}

.e9-6 > .x-row-inner > * {
  margin: .5rem;
}

.e9-1.x-section {
  z-index: 1;
  background-color: #0000;
  margin: 0;
  padding: 45px 0;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-5.x-section {
  z-index: 1;
  background-color: #fff;
  margin: 0;
  padding: 45px 0;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-11.x-text {
  letter-spacing: 0;
  text-transform: none;
  color: #000;
  background-color: #0000;
  margin: 0 0 1.5em;
  font-family: inherit;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.e9-11.x-text > :first-child {
  margin-top: 0;
}

.e9-11.x-text > :last-child {
  margin-bottom: 0;
}

@media (max-width: 479.98px) {
  .e9-2 > .x-row-inner > :nth-child(n), .e9-6 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }
}

@media (min-width: 480px) and (max-width: 766.98px) {
  .e9-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e9-6 > .x-row-inner > :nth-child(2n-1), .e9-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 767px) and (max-width: 978.98px) {
  .e9-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e9-6 > .x-row-inner > :nth-child(2n-1), .e9-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 979px) and (max-width: 1199.98px) {
  .e9-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e9-6 > .x-row-inner > :nth-child(2n-1), .e9-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 1200px) {
  .e9-2 > .x-row-inner > :nth-child(n) {
    flex-basis: calc(100% - 1rem);
  }

  .e9-6 > .x-row-inner > :nth-child(2n-1), .e9-6 > .x-row-inner > :nth-child(2n) {
    flex-basis: calc(50% - 1rem);
  }
}

.e9-9 .x-text-content-text-primary, .e9-13 .x-text-content-text-primary {
  font-family: arial-black, sans-serif !important;
}

/*# sourceMappingURL=index.389a1480.css.map */
